// import { Form, Button, FormGroup, FormControl, Card, ControlLabel, InputGroup, Navbar, NavDropdown } from "react-bootstrap";
// import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import React, { Fragment, useEffect, useRef, useState } from 'react';
// import { selectApp, setAppSliceState } from 'state/appSlice';
// import { selectLogin, setLoginSliceState } from 'state/signupSlice';
// import { useDispatch, useSelector } from 'react-redux';


// // import bellagioFront from "../../login/assets/backgroundImages/bellagioFront.jpg";
// // import logo from "../../login/assets/logo/logo.png";



// import Grid from '@material-ui/core/Grid';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

// import Loader from 'react-loader-spinner';

// import Modal from 'react-modal';

// import Icon from 'components/login/assets/icons/Icon';
// import pageConfig from 'components/login/utilities/pageConfig';
// import persistentStorage from 'utilities/persistentStorage';
// import styles from 'components/login/assets/styles/Login.module.scss';
// import submitForm from 'components/login/utilities/submitForm';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// import "../../login/styles.css"


// const AddUser = () => {
 

//   const dispatch = useDispatch();

//   var {
//     email,
//     isRememberMe,
//     pageType,
//     password,
//     username
//   } = useSelector(selectLogin);

// //  var pageType = 'signUp';
// //  var email = "";
// //  var username = "";
// //  var password = "";
// //   // const email = '';
// //   // const isRememberMe = false;
// //   // const pageType = 'signUp';
// //   // const password = "";
// //   // const username = "";
// //   // const { currentPage } = 'login';

//   const { currentPage } = useSelector(selectApp);

//   const [showNotice, setShowNotice] = useState(false);
//   const [notice, setNotice] = useState({ header: '', message: '' });
//   const [showLoader, setShowLoader] = useState(false);

//   useEffect(() => {
//     const rememberUser = persistentStorage.getLocalValue('rememberUser');
//     const usernameData = persistentStorage.getLocalValue('username');

//     // if (rememberUser) {
//     //   if (usernameData) {
//     //     dispatch(setLoginSliceState([
//     //       ['isRememberMe', true],
//     //       ['username', usernameData]
//     //     ]));
//     //   }

//     //   else dispatch(setLoginSliceState(['isRememberMe', true]));
//     // }

//     if (notice.header) {
//       setShowNotice(true);
//       setShowLoader(false);
//     }

//     else setShowNotice(false);
//   }, [notice]);

//   const toggleForgotPassword = () => {
//     dispatch(setLoginSliceState(['pageType', 'passwordReset']));
//   };

//   const toggleLoginSignup = () => {
//     const newPageType = pageType === 'login' ? 'signUp' : 'login';
//     dispatch(setLoginSliceState(['pageType', newPageType]));
//   };

//   const toggleRememberMe = () => {
//     persistentStorage.setLocalValue('rememberUser', !isRememberMe);
//     dispatch(setLoginSliceState(['isRememberMe', !isRememberMe]));
//   };

//   const updateEmail = (event) => {
//     const { target: { value } } = event;
//     dispatch(setLoginSliceState(['email', value]));
//   };

//   const updatePassword = (event) => {
//     const {
//       target: { value }
//     } = event;
//     dispatch(setLoginSliceState(['password', value]));
//   };

//   const updateUsername = (event) => {
//     const {
//       target: { value }
//     } = event;
//     dispatch(setLoginSliceState(['username', value]));
//   };

//   const { currentPageText, newPageText } = pageConfig(pageType);
//   const submitInput = useRef(null);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     setShowLoader(true);

//     submitForm(
//       pageType,
//       email,
//       password,
//       username,
//       setNotice
//     ).then((response) => {

//       if (currentPage === 'login' && pageType === 'login') {

//         const hasResponseData = Boolean(response && response.data !== null);

//         if (hasResponseData && !response.data.isEmailVerified) {
//           setNotice({
//             header: 'Verify email address',
//             message: "You must first verify your email before you're able to log in."
//           });
//         }

//         else if (hasResponseData) {

//           if (persistentStorage.getLocalValue('rememberUser')) {
//             persistentStorage.setLocalValue('username', username);
//           }

//           else persistentStorage.deleteLocalItem('username');

//           persistentStorage.setSessionValue('userData', response.data);

//           dispatch(
//             setAppSliceState([ ['currentPage', 'account'], ['userData', response.data] ])
//           );
//         }

//       }
//     });
//   };

//   const stylesnew = {
//     header: {
//       // backgroundImage: `url(${ bellagioFront })`,
//       // height: '100vh',
//       // pa
      
//       backgroundPosition: 'center',
//       backgroundRepeat: 'no-repeat',
//       backgroundSize: 'cover',
//     },
  
//     content: {
//       height: '100%',
//       width: '100%',
//       backgroundColor: 'rgba(100, 100, 100, 0.5)',
//     },

//     logo:{
//       // backgroundImage: `url(${logo})`,
//       // width: '324px',
//       height: '78px',
//     },

//     navbar:{
//       height: '70px',
//       backgroundColor: '#F5F5FA'
//     },

//     icon: {
//       color: "grey"
//     },

//     button:{
//       width: "100%",
      
//     },
//     heading:{
//       fontFamily: "Times New Roman",
//       textAlign: "center",
//     },
    
//     loader:{
//       zIndex: 30,
//       alignItems: "center",
//     },

//     modal: {
//       top                   : '50%',
//       left                  : '50%',
//       right                 : 'auto',
//       bottom                : 'auto',
//       marginRight           : '-50%',
//       transform             : 'translate(-50%, -50%)'
//     }
    
//   }


  

  
  

//   return (
//     <div style={stylesnew.header}>
      
    
        

      
//       <div style={stylesnew.content}>
//       <Grid
//   container
//   spacing={0}
//   direction="column"
//   alignItems="center"
//   justify="center"
//   style={{ minHeight: '100vh' }}
//  >

//   <Grid item xs={4}>
//       <Card className="bg-light">
//       <Loader type="Oval" color="#00BFFF" height={80} width={80} visible={ showLoader || false } style={{zIndex:"4" , position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}/>
//         <Card.Body >
//         {/* <form onSubmit={ handleSubmit }> */}
//         <form>
//         <Container >
//           <Icon pageType={ pageType } style={stylesnew.icon} />
//           <h4 style={stylesnew.heading} >{currentPageText}</h4>
//           <div >
//             {
//               pageType === 'signUp' || pageType === 'passwordReset' ? (
//                 <InputGroup className="mb-3">
                  
//                   <FormControl
//                   onChange= {updateEmail}
//                     placeholder="Email ID"
//                     required
//                     type="email"
//                     value={email || ''}
                    
//                   />
//                 </InputGroup>
//               ) : null
//             }
//             {
//               pageType !== 'passwordReset' ? (
//                 <Fragment>
//                   <InputGroup className="mb-3">
//                     <FormControl
//                       placeholder="Username"
//                       onChange={updateUsername}
//                       placeholder={ pageType === 'signUp' ? 'username' : 'username or email' }
//                       required
//                       value={ username || '' }
//                       variant="large"
//                     />
//                   </InputGroup>

//                   <InputGroup className="mb-3">
                    
//                     <FormControl
//                       autoComplete="on"
//                       minLength="6"
//                       onChange={ updatePassword }
//                       placeholder="password"
//                       required
//                       type="password"
//                       value={ password || '' }
//                       variant="large"
//                     />
//                   </InputGroup>
//                 </Fragment>
//               ) : null
//             }
//           </div>

//           {/* <Form.Group>
//           {
//             pageType === 'login' ? (
              
//               <InputGroup>
//               <Form.Group controlId="formBasicCheckbox">
//                 <Form.Check type="checkbox" label="Remember Me" checked={ isRememberMe } onChange={toggleRememberMe} />
//               </Form.Group>
//               </InputGroup>
//             ) : null
//           }
//           </Form.Group> */}

//           <Button
//             className="btn-info mx-auto"
//             // className="btn-primary"
//             style={stylesnew.button}
//             onClick={ () => submitInput.current.click() }
//             variant="large"
//           >
//             <input
//               className={ styles['submit-input'] }
//               ref={ submitInput }
//               type="submit"
//             />
//             { currentPageText }
//           </Button>
//           x
//           <div className={ pageType === 'login' ? styles.links : styles.link }>
//             {/* <a href="#submit" onClick={ toggleForgotPassword } style={{fontSize: 12, color: "blue"}}>
//               Forgot password?
//             </a> */}

//             {/* <a href="#submit" onClick={ toggleLoginSignup }>
//               {`${newPageText}?`}
//             </a> */}
//           </div>
//         </Container>
//       </form>

//       <div>
//       <Modal isOpen={showNotice || false} className="Modal" style={{minHeight:"50%", width:"50%", alignItems:"center"}} ariaHideApp={false}>
//       <p style={{marginTop: "18%", textAlign:"center"}}>{notice.message}</p>
//       <Button onClick={() => setShowNotice(false) } style={{marginLeft: "44%"}}>Okay</Button>
//       </Modal>
//       </div>
    
//       </Card.Body>
//       </Card>
//       </Grid>
//       </Grid>
//       </div>
//     </div>
//   );
// };

// export default AddUser;
// import { Button, Checkbox, Container, Input, Loader, Notice } from '@default-services/components';
import { Form, Button, FormGroup, FormControl, Card, ControlLabel, InputGroup, Navbar, NavDropdown } from "react-bootstrap";
import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { selectApp, setAppSliceState } from './state/appSlice';
import { selectLogin, setLoginSliceState } from './state/signUpSlice';
import { useDispatch, useSelector } from 'react-redux';
import Icon from './assets/icons/Icon';
import pageConfig from './Utilities/pageConfig';
import persistentStorage from './Utilities/persistentStorage';
import styles from './assets/styles/Login.module.scss';
import submitForm from './Utilities/submitForm';
import { sendPushNotification } from '../../../utilities/requests';

import Grid from '@material-ui/core/Grid';
import {Oval} from 'react-loader-spinner';
import Modal from 'react-modal';

import Background from "../assets/background/15.jpg";


const SignUp = () => {

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [role, setRole] = useState("Admin");

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const {
    email,
    isRememberMe,
    // pageType,
    password,
    firstName,
    lastName,
    username
  } = useSelector(selectLogin);
  const { currentPage } = useSelector(selectApp);

  const pageType = "signUp"

  const submitInput = useRef(null);
  const loginSignUpButton = useRef(null);

  const [showNotice, setShowNotice] = useState(false);
  const [notice, setNotice] = useState({ header: '', message: '' });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // const rememberUser = persistentStorage.getLocalValue('rememberUser');
    // const usernameData = persistentStorage.getLocalValue('username');

    // Example push notification
    // setTimeout(() => {
    //   sendPushNotification(
    //     'daniel', // username
    //     'test title!',
    //     'testing message',
    //     (response) => console.log(response),
    //     (error) => console.error(error)
    //   );
    // }, 3000);

    // if (rememberUser) {
    //   if (usernameData) {
    //     dispatch(setSignUpSliceState([
    //       ['isRememberMe', true],
    //       ['username', usernameData]
    //     ]));
    //   }

    //   else dispatch(setLoginSliceState(['isRememberMe', true]));
    // }

    if (notice.header) {
      setShowNotice(true);
      setShowLoader(false);
    }

    else setShowNotice(false);
  }, [notice]);

  
  const updateEmail = (event) => {
    const { target: { value } } = event;
    dispatch(setLoginSliceState(['email', value]));
  };

  const updatePassword = (event) => {
    const {
      target: { value }
    } = event;
    dispatch(setLoginSliceState(['password', value]));
  };

  const updateUsername = (event) => {
    const {
      target: { value }
    } = event;
    dispatch(setLoginSliceState(['username', value]));
  };
  const updateFirstname = (event) => {
    const {
      target: { value }
    } = event;
    dispatch(setLoginSliceState(['firstName', value]));
  };
  const updateLastname = (event) => {
    const {
      target: { value }
    } = event;
    dispatch(setLoginSliceState(['lastName', value]));
  };

  const { currentPageText, newPageText } = pageConfig(pageType);

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoader(true);

    submitForm(
      pageType,
      email,
      password,
      firstName, 
      lastName,
      username,
      role,
      setNotice
    );
    //).then((response) => {

    //   if (currentPage === 'login' && pageType === 'login') {

    //     const hasResponseData = Boolean(response && response.data !== null);

    //     if (hasResponseData && !response.data.isEmailVerified) {
    //       setNotice({
    //         header: 'Verify email address',
    //         message: "You must first verify your email before you're able to log in."
    //       });
    //     }

    //     else if (hasResponseData) {

    //       if (persistentStorage.getLocalValue('rememberUser')) {
    //         persistentStorage.setLocalValue('username', username);
    //       }

    //       else persistentStorage.deleteLocalItem('username');

    //       persistentStorage.setSessionValue('userData', response.data);

    //       dispatch(
    //         setAppSliceState([ ['currentPage', 'account'], ['userData', response.data] ])
    //       );
    //     }
    //   }
    //}
    //);
  };


  return (

    <div>
    {/* <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  // style={{ minHeight: '80vh'}}
 >

  <Grid item xs={4}>
      
      </Grid>
      </Grid> */}
      <Container style={{width: "30%"}}>
      <div>

      <Card>
      <Oval color="#00BFFF" height={80} width={80} visible={ showLoader || false } style={{zIndex:"4" , position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}/>
        <Card.Header style={{backgroundImage: `url(${Background})`, backgroundSize: "cover", margin:0}}>
        <Icon pageType={ pageType }  className="w-50" style={{color:"white", margin:0}}/>
          <h4 style={{marginBottom:"0%", marginTop:0, color:"white"}}>{currentPageText}</h4>
        </Card.Header>
        <Card.Body >
        <form onSubmit={ handleSubmit }>
        {/* <Container > */}
          <div>
            {
              pageType === 'signUp' || pageType === 'passwordReset' ? (
                <div>
                    <label>Email ID</label>
                <InputGroup className="mb-1">
                  
                  <FormControl
                    onChange= {updateEmail}
                    placeholder="Email ID"
                    required
                    type="email"
                    value={email || ''}
                    
                  />
                </InputGroup>
                </div>
              ) : null
            }
            {
              pageType !== 'passwordReset' ? (
                <Fragment>
                  <label>Name</label>
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder="First Name"
                      onChange={updateFirstname}
                      required
                      value={ firstName || '' }
                      variant="large"
                    />
                    <FormControl
                      placeholder="Last Name"
                      onChange={updateLastname}
                      required
                      value={ lastName || '' }
                      variant="large"
                    />
                  </InputGroup>
                    <label>Username</label>
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder="Username"
                      onChange={updateUsername}
                      required
                      value={ username || '' }
                      variant="large"
                    />
                  </InputGroup>
                  <label>Role</label>
                  <InputGroup className="mb-1">
                  <Dropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
                    <DropdownToggle caret className="nav-link" color="primary">
                        {role}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={()=> setRole("Admin")}>Admin</DropdownItem>
                        <DropdownItem onClick={()=> setRole("Director")}>Director</DropdownItem>
                        <DropdownItem onClick={()=> setRole("Engineer")}>Engineer</DropdownItem>
                    </DropdownMenu>
                    </Dropdown>
                  </InputGroup>
                  <label>Password</label>
                  <InputGroup>
                  
                    <FormControl
                      autoComplete="on"
                      minLength="6"
                      onChange={ updatePassword }
                      placeholder="password"
                      required
                      type="password"
                      value={ password || '' }
                      variant="large"
                    />
                  </InputGroup>
                </Fragment>
              ) : null
            }
          </div>

          
            <div className="d-flex justify-content-center" >
          <Button
            className="btn-primary "
            onClick={ () => submitInput.current.click() }
            variant="large"
            style={{marginTop: "5%", marginBottom:0}}
          >
            <input
              className={ styles['submit-input'] }
              ref={ submitInput }
              type="submit"
            />
            { currentPageText }
          </Button>
            </div>
        {/* </Container> */}
      </form>
      </Card.Body>
      </Card>
      </div>

      <Modal isOpen={showNotice || false} className="Modal" style={{minHeight:"50%", width:"50%", alignItems:"center", overlay: {zIndex:1000} }} ariaHideApp={false}>
      <p style={{marginTop: "18%", textAlign:"center"}}>{notice.message}</p>
      <Button onClick={() => setShowNotice(false) } style={{marginLeft: "44%"}}>Okay</Button>
      </Modal>
      </Container>
      </div>

  );
};

export default SignUp;