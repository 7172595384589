import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";

const PageTitle = ({ title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    
    "mb-sm-0"
  );

  return (
    <Col xs="12" sm="12" className={classes} { ...attrs }>
      <span className="text-uppercase page-subtitle text-secondary">{subtitle}</span>
      <h3 className="page-title text-dark" style={{fontFamily: "Times New Roman"}}>{title}</h3>
    </Col>
  )
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string
};

export default PageTitle;
