import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink, NavbarBrand } from "shards-react";
import { Link } from "react-router-dom";
import {BrowserView, MobileView, isMobile} from 'react-device-detect';


const MainFooter = ({ contained, menuItems, copyright }) => {
  if(isMobile){
    return(
    <footer className="main-footer d-flex bg-white border-top">
    <Container fluid={contained}>
      <Row className="">
        {/* <NavbarBrand>Shivoham Techno Services</NavbarBrand> */}
        <Nav className="d-flex justify-content-center">
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <NavLink tag={(props) => <Link {...props} />} to={item.to}>
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <span className="copyright ml-auto my-auto">{copyright}</span>
        
      </Row>
    </Container>
  </footer>
    )
  }
  return(
  <footer className="main-footer fixed-bottom d-flex bg-white border-top">
    <Container fluid={contained}>
      <Row className="">
        {/* <NavbarBrand>Shivoham Techno Services</NavbarBrand> */}
        <Nav className="d-flex justify-content-center">
          {menuItems.map((item, idx) => (
            <NavItem key={idx}>
              <NavLink tag={(props) => <Link {...props} />} to={item.to}>
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <span className="copyright ml-auto my-auto">{copyright}</span>
        
      </Row>
    </Container>
  </footer>
  );
}

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string
};

MainFooter.defaultProps = {
  contained: false,
  copyright: "2020 Shivoham Technoservices",
  menuItems: [
    {
      title: "Home",
      to: "/#"
    },
    {
      title: "Services",
      to: "/#"
    },
    {
      title: "About",
      to: "/#"
    },
    // {
    //   title: "Products",
    //   to: "/#"
    // },
    // {
    //   title: "Blog",
    //   to: "/#"
    // }
  ]
};

export default MainFooter;
