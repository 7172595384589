import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col,Button } from "shards-react";
import MainNavbar from "../menu-header-footer-layout/Header-Components/HeaderLayout/header-left-layout";
import MainSidebar from "../menu-header-footer-layout/Left-Slider-Menu-Components/left-slider-menu";
import MainFooter from "../menu-header-footer-layout/Footer/MainFooter";
import SmallSidebar from "../menu-header-footer-layout/Left-Slider-Menu-Components/Small";
import {BrowserView, MobileView, isMobile} from 'react-device-detect';

const stylesnew = {
    header: {
      // backgroundImage: `url(${ bellagioFront })`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: "cover",
      // backgroundColor: "green",
      // position: 'sticky',
      height: '100%',
      // pa
      
      
    },
    headerMobile: {
      // backgroundImage: `url(${ bellagioFront })`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: "cover",
      // backgroundColor: "green",
      // position: 'sticky',
      
      height: '100%',
      width: '100%',
      // pa
      
      
    },
    content: {
      minHeight: '80vh',
      width:"100%",
      height: '100%',
      // width: '100%',
      backgroundColor: 'rgba(250, 250, 250, 0.4)',
    },
  }
  const DefaultLayout = ({ children, noNavbar, noFooter }) => {
    // if(isMobile){
    //   return (
    //   //   <Container fluid>
    //   // <Row >
    //       <div className="main-content p-0" width="w-100" style={{overflow: "hidden"}}>
    //       {!noNavbar && <MainNavbar />}
    //       <div style={stylesnew.headerMobile} className="mt-5">
    //       <div style={stylesnew.content}>
    //       {children}
    //       </div>
    //       </div>
    //       {!noFooter && <MainFooter/>}
    //     </div>
    //     // </Row>
    //     // </Container>
    //   )
    // }
      return(
    <Container fluid>
      <Row >
      {!noNavbar && <MainNavbar />}
          <MainSidebar/>
          <div className="main-content p-0" style={{width: "94.5%", marginLeft: "5.5%"}}>
          <div style={stylesnew.header}>
          <div style={stylesnew.content}>
          {children}
          </div>
          </div>
          {!noFooter && <MainFooter/>}
        </div>
        
      </Row>
    </Container>
  )
    };
  
  DefaultLayout.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
  };
  
  DefaultLayout.defaultProps = {
    noNavbar: false,
    noFooter: false
  };
  
  export default DefaultLayout;
  