export default function() {
    return [
      {
        title: "Hotel Dashboard",
        to: "/Dashboard",
      },
      {
        title: "Metering",
        to: "/Metering",
      },
      {
        title:"Export Data",
        to: "/Reporting"
      },
       {
        title:"Thunder",
        to: "/Thunder"
      }      
    ];
  }
  