import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaChartLine, FaChartArea, FaListAlt, FaTachometerAlt,FaHistory, FaBars,FaPrint, FaSlidersH, FaClock, FaListOl, FaArrowsAlt, FaEject, FaThermometerHalf, FaFileExcel } from "react-icons/fa";
import sidebarBg from "../../assets/sidebar-assets/bg1.jpg"
import {VscSymbolClass} from "react-icons/vsc";
import {RiFileExcel2Line} from "react-icons/ri";
import { GiAerodynamicHarpoon, GiTurbine } from "react-icons/gi";
import {BsAlarmFill} from 'react-icons/bs';
import { Store } from "../../flux-tools";
import { Link } from 'react-router-dom';
import { RiTempColdFill } from 'react-icons/ri';
import { BiWater } from 'react-icons/bi';
class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      collapsed: true,
      toggled: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  UNSAFE_componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }

  handleToggleSidebar = (value) => {
    this.setState({toggled: value})
  };

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      
      this.state.menuVisible && "open"
    );

    return (
      // <Col
      //   // tag="aside"
      //   className={classes}
      //   style={{backgroundColor: 'rgba(250, 250, 250, 0.5)',}}
      // >



  <ProSidebar
      image={Image ? sidebarBg : false}
      collapsed={this.state.collapsed}
      toggled={this.state.toggled}
      breakPoint="md"
      onToggle={this.handleToggleSidebar}
      collapsedWidth="5.5%"
      width="18.5%"
      className={classes}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            height:'100%'
          }}
          // onFocus={()=> this.setState({collapsed: !this.state.collapsed})}
          // onBlur={()=> this.setState({collapsed: !this.state.collapsed})}
          // tabIndex="0"
          onClick={()=> this.setState({collapsed: !this.state.collapsed})}
        >
          Third Eye
        </div>
      </SidebarHeader>

      <SidebarContent>
        {/* <Menu iconShape="circle">
          <MenuItem
            icon={<FaTachometerAlt />}
            // suffix={<span className="badge red">new</span>}
          >
            Dashboard
            <Link to={{pathname: "/"}} />
          </MenuItem>
        </Menu> */}
         <Menu iconShape="circle" popperArrow>  
        {/* <MenuItem icon={<FaListAlt />}> Metering</MenuItem> */}
        {/* <SubMenu
            // suffix={<span className="badge yellow">3</span>}
            title='Metering'
            icon={<FaListAlt />}
          > */}
            <SubMenu title='Metering'>
              <MenuItem className="mt-2" icon={<GiAerodynamicHarpoon color="cyan" size={20} />}>Dynamic
                <Link to={{ pathname: "/Dynamic" }} />
              </MenuItem>
              <MenuItem className="mt-2" icon={<BsAlarmFill color="cyan" size={20} />}>Alarms
                <Link to={{ pathname: "/Alarms" }} />
              </MenuItem>
              <MenuItem className="mt-2" icon={<FaHistory color="cyan" size={20} />}>History
                <Link to={{ pathname: "/History" }} />
              </MenuItem>
            </SubMenu>
            <SubMenu title='Sensor'>
              <SubMenu title='NkProteins'>
                <MenuItem className="mt-2" icon={<RiTempColdFill color="cyan" size={20} />}>Temprature
                  <Link to={{ pathname: "/Temprature" }} />
                </MenuItem>
                <MenuItem className="mt-2" icon={<BiWater color="cyan" size={20} />}>Water Flow
                  <Link to={{ pathname: "/WaterFlow" }} />
                </MenuItem>
                <MenuItem className="mt-2" icon={<GiTurbine color="cyan" size={20} />}>MCM
                  <Link to={{ pathname: "/MCM" }} />
                </MenuItem>
              </SubMenu>
              <SubMenu title='Hindalco'>
                <MenuItem className="mt-2" icon={<GiAerodynamicHarpoon color="cyan" size={20} />}>Temprature
                  <Link to={{ pathname: "/Temprature" }} />
                </MenuItem>
              </SubMenu>
            </SubMenu>
            {/* <MenuItem className="mt-2" icon={<FaChartLine color="cyan" size={20}/>} >Diagnostic
              <Link to={{pathname:"/Diagnostic"}} />
            </MenuItem>
            <MenuItem className="mt-2" icon={<FaChartArea color="cyan" size={20}/>} >Live Charts
              <Link to={{pathname:"/Live"}} />
            </MenuItem>
            <MenuItem className="mt-2" icon={<FaPrint color="cyan" size={20}/>}>Report
              <Link to = {{pathname:"/Report"}} />
            </MenuItem> */}
            {/* <MenuItem icon={<FaListAlt />}>Overview
              <Link to={{pathname: "/Metering"}} />
            </MenuItem> */}

            {/* <MenuItem>Common
              <Link to={{pathname: "/CommonMetering"}} />
            </MenuItem> */}

            {/* <MenuItem >Electrical
              <Link to={{pathname: "/ElectricalStats"}} />  
            </MenuItem> */}

          {/* </SubMenu> */}
          </Menu> 
        {/* <Menu iconShape="circle" popperArrow>  */}
        {/* <SubMenu
            // suffix={<span className="badge yellow">3</span>}
            title='Centralized Control'
            icon={<FaSlidersH />}
          > */}
            {/* <MenuItem>Rooms Overview
            <Link to={{pathname: "/RoomOverview"}} />
            </MenuItem> */}
            {/* <MenuItem>Room Control
            <Link to={{pathname:"/Roomcontrol"}} />
            </MenuItem> */}
            {/* <MenuItem>Common Overview
            <Link to={{pathname:"/Commonoverview"}} />
            </MenuItem> */}
            {/* <MenuItem>Common Control
            <Link to={{pathname:"/Commoncontrol"}} />
            </MenuItem> */}
          {/* </SubMenu> */}
          {/* <MenuItem icon={<FaSlidersH />}> Meters */}
              {/* <Link to={{pathname: "/FloorMetering"}} /> */}
              {/* <Link to={{pathname: "/Meters"}} /> */}
            {/* </MenuItem>
        </Menu> */}
        {/* <Menu iconShape="circle">
          <MenuItem icon={<VscSymbolClass />}>A/C Control
            <Link to = {{pathname:"/Blowers"}} />
          </MenuItem>
        </Menu> */}
        {/* <Menu iconShape="circle"> */}
          {/* <MenuItem icon={<FaThermometerHalf />}><Link to={{pathname:"/HVAC"}} />HVAC</MenuItem> */}
          {/* <MenuItem icon={<FaThermometerHalf />}>HVAC</MenuItem>
        </Menu> */}
        {/* <Menu iconShape="circle">
          <MenuItem icon={<FaPrint color="cyan" size={20}/>}>Report
            <Link to = {{pathname:"/Report"}} />
          </MenuItem>
        </Menu> */}
        {/* <Menu iconShape="circle">
          <MenuItem icon={<RiFileExcel2Line />}>Reports - 2.0
            <Link to = {{pathname:"/Reports"}} />
          </MenuItem>
        </Menu> */}
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          
            {/* <FaBars onClick={()=> this.setState({collapsed: !this.state.collapsed})} style={{color:this.state.collapsed ? "green":"red"}} /> */}
            
        </div>
      </SidebarFooter>
    </ProSidebar>
      
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
