import React from "react";
//import { Container, Row, Col } from "shards-react";
import { Row, Col,Container,  Card, CardTitle,  CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody, Button, CardSubtitle } from "shards-react";
import {url} from '../../../utilities/requests'
import PageTitle from "../default-dashboard-layout/main-title-dashboard";
// import UsersByDevice from "./../components/blog/UsersByDevice";
import {Oval} from 'react-loader-spinner';
import Table from 'react-bootstrap/Table'
import { usePromiseTracker } from "react-promise-tracker";
import Modal from "react-modal";
import { trackPromise } from 'react-promise-tracker';
import { FaCircle } from 'react-icons/fa';
import { FcCheckmark } from "react-icons/fc";
import { CgCloseO } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import DateTimePicker from 'react-datetime-picker';
import Sticky from 'react-stickynode';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import SliderLabel from '@mui/material/StepLabel';
import { height } from "@mui/system";
import LoadingIndicator from "./promiseTracker";

export default class Alarms extends React.Component{
    constructor(props){
        super(props);
        this.formRef = React.createRef();
        const now = new Date();
        var date = now.getFullYear() + '-' + (now.getMonth()+1) + '-' + now.getDate() +' '+ now.getHours()+':'+ now.getMinutes()+':'+ 		now.getSeconds();
		const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
		const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
        this.state = {                
            tempDeviceName:'', tempAlarm:'',modalVisible:true, tempDateTime: date, tempAlarmCode:'',  tempAlarmDesc:'', alarmAck:false, alarmDismiss:false, tempAlarmSeverity: 0,
            p1Select:false, p2Select:false, p3Select:false,
            tableDisplay:[
                {uid: 1, name:"Meter 11", alarm: "Voltage Threshold Warning", alarmCode: "09115", datetime : "10/12/2021 13:45:11", alarmDesc:"Threshold voltage level has been reached", alarmAck: false, alarmDismiss:false, alarmSeverity : 3, read:false}
            ],
            dateTimeNow : now,            
            dateValue: [yesterdayBegin, todayEnd],
            meter_list:[],
            maxVoltageLL:null, minVoltageLL:null, maxVoltageLN:null, minVoltageLN:null, minCurrent:null, maxCurrent:null,
            otherValue : null, otherFactor : 'kwh', modalThreshold : false, thresholdArray: [], modalThresholdDetails:false, thresholdDetailsArray:[],
            pfValue:null, hzValue:null, wattValue:null, vaValue:null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.maxVoltageLL=this.maxVoltageLL.bind(this);
        // this.minVoltageLL=this.minVoltageLL.bind(this);
        // this.minVoltageLN=this.minVoltageLN.bind(this);
        this.maxVoltageLN=this.maxVoltageLN.bind(this);
        // this.minCurrent=this.minCurrent.bind(this);
        this.maxCurrent=this.maxCurrent.bind(this);
        this.setOtherLimit = this.setOtherLimit.bind(this);
        this.setPfLimit = this.setPfLimit.bind(this);
        this.setHzLimit = this.setHzLimit.bind(this);
        this.setWattLimit = this.setWattLimit.bind(this);
        this.setVaLimit = this.setVaLimit.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.phaseSelect = this.phaseSelect.bind(this);
        this.severitySelect = this.severitySelect.bind(this);
        this.viewThreshold = this.viewThreshold.bind(this);
        this.handleThresholdDisplay = this.handleThresholdDisplay.bind(this);
        this.closeModalDetails = this.closeModalDetails.bind(this);
        this.alarmThreshold = this.alarmThreshold.bind(this);
    }
    maxVoltageLL=(event)=>{
      this.setState({maxVoltageLL:event.target.value}) ;
    }
    // minVoltageLL=(event)=>{
    //     this.setState({minVoltageLL:event.target.value}) ;   
    // }
    maxVoltageLN=(event)=>{
        this.setState({maxVoltageLN:event.target.value}) ;
    }
    // minVoltageLN=(event)=>{
    //     this.setState({minVoltageLN:event.target.value}) ;
    // }
    maxCurrent=(event)=>{
        this.setState({maxCurrent:event.target.value}) ;
    }
    // minCurrent=(event)=>{
    //     this.setState({minCurrent:event.target.value}) ;
    // }

    setLimitKwh=(event)=>{
        this.setState({kwhRead:event.target.value});
    }


    handleThreshold(data){
        // console.log(data['result']);
        this.setState({
            result: data['result']
        })
        // console.log(data['result']);

        var meter = this.state.tempDeviceName;
        var maxVoltageLL = this.state.maxVoltageLL;
        var minVoltageLL = this.state.minVoltageLL;        
        var minCurrent = this.state.minCurrent;
        var maxCurrent = this.state.maxCurrent;
        var kwhValue = this.state.otherValue;
        var pfValue = this.state.pfValue;
        var hzValue = this.state.hzValue;
        var wattValue = this.state.wattValue;
        var vaValue = this.state.vaValue;
        var phase = this.state.phaseVal;
        var arrayCompare = [];
        // console.log('inside this block');

        data['result'].map((d, idx) => {
            if("Meter "+d['uid'] == meter){
                if(d['v1'] <= minVoltageLL || d['v1'] >= maxVoltageLL ){
                    arrayCompare = [...arrayCompare, {valueType:'Voltage P1', value:d['v1'], meterId: d['uid'], minVal: minVoltageLL, maxVal : maxVoltageLL}]
                }
                if(d['v2'] <= minVoltageLL || d['v2'] >= maxVoltageLL){
                    arrayCompare = [...arrayCompare, {valueType:'Voltage P2', value:d['v2'], meterId: d['uid'], minVal: minVoltageLL, maxVal : maxVoltageLL }]
                }
                if(d['v3'] <= minVoltageLL || d['v3'] >= maxVoltageLL){
                    arrayCompare = [...arrayCompare, {valueType:'Voltage P3', value:d['v3'], meterId: d['uid'],minVal: minVoltageLL, maxVal : maxVoltageLL }]
                }
                if(d['c1'] <= minCurrent || d['c1'] >= maxCurrent){
                    arrayCompare = [...arrayCompare, {valueType:'Current P1', value:d['c1'], meterId: d['uid'], minVal: minCurrent, maxVal: maxCurrent }]
                }
                if(d['c2'] <= minCurrent || d['c2'] >= maxCurrent){
                    arrayCompare = [...arrayCompare, {valueType:'Current P2', value:d['c2'], meterId: d['uid'], minVal: minCurrent, maxVal: maxCurrent }]
                }
                if(d['c3'] <= minCurrent || d['c3'] >= maxCurrent){
                    arrayCompare = [...arrayCompare, {valueType:'Current P3', value:d['c3'], meterId: d['uid'], minVal: minCurrent, maxVal: maxCurrent }]
                }
                if(d['kwh'] >= kwhValue){
                    arrayCompare = [...arrayCompare, {valueType:'KWH', value:d['kwh'], meterId: d['uid'], minVal: 0, maxVal: kwhValue }]
                }
                if(d['pf'] <= pfValue){
                    arrayCompare = [...arrayCompare, {valueType:'Power Factor', value:d['pf'], meterId: d['uid'], minVal: 0, maxVal: pfValue }]
                }
                if(d['hz'] >= hzValue){
                    arrayCompare = [...arrayCompare, {valueType:'Frequency', value:d['hz'], meterId: d['uid'], minVal: 0, maxVal: hzValue }]
                }
                if(d['watt'] >= wattValue){
                    arrayCompare = [...arrayCompare, {valueType:'Watt Power', value:d['watt'], meterId:d['uid'], minVal:0, maxVal: wattValue}]
                }
                if(d['va'] >= vaValue){
                    arrayCompare = [...arrayCompare, {valueType:'VA Power', value:d['va'], meterId:d['uid'], minVal:0, maxVal: vaValue}]
                }
            }
        })
        // console.log(arrayCompare);
        this.setState({ modalThreshold : true, thresholdArray : arrayCompare})
        // console.log(thresholdArray);        
        
    }
    addThreshold=()=>{
        // console.log('meter name' + this.state.tempDeviceName)
        var meter = this.state.tempDeviceName;
        var maxVoltageLL = this.state.maxVoltageLL;
        var minVoltageLL = this.state.minVoltageLL;
        var minCurrent = this.state.minCurrent;
        var maxCurrent = this.state.maxCurrent;
        var kwhValue = this.state.otherValue;
        var pfValue = this.state.pfValue;
        var hzValue = this.state.hzValue;
        var wattValue = this.state.wattValue;
        var vaValue = this.state.vaValue;

        try{
            trackPromise(
                fetch(url+'input_threshold', {headers :{
                    'Content-Type' : 'application/json',
                    'Accept' : 'applicaion/json'
                },
                method : "POST",
                body : JSON.stringify({
                    "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"],
                    "meter" : meter,
                    "maxVolt": maxVoltageLL,
                    "minVolt": minVoltageLL,
                    "maxCurrent": maxCurrent,
                    "minCurrent": minCurrent, 
                    "kwhValue": kwhValue,
                    "pfValue": pfValue,
                    "hzValue": hzValue,
                    "wattValue": wattValue,
                    "vaValue": vaValue
                })
            }).then(response => response.json()).then(value => console.log(value))
            );
        }catch(e){
            console.log(e)
        }
    }

    async handleThresholdDisplay(value){
        await this.setState({thresholdDetailsArray:value.result,
                            modalThresholdDetails : true
                        });
        // console.log(this.state.thresholdDetailsArray);
    }

    viewThreshold=()=>{
        try{trackPromise(
            fetch(url+'view_threshold', {headers :{
                'Content-Type' : 'application/json',
                'Accept' : 'applicaion/json'
            },
            method : "POST",
            body : JSON.stringify({
                "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"]
            })
        }).then(response => response.json()).then(value => this.handleThresholdDisplay(value))
        );
    }catch(e){
        console.log(e)
    }
    }

    handleDateChange(value){
        this.setState({tempDateTime : value});
        // console.log(this.state.tempDateTime, this.state.tempDeviceName)
    }

    componentDidMount(){
        this.loadData()
        setInterval(this.loadData, 300000);
        // this.sleep(2000)
        
        setInterval(this.alarmThreshold, 120000);
        this.alarmThreshold()   
    }

    loadData(){
        try{
            trackPromise(
                fetch(url+'alarms_data', {headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 },
                method: "POST",
                body: JSON.stringify({
                    "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"]
                })       
                }).then(response => response.json()).then(data => this.handleChange(data))
            // }).then(response => response.json()).then(data => console.log(data))

                );
        } catch (e){
            console.log(e)
        }

        // try{
        //     fetch(url+'alarm_by_threshold', {headers : { 
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //        },
        //       method: "POST",
        //       body: JSON.stringify({
        //           "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"]
        //       })       
        //       }).then(response => response.json()).then(data => console.log(data))
        // }
        // catch(e){
        //     console.log(e)
        // }

    }

    alarmThreshold(){
        try{
            fetch(url+'alarm_by_threshold', {headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
               },
              method: "POST",
              body: JSON.stringify({
                  "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"]
              })       
              }).then(response => response.json()).then(this.loadData)
        }
        catch(e){
            console.log(e)
        }
    }

    closeModal(){
        this.setState({modalThreshold: false});
    }
    closeModalDetails(){
        this.setState({modalThresholdDetails: false});
    }
    meterSelect(event){
        this.setState({tempDeviceName:event.target.value})
        // console.log(event.target.value); 
    }
    alarmSelect(event){
        this.setState({tempAlarm:event.target.value})
    }
    severitySelect(event){
        this.setState({tempAlarmSeverity : event.target.value})
    }

    handleAlarmDescription(value){
        this.setState({tempAlarmDesc : value})
    }
    changeLoader(){this.setState({showLoader: false});}
    handleDateChange(val){
		this.setState({dateValue: val})
		this.setState({start: val[0]})
		this.setState({end: val[1]})
    }
    handleChange(data){
        // console.log(data.result)
        this.setState({ tableDisplay : data.result,
                        meter_list   : data.meter_list
                    })
    }
    async handleAdd(){
        // var length = this.state.tableDisplay.length;
        const form = this.formRef.current;
        var now = new Date();
        var date = now.getFullYear() + '-' + (now.getMonth()+1) + '-' + now.getDate() +' '+ now.getHours()+':'+ now.getMinutes()+':'+ 		now.getSeconds();
        var uniID = 0;
        for(let i =0;i<this.state.tableDisplay.length;i++){
            uniID = this.state.tableDisplay[i].uid+1
        }
        // console.log(length);
        if(this.state.tempDeviceName != '' || this.state.tempAlarm != '' || this.state.tempAlarmCode != '' || this.state.tempDateTime != null || this.state.tempAlarmDesc != ''){
            this.setState({
                tableDisplay:[...this.state.tableDisplay,{uid:uniID, name: this.state.tempDeviceName, alarm: this.state.tempAlarm, alarmCode: this.state.tempAlarmCode, dateTime: this.state.tempDateTime, alarmDesc:form['alarmDesc'].value, alarmAck:this.state.alarmAck, alarmDismiss:this.state.alarmDismiss, alarmSeverity : this.state.tempAlarmSeverity, read: false}],
                tempAlarmCode:'', tempDateTime:date, tempAlarmDesc:'', alarmAck:false, alarmDismiss:false, tempAlarmSeverity:0
            })
            try{
                trackPromise(
                    await fetch(url+'alter_alarms', {headers :{
                        'Content-Type' : 'application/json',
                        'Accept' : 'applicaion/json'
                    },
                    method : "POST",
                    body : JSON.stringify({
                        "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"],
                        "alarms_data": [...this.state.tableDisplay,{uid:uniID, name: this.state.tempDeviceName, alarm: this.state.tempAlarm, alarmCode: this.state.tempAlarmCode, dateTime: this.state.tempDateTime, alarmDesc:form['alarmDesc'].value, alarmAck:this.state.alarmAck, alarmDismiss:this.state.alarmDismiss, alarmSeverity : this.state.tempAlarmSeverity, read: false}]
                    })
                })
                )
            }catch(e){
                console.log(e)
            }
        }
        else {
            window.alert("Please enter relevant details for Alarm");
        }
    }

  
    deleteCard(uid){
        // console.log(uid);
        var dataDisplay = this.state.tableDisplay;
        var tempDataDisplay = [];
        if(uid === 'all'){
            this.setState({tableDisplay: tempDataDisplay});
            try{
                trackPromise(
                    fetch(url+'alter_alarms', {headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify({
                        "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"],
                        // "user": JSON.parse(sessionStorage["default-session-data"])["userData"]["email"],
                        "alarms_data": tempDataDisplay
                    })       
                    })
                    );
            } catch (e){
                console.log(e)
            }    
        }
        else{
            for(let i=0;i<dataDisplay.length;i++){
                // console.log(dataDisplay[i].uid)
                if(dataDisplay[i].uid!=uid){
                    tempDataDisplay = [...tempDataDisplay, dataDisplay[i]];
                }
            }
            this.setState({tableDisplay: tempDataDisplay});
            try{
                trackPromise(
                    fetch(url+'alter_alarms', {headers : { 
                      'Content-Type': 'application/json',
                      'Accept': 'application/json'
                     },
                    method: "POST",
                    body: JSON.stringify({
                        "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"],
                        // "user": JSON.parse(sessionStorage["default-session-data"])["userData"]["email"],
                        "alarms_data": tempDataDisplay
                    })       
                    })
                    );
            } catch (e){
                console.log(e)
            }
        }
        
        // console.log(tempDataDisplay);
    }
    
    ackAlarm(e, alarmName){

    }

    submitHandler(e) {
        e.preventDefault();
    }

    onChangeRadio(event){
        this.setState({otherFactor : event.target.value})
    }
    phaseSelect(event){
        this.setState({phaseVal : event.target.value})
        // console.log(event.target.value);
    }
    setOtherLimit(event){
        this.setState({otherValue : event.target.value})
    }
    setHzLimit(event){
        this.setState({hzValue : event.target.value})
    }
    setPfLimit(event){
        this.setState({pfValue : event.target.value})
    }
    setWattLimit = (event) =>{
        this.setState({wattValue:event.target.value});
    }
    setVaLimit = (event) =>{
        console.log(event)
        this.setState({vaValue:event.target.value});
    }
    renderThresholdTable(){
        // console.log(this.state.thresholdArray);
        return this.state.thresholdArray.map((data, idx) =>{
            return(<tr>
                <td>Meter {data['uid']}</td>
                <td>{data['valueType']}</td>
                <td>{data['value']}</td>
            </tr>)
        })
    }
    closeModal(){
        this.setState({modal: false});
    }

    async deleteThreshold(uid){
        this.setState({modalThresholdDetails:false})
        console.log("delete threshold " + uid);
        
        try{
            await fetch(url+'delete_threshold', {headers :{
                'Content-Type' : 'application/json',
                'Accept' : 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"],
                    // "user": JSON.parse(sessionStorage["default-session-data"])["userData"]["email"],
                "uid" : uid
            })
        })
        }
        catch(e){
            console.log(e)
        }

        this.viewThreshold()
    }
    render(){
        const maxDate = new Date();

        let alarms = (
            
        <Container fluid className="main-content-container px-4  H-100 ">
            <Modal isOpen={this.state.modalVisible} style={{           
                    overlay: { 
                        position: 'fixed', 
                        top: 0,
                        left: '75%',
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        zIndex:1,
                  },
                  content: {
                    position: 'absolute',
                    top: '8%',
                    left: '3%',
                    right: '0%',
                    bottom: '8%',
                    border: '1px solid #ccc',
                    background: '#FFFFFF',
                    overflowY: 'auto',
                    overflowX:'hidden',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '4px',
                  }
                  }}>
                
            <Card style={{backgroundColor:'rgb(37, 41, 88)',paddingLeft:'13%'}}><h4 className="text-center mt-5" style={{color:'rgb(0,191,255)'}}>Settings</h4>
             <Row >
           <Col className='ml-4'><h5 style={{color:'rgb(0,191,255)'}}>Select Meter</h5></Col>
            </Row>
            <Row className='pb-3 pl-4'>
           <Col >
           <select className="ml-4" style={{height:'30px', maxWidth:'25vh'}} onChange={(event) => this.meterSelect(event)} ><option value='none'>Select One</option>
           {this.state.meter_list.map((meter, idx) =>{
                return(<option key={idx} value={'Meter '+meter['meter']}>{meter['name']}</option>
                    
                )
                })}</select>
           </Col>
            </Row >

            <Row className='ml-3 mt-3'>
                <Col><h5 style={{color:'rgb(0,191,255)'}} >Voltage Max</h5></Col>
            </Row>
            <Row>
            <Col className='ml-5'>
                <Box width={200}>
                <Slider
                    size="small"
                    min={0}
                    max={12000}
                    step={10}
                    value={this.state.maxVoltageLL}
                    defaultValue={null}
                    aria-label="Small"
                    valueLabelDisplay="auto"  onChange={this.maxVoltageLL}>
                     {[1,10,20,30,40,50,60,70,80,90,100].map((value)=>(
                         <SliderLabel
                         title={value.toString()}
                         key={value}
                         position={value}
                         onChange={(value)=>this.setState(value)}></SliderLabel>
                    ))}

                    </Slider>
                    <form onSubmit={this.submitHandler}>
                        <input type="text" placeholder="Threshold Value..." value={this.state.maxVoltageLL} onChange={this.maxVoltageLL}></input>
                    </form>
                </Box>
                </Col>
            </Row>
            {/* <Row className='ml-3 mt-3'>
                <Col><h5 style={{color:'rgb(0,191,255)'}}>Voltage Min</h5></Col>
            </Row>
            <Row>
            <Col className='ml-5'>
            <Box width={200}>

            <Slider
                    size="small"
                    min={0}
                    max={600}
                    step={1}
                    value={this.state.minVoltageLL}
                    defaultValue={180}
                    aria-label="Small"
                    valueLabelDisplay="auto"  onChange={this.minVoltageLL}>
                     {[1,10,20,30,40,50,60,70,80,90,100].map((value)=>(
                         <SliderLabel
                         title={value.toString()}
                         key={value}
                         position={value}
                         onChange={(value)=>this.setState({minVoltageLL:0})}></SliderLabel>
                    ))}

                    </Slider>
                    <form onSubmit={this.submitHandler}>
                        <input type="text" value={this.state.minVoltageLL} onChange={this.minVoltageLL} ></input>
                    </form>
                    </Box>
                </Col>
            </Row>  */}
            <Row className='ml-3 mt-3'>
                <Col><h5 style={{color:'rgb(0,191,255)'}}>Current Max</h5></Col>
            </Row>
            <Row>
            <Col className='ml-5'>
                <Box width={200}>
                    <Slider
                        size="small"
                        min={0}
                        max={2000}
                        step={10}
                        value={this.state.maxCurrent}
                        defaultValue={null}
                        aria-label="Small"
                        valueLabelDisplay="auto" onChange={this.maxCurrent}
                    />
                    <form onSubmit={this.submitHandler}>
                        <input type="text" placeholder="Threshold Value..." value={this.state.maxCurrent} onChange={this.maxCurrent} ></input>
                    </form>
                </Box>
                </Col>
            </Row>
            {/* <Row className='ml-3 mt-3'>
                <Col><h5 style={{color:'rgb(0,191,255)'}}>Current Min</h5></Col>
            </Row>
            <Row>
            <Col className='ml-5'>
                <Box width={200}>
                <Slider
                    size="small"
                    min={0}
                    max={100}
                    step={1}
                    value={this.state.minCurrent}
                    defaultValue={70}
                    aria-label="Small"
                    valueLabelDisplay="auto" onChange={this.minCurrent}
                />
                <form onSubmit={this.submitHandler}>
                    <input type="text" value={this.state.minCurrent} onChange={this.minCurrent} ></input>
                </form>
                </Box>
                </Col>
            </Row> */}
            <Row className='ml-3 mt-3'>
                <Col><h5 style={{color:'rgb(0,191,255)'}}>Phase</h5></Col>
            </Row>
            <Row className='ml-4 mr-4' onChange={this.phaseSelect}>
                {/* <div onChange={this.phaseSelect}> */}
                <div className="d-flex justify-content-between">
                    <span><input className="ml-2" type="radio" value="1"/><h6 style={{color:'white'}}>Phase 1</h6></span>
                    <span><input className="ml-4" type="radio" value="2"/><h6 style={{color:'white'}}>Phase 2</h6></span>
                    <span><input className="ml-4" type="radio" value="3"/><h6 style={{color:'white'}}>Phase 3</h6></span>
                </div>
                {/* </div> */}
            </Row>
            <Row className='ml-3 mt-3'>
                <Col><h5 style={{color:'rgb(0,191,255)'}}>Other Factors</h5></Col>
            </Row>
            <Row>
            <Col className='ml-5 pb-3'>
                <Box width={200}>
                <h6 className="px-1" style={{color:'white'}}>Kwh</h6>
                <Slider
                    size="small"
                    min={0}
                    max={999999999}
                    step={10000}
                    value={this.state.otherValue}
                    defaultValue={null}
                    aria-label="Small"
                    valueLabelDisplay="auto" onChange={this.setOtherLimit}
                />
                
                <form onSubmit={this.submitHandler}>
                        <input type="text" placeholder="Threshold Value..." value={this.state.otherValue} onChange={this.setOtherLimit} ></input>
                    </form>
                </Box>
                </Col>
            </Row>
            {/* <Row>
            <Col className='ml-5 pb-3'>
            <h6 className="px-1" style={{color:'white'}}>Frequency</h6>
                <Box width={200}>
                <Slider
                    size="small"
                    min={0}
                    max={60}
                    step={1}
                    value={this.state.hzValue}
                    defaultValue={0}
                    aria-label="Small"
                    valueLabelDisplay="auto" onChange={this.setHzLimit}
                />
                <form onSubmit={this.submitHandler}>
                    <input type="text" value={this.state.hzValue} onChange={this.setHzLimit} ></input>
                </form>
                </Box>
                </Col>
            </Row>
            <Row>
            <Col className='ml-5 pb-3'>
            <h6 className="px-1" style={{color:'white'}}>Power Factor</h6>
                <Box width={200}>
                <Slider
                    size="small"
                    min={0}
                    max={1}
                    step={0.001}
                    value={this.state.pfValue}
                    defaultValue={0}
                    aria-label="Small"
                    valueLabelDisplay="auto" onChange={this.setPfLimit}
                />
                <form onSubmit={this.submitHandler}>
                    <input type="text" value={this.state.pfValue} onChange={this.setPfLimit} ></input>
                </form>
                </Box>
                </Col>
            </Row> */}
            <Row>
            <Col className='ml-5 pb-3'>
            <h6 className="px-1" style={{color:'white'}}>Power (Watt)</h6>
                <Box width={200}>
                <Slider
                    size="small"
                    min={0}
                    max={8000}
                    step={10}
                    value={this.state.wattValue}
                    defaultValue={null}
                    aria-label="Small"
                    valueLabelDisplay="auto" onChange={this.setWattLimit}
                />
                <form onSubmit={this.submitHandler}>
                    <input type="text" placeholder="Threshold Value..." value={this.state.wattValue} onChange={this.setWattLimit} ></input>
                </form>
                </Box>
                </Col>
            </Row>
            <Row>
            <Col className='ml-5 pb-3'>
            <h6 className="px-1" style={{color:'white'}}>Power (VA)</h6>
                <Box width={200}>
                <Slider
                    size="small"
                    min={0}
                    max={8000}
                    step={10}
                    value={this.state.vaValue}
                    defaultValue={null}
                    aria-label="Small"
                    valueLabelDisplay="auto" onChange={this.setVaLimit}
                />
                <form onSubmit={this.submitHandler}>
                    <input type="text" placeholder="Threshold Value..." value={this.state.vaValue} onChange={this.setVaLimit} ></input>
                </form>
                </Box>
                </Col>
            </Row>
            {/* <Row className='mx-1' onChange={this.onChangeRadio}>
                <input type="radio" value="kwh" /><h6 className="px-1" style={{color:'white'}}>Kwh</h6>
                <input type="radio" value="hz" /><h6 style={{color:'white'}}>Frequency</h6>
                <input type="radio" value="pf" /><h6 style={{color:'white'}}>Power Factor</h6>
            </Row> */}
            <Row>
                <Col className="text-center my-3"><Button onClick={this.addThreshold}>Add Thresholds</Button></Col>
                <Col className="text-center my-3"><Button onClick={this.viewThreshold}>View Thresholds</Button></Col>
            </Row>
            </Card>
            </Modal>
            <Row noGutters ="true" className="page-header">
                <PageTitle title="Alarms" subtitle="Set Alarms" className="text-sm-left"/>
            </Row>
            <LoadingIndicator />
            <Modal isOpen={this.state.modalThreshold} onRequestClose={this.closeModal} 
                style={{           
                    overlay: { 
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  },
                  content: {
                    position: 'absolute',
                    top: '20%',
                    left: '20%',
                    right: '20%',
                    bottom: '20%',
                    zIndex : '1000',
                    minWidth: '20hh',
                    border: '1px solid #ccc',
                    // background: '#172d44',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '4px'
                  }
                  }}>
                    <Container style={{backgroundColor:'#eaf1f8'}}>
                        <Card className="h-100">
                            <CardHeader>
                                <CardTitle>
                                    Thresholds Added successfully
                                </CardTitle>
                                <CardSubtitle>
                                    Current meter limits
                                </CardSubtitle>
                            </CardHeader>
                            <CardBody>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Meter number</th>
                                            <th>Variable</th>
                                            <th>Value</th>
                                            <th>Minimum Value</th>
                                            <th>Maximum Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.thresholdArray.map((data, idx) =>{
                                            return(<tr>
                                                {/* <td>{data['uid']}</td> */}
                                                <td>{this.state.tempDeviceName}</td>
                                                <td>{data['valueType']}</td>
                                                <td>{data['value']}</td>
                                                <td>{data['minVal']}</td>
                                                <td>{data['maxVal']}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </Table>

                                {/* <Button >Close Modal</Button> */}
                            </CardBody>
                            <Row>
                          <Col className='text-center pt-3'><Button onClick={()=> this.setState({modalThreshold:false})}>Close</Button></Col>
                      </Row>
                        </Card>
                    </Container>
                </Modal>
                <Modal isOpen={this.state.modalThresholdDetails} onRequestClose={this.closeModalDetails} 
                style={{           
                    overlay: { 
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        zIndex: 100
                  },
                  content: {
                    position: 'absolute',
                    top: '10%',
                    left: '10%',
                    right: '10%',
                    bottom: '10%',
                    // zIndex : '1000',
                    minWidth: '20hh',
                    border: '1px solid #ccc',
                    // background: '#172d44',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '4px'
                  }
                  }}>
                    <Container fluid style={{backgroundColor:'#eaf1f8'}}>
                        <Card className="h-100" style={{marginTop: '10px', overflow: 'auto', minHeight:'75vh', borderRadius:0}}>
                            <CardHeader>
                                <CardTitle>
                                    Details of Set Thresholds
                                </CardTitle>
                                <CardSubtitle>
                                    Alarm Conditions
                                </CardSubtitle>
                            </CardHeader>
                            <CardBody>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Sno</th>
                                            <th>Meter Name</th>
                                            <th>Voltage Max Phase 1-2</th>
                                            <th>Voltage Max Phase 2-3</th>
                                            <th>Voltage Max Phase 3-1</th>
                                            {/* <th>Voltage Min Phase 1-2</th>
                                            <th>Voltage Min Phase 2-3</th>
                                            <th>Voltage Min Phase 3-1</th> */}
                                            <th>Current Max Phase 1</th>
                                            <th>Current Max Phase 2</th>
                                            <th>Current Max Phase 3</th>
                                            {/* <th>Current Min Phase 1</th>
                                            <th>Current Min Phase 2</th>
                                            <th>Current Min Phase 3</th> */}
                                            <th>KWH Max</th>
                                            {/* <th>Frequency Max</th>
                                            <th>Power Factor Min</th> */}
                                            <th>Power (Watt)</th>
                                            <th>Power (VA)</th>
                                            <th><input type="checkbox" id={"delete threshold all"} name={"delete threshold all"} value={"delete threshold all"} onChange={() =>this.deleteThreshold('all')}/></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {this.state.thresholdDetailsArray.map((data, idx) =>{
                                            return(<tr id={data['uid']}>
                                                {/* <td>{data['uid']}</td> */}
                                                <td>{data['uid']}</td>
                                                <td>{data['display_name']}</td>
                                                <td>{data['vMaxP12']}</td>
                                                <td>{data['vMaxP23']}</td>
                                                <td>{data['vMaxP31']}</td>
                                                {/* <td>{data['vMinP12']}</td>
                                                <td>{data['vMinP23']}</td>
                                                <td>{data['vMinP31']}</td> */}
                                                <td>{data['cMaxP1']}</td>
                                                <td>{data['cMaxP2']}</td>
                                                <td>{data['cMaxP3']}</td>
                                                {/* <td>{data['cMinP1']}</td>
                                                <td>{data['cMinP2']}</td>
                                                <td>{data['cMinP3']}</td> */}
                                                <td>{data['kwhMax']}</td>
                                                {/* <td>{data['hzMax']}</td>
                                                <td>{data['pfMin']}</td> */}
                                                <td>{data['wattMax']}</td>
                                                <td>{data['vaMax']}</td>
                                                <td>{<input type="checkbox" id={"delete threshold"+data['uid']} name={"delete threshold"+data['uid']} value={"delete threshold"+data['uid']} onChange={() =>this.deleteThreshold(data['uid'])}/>}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </Table>

                                {/* <Button >Close Modal</Button> */}
                            </CardBody>
                            <Row>
                          <Col className='text-center pt-3'><Button onClick={()=> this.setState({modalThresholdDetails:false})}>Close</Button></Col>
                      </Row>
                        </Card>
                    </Container>
                </Modal>
        <Col md="8">
            <Card style={{borderRadius:'0'}} className="my-3 p-2">
                <div className="d-flex justify-content-between"><form style={{fontFamily:'Arial, Helvetica, sans-serif', fontSize:'16'}}>
                    {/* <label>Schedule Alarm for 
                            <select onChange={() => this.meterSelect}>
                                <option value='none'>Select One</option>
                                <option value='Meter 1'>Meter 1</option>
                                <option value='Meter 2'>Meter 2</option>
                                <option value='Meter 3'>Meter 3</option>
                                <option value='Meter 4'>Meter 4</option>
                                <option value='Meter 5'>Meter 5</option>
                                <option value='Meter 6'>Meter 6</option>
                                <option value='Meter 7'>Meter 7</option>
                                <option value='Meter 8'>Meter 8</option>
                                <option value='Meter 9'>Meter 9</option>
                                <option value='Meter 10'>Meter 10</option>
                                <option value='Meter 11'>Meter 11</option>
                                <option value='Meter 12'>Meter 12</option>
                                <option value='Meter 13'>Meter 13</option>
                                <option value='Meter 14'>Meter 14</option>
                                <option value='Meter 15'>Meter 15</option>
                            </select>
                            at
                            <DateTimePicker
                                onChange = {(value) => this.setState({tempDateTime : value})}
                                value = {this.state.value}
                            />
                    </label>
                    <label>
                        Select Trigger Condition:
                        <select onChange={this.size}>
                            <option value=''>Select one</option>
                        </select>
                    </label>
                    <label>
                        Input Value
                        <select onChange={this.size}>
                            <option value=''>Select one</option>
                        </select>
                    </label> */}
        
                  
                </form>
                </div>
            {/* </Card>
            <Card style={{borderRadius:'0'}}> */}
            <Table striped>
                <thead>
                    <tr>
                        <th scope="col">Device</th>
                        <th scope="col">Alarm</th>
                        <th scope="col">Alarm Code</th>
                        <th scope="col">Date/Time</th>
                        <th scope="col">Alarm Description</th>
                        <th scope="col">Actions <input type="checkbox" id={"delete all"} name={"delete all"} value={"delete all"} onChange={() =>this.deleteCard("all")}/></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.tableDisplay.map((data, idx)=>{
                            // console.log(data.datetime);
                            return(<tr>
                                <td>{data.name}</td>
                                <td>
                                    <svg width="0" height="0">
                                    <linearGradient id="red-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                                        <stop stopColor={data.alarmSeverity == '3' ? "#F01C1C" : (data.alarmSeverity == '2' ? "#E8F01C":"#1CF08D")} offset="0%" />
                                        <stop stopColor="#ffffff" offset="100%" />
                                    </linearGradient>
                                    </svg>
                                    <FaCircle style={{ fill : "url(#red-gradient)" }}/>  {data.alarm}</td>
                                <td>{data.alarmCode}</td>
                                <td>{data.datetime}</td>
                                <td>{data.alarmDesc}</td>
                                <td><input type="checkbox" id={"delete"+data.uid} name={"delete"+data.uid} value={"delete"+data.uid} onChange={() =>this.deleteCard(data.uid)}/>Dismiss</td>
                                {/* <td><Link to={this.size} style={{color:'black'}}>Ack...<FcCheckmark /></Link> <Link to={this.size} style={{color:'black'}}>Dismiss... <CgCloseO style={{ color: '#F01C1C' }} /></Link></td> */}
                            </tr>)
                        })
                    }
                </tbody>
            </Table>
            </Card>
        </Col>
        <Col md="1"></Col>
        </Container>
        );

        return alarms;
    }
}