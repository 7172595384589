import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { url } from "../../../../utilities/requests";
import Graphs from "../reportGraph";
import Select from "react-select";
import ReactSelect from "../../dashboard-tools-component/common-multiselect";
import makeAnimated from "react-select/animated";
import { setEDate ,sDate,switchInt} from "./diagnosticActions";

import "animate.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Button,
} from "shards-react";
import PageTitle from "../../default-dashboard-layout/main-title-dashboard";
import {
  AiFillForward,
  AiFillBackward,
  AiFillFastForward,
  AiFillFastBackward,
} from "react-icons/ai";
import moment from "moment";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "../../all-dashboard-styles/diagnosticStyle.css";
import ReactMulti from "../../dashboard-tools-component/react-multiselect-function";
import { dummyData } from "../dummy";
import { HeadersH, Method, Token } from "../GlobalConst/GlobalConst";
class Diagnostic extends React.PureComponent {
  constructor(props) {
    super(props);
    var today = new Date(),
      time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    // var nowTime = new Date();
    // var prevTime = new Date(),
    // prevTime = prevTime.setHours(prevTime.getHours() - 1);
    // prevTime = prevTime.setMinutes(prevTime.getMinutes() - 30)

    this.state = {
      options: [{ label: "Meter 1", value: "1000" }],
      selecteditems: [],
      tempMeter: [],
      selectedMeters: [],
      selectedOptions: [],
      Readingitem: "",
      dateBegin: time,
      dateEnd: time,
      nowDate: time,
      monthData: [{ date: 0 }],
      weekData: [{ date: 0 }],
      Reading: [
        { label: "R-Y (V)", value: "voltage1" },
        { label: "Y-B (V)", value: "voltage2" },
        { label: "B-R (V)", value: "voltage3" },
        { label: "R (A)", value: "current1" },
        { label: "Y (A)", value: "current2" },
        { label: "B (A)", value: "current3" },
        { label: "kWh", value: "kWh" },
        { label: "pf", value: "pf" },
        { label: "hz", value: "hz" },
        { label: "kva", value: "kva" },
        { label: "kw", value: "kw" },
        { label: "R (V)", value: "induction12" },
        { label: "Y (V)", value: "induction23" },
        { label: "B (V)", value: "induction31" },
        { label: "Average (A)", value: "currentAvg" },
        { label: "Average L-L (V)", value: "voltageAvgLL" },
        { label: "Average (V)", value: "voltageAvg" },
        { label: "var", value: "var" },
        { label: "kvarh", value: "kvarh" },
      ],
      v1: false,
      v2: false,
      v3: false,
      i1: false,
      i2: false,
      i3: false,
      kwh: false,
      pf: false,
      hz: false,
      Kva: false,
      watt: false,
      vln1: false,
      vln2: false,
      vln3: false,
      cavg: false,
      vavg: false,
      vlnavg: false,
      var: false,
      kvarh: false,
      keysGraph: ["0"],
      selectedOption: "Week",
      open: false,
      graphState: "Area",
      openChart: false,
      // "#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA"
      colorDict: {
        1: "#0074D9",
        2: "#FF4136",
        3: "#2ECC40",
        4: "#FF851B",
        5: "#7FDBFF",
        6: "#B10DC9",
        7: "#FFDC00",
        8: "#001f3f",
        9: "#39CCCC",
        10: "#01FF70",
        11: "#85144b",
        12: "#F012BE",
        13: "#3D9970",
        14: "#111111",
        15: "#AAAAAA",
        16: "#0074D9",
        17: "#FF4136",
        18: "#2ECC40",
        19: "#FF851B",
        20: "#7FDBFF",
        21: "#B10DC9",
        22: "#FFDC00",
        23: "#001f3f",
        24: "#39CCCC",
        25: "#01FF70",
        26: "#85144b",
        27: "#F012BE",
        28: "#3D9970",
        29: "#111111",
        30: "#AAAAAA",
        31: "#0074D9",
        32: "#FF4136",
        33: "#2ECC40",
        34: "#FF851B",
        35: "#7FDBFF",
        36: "#B10DC9",
        37: "#FFDC00",
        38: "#001f3f",
        39: "#39CCCC",
        40: "#01FF70",
        41: "#85144b",
        42: "#F012BE",
        43: "#3D9970",
        44: "#111111",
        45: "#AAAAAA",
        46: "#0074D9",
        47: "#FF4136",
        48: "#2ECC40",
        49: "#FF851B",
        50: "#7FDBFF",
        51: "#B10DC9",
        52: "#FFDC00",
        53: "#001f3f",
        54: "#39CCCC",
        55: "#01FF70",
        56: "#85144b",
        57: "#F012BE",
        58: "#3D9970",
        59: "#111111",
        60: "#AAAAAA",
        61: "#0074D9",
        62: "#FF4136",
        63: "#2ECC40",
        64: "#FF851B",
        65: "#7FDBFF",
        66: "#B10DC9",
        67: "#FFDC00",
        68: "#001f3f",
        69: "#39CCCC",
        70: "#01FF70",
        71: "#85144b",
        72: "#F012BE",
        73: "#3D9970",
        74: "#111111",
        75: "#AAAAAA",
        76: "#0074D9",
        77: "#FF4136",
        78: "#2ECC40",
        79: "#FF851B",
        80: "#7FDBFF",
        81: "#B10DC9",
        82: "#FFDC00",
        83: "#001f3f",
        84: "#39CCCC",
        85: "#01FF70",
        86: "#85144b",
        87: "#F012BE",
        88: "#3D9970",
        89: "#111111",
        90: "#AAAAAA",
        91: "#0074D9",
        92: "#FF4136",
        93: "#2ECC40",
        94: "#FF851B",
        95: "#7FDBFF",
        96: "#B10DC9",
        97: "#FFDC00",
        98: "#001f3f",
        99: "#39CCCC",
        100: "#01FF70",
        101: "#85144b",
        102: "#F012BE",
        103: "#3D9970",
        104: "#111111",
        105: "#AAAAAA",
        106: "#0074D9",
        107: "#FF4136",
        108: "#2ECC40",
        109: "#FF851B",
        110: "#7FDBFF",
      },
      time: 0,
      interval: "1 Hour",
      startDate: null,
      endDate: null,
      startHour: "00",
      endHour: "00",
      startMinute: "00",
      endMinute: "00",
      dataGraph: dummyData,
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.DisplayData = this.DisplayData.bind(this);
    this.ReadingChange = this.ReadingChange.bind(this);
    this.getData = this.getData.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.handleMeterChange = this.handleMeterChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleChart = this.toggleChart.bind(this);
    this.timeMinus = this.timeMinus.bind(this);
    this.timePlus = this.timePlus.bind(this);
    this.timeGetData = this.timeGetData.bind(this);
    this.toggleInterval = this.toggleInterval.bind(this);
    this.switchInterval = this.switchInterval.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.graphDataWithInterval = this.graphDataWithInterval.bind(this);
    this.startHourSelect = this.startHourSelect.bind(this);
    this.endHourSelect = this.endHourSelect.bind(this);
    this.startMinuteSelect = this.startMinuteSelect.bind(this);
    this.endMinuteSelect = this.endMinuteSelect.bind(this);
    this.thisInterval = this.thisInterval.bind(this);
  }
  async handleGraphChange(data) {
    if (data["data"].length != 0) {
      let keys = await Object.keys(data["data"][0]);

      this.setState({
        dataGraph: data["data"],
        keysGraph: keys,
      });
    }
  }
  async setStartDate(date) {
    let sDate = date;
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    sDate = new Date(sDate - tzoffset).toISOString().slice(0, -1).split("T")[0];

    await this.setState({ startDate: sDate, tempStartDate: date });
  }
  async setEndDate(date) {
    const eDate = setEDate(date);
    await this.setState({ endDate: eDate, tempEndDate: date });
  }

  getData() {
    var meters = this.state.selectedMeters;
    var sensor = this.state.Readingitem;
    var interval = this.switchInterval(this.state.interval);
    var startDate = this.state.startDate;
    var endDate = this.state.endDate;
    var startHour = this.state.startHour;
    var endHour = this.state.endHour;
    var startMinute = this.state.startMinute;
    var endMinute = this.state.endMinute;
    let timezone = new Date().getTimezoneOffset();
    // console.log(timezone)
    startDate = startDate + "T" + startHour + ":" + startMinute + ":00";
    endDate = endDate + "T" + endHour + ":" + endMinute + ":00";
    if (meters != [] && sensor != "") {
      try {
        fetch(url + "comparisonGraph", {
          headers:HeadersH,
          method: Method,
          body: JSON.stringify({
            idToken: Token,
            meters: meters,
            sensor: sensor,
            time: 0,
            interval: interval,
            startDate: startDate,
            endDate: endDate,
            timezone: timezone,
          }),
        })
          .then((response) => response.json())
          .then((data) => this.handleGraphChange(data));
        // console.log(this.state.monthData);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log("None selected");
    }
    // console.log(meters);
    // console.log(sensor);
  }

  switchInterval(interval) {
    const si = switchInt(interval);
    return si;
  }

  timeGetData() {
    var meters = this.state.selectedMeters;
    var sensor = this.state.Readingitem;
    var time = this.state.time;
    var interval = this.switchInterval(this.state.interval);
    var startDate = this.state.startDate;
    var endDate = this.state.endDate;
    var startHour = this.state.startHour;
    var endHour = this.state.endHour;
    var startMinute = this.state.startMinute;
    var endMinute = this.state.endMinute;
    let timezone = new Date().getTimezoneOffset();
    startDate = startDate + "T" + startHour + ":" + startMinute + ":00";
    endDate = endDate + "T" + endHour + ":" + endMinute + ":00";
    if (meters != [] && sensor != "") {
      try {
        fetch(url + "comparisonGraph", {
          headers:HeadersH,
          method: Method,
          body: JSON.stringify({
            idToken: Token,
            meters: meters,
            sensor: sensor,
            time: time,
            interval: interval,
            startDate: startDate,
            endDate: endDate,
            timezone: timezone,
          }),
        })
          .then((response) => response.json())
          .then((data) => this.handleGraphChange(data));
        // console.log(this.state.monthData);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log("None selected");
    }
  }

  async handleChange(selecteditems) {
    await this.setState({ selectedMeters: selecteditems });
    // var tempMeter=this.state.selectedMeters;
  }

  handleMeterChange(selectedMeters) {
    this.setState(
      { tempMeter: selectedMeters, selectedMeters: selectedMeters },
      () => {
        this.getData();
      }
    );
  }

  componentDidMount() {
    this.loadData();
    setInterval(this.loadData, 3600000);
  }

  setOptions(data) {
    this.setState({ options: data["valMeter"] });
    let keys = Object.keys(this.state.dataGraoh[0]);
    this.setState({ keysGraph: keys });
  }

  loadData() {
    trackPromise(
      fetch(url + "meterlist", {
        headers: HeadersH,
        method: Method,
        body: JSON.stringify({
          idToken: Token,
        }),
      })
        .then((response) => response.json())
        .then((data) => this.setOptions(data))
    );
  }
  async ReadingChange(Readingitem) {
    // console.log(Readingitem['label']);
    if (Readingitem["value"] == "kva") {
      await this.setState({ Readingitem: "Kva", tempItem: "Kva" });
    } else if (Readingitem["value"] == "kw") {
      await this.setState({ Readingitem: "Kw", tempItem: "Kw" });
    } else {
      await this.setState({
        Readingitem: Readingitem["value"],
        tempItem: Readingitem["value"],
      });
    }

    // console.log(this.state.Readingitem);
    // var reading=this.state.Reading
  }

  DisplayData(event) {
    const {
      v1,
      v2,
      v3,
      i1,
      i2,
      i3,
      kwh,
      pf,
      hz,
      Kva,
      watt,
      vln1,
      vln2,
      vln3,
      cavg,
      vavg,
      vlnavg,
    } = this.state;
    // console.log("inside display data")
    var tar = event.target.value;
    switch (tar) {
      case "v1":
        return this.setState({ v1: !this.state.v1 });
      case "v2":
        return this.setState({ v2: !this.state.v2 });
      case "v3":
        return this.setState({ v3: !this.state.v3 });
      case "i1":
        return this.setState({ i1: !this.state.i1 });
      case "i2":
        return this.setState({ i2: !this.state.i2 });
      case "i3":
        return this.setState({ i3: !this.state.i3 });
      case "kwh":
        return this.setState({ kwh: !this.state.kwh });
      case "pf":
        return this.setState({ pf: !this.state.pf });
      case "hz":
        return this.setState({ hz: !this.state.hz });
      case "kva":
        return this.setState({ Kva: !this.state.Kva });
      case "watt":
        return this.setState({ watt: !this.state.watt });
      case "vln1":
        return this.setState({ vln1: !this.state.vln1 });
      case "vln2":
        return this.setState({ vln2: !this.state.vln2 });
      case "vln3":
        return this.setState({ vln3: !this.state.vln3 });
      case "cavg":
        return this.setState({ cavg: !this.state.cavg });
      case "vavg":
        return this.setState({ vavg: !this.state.vavg });
      case "vlnavg":
        return this.setState({ vlnavg: !this.state.vlnavg });
      case "var":
        return this.setState({ var: !this.state.var });
      case "kvarh":
        return this.setState({ kvarh: !this.state.kvarh });
    }
  }

  toggle() {
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  }
  toggleChart() {
    this.setState((prevState) => {
      return { openChart: !prevState.openChart };
    });
  }
  toggleInterval() {
    this.setState((prevState) => {
      return { openInterval: !prevState.openInterval };
    });
  }
  async timeMinus() {
    await this.setState({ time: this.state.time - 1 });
    this.timeGetData();
  }
  timePlus() {
    if (this.state.time == 0) {
      window.alert("Latest time selected!");
      this.setState({ time: 0 });
    } else {
      this.setState({ time: this.state.time + 1 });
    }
    this.setState({ time: this.state.time + 1 });
    this.timeGetData();
  }

  graphDataWithInterval() {
    const every_nth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);
    let n = this.switchInterval(this.state.interval);
    let data = every_nth(this.state.dataGraph, n);
    return data;
  }

  thisInterval(interval) {
    this.setState({ interval: interval }, () => {
      this.getData();
    });
  }
  startHourSelect(event) {
    // console.log(event.target.value)
    this.setState({ startHour: event.target.value });
  }
  startMinuteSelect(event) {
    // console.log(event.target.value)
    this.setState({ startMinute: event.target.value });
  }

  endHourSelect(event) {
    // console.log(event.target.value)
    this.setState({ endHour: event.target.value });
  }
  endMinuteSelect(event) {
    // console.log(event.target.value)
    this.setState({ endMinute: event.target.value });
  }

  render() {
    const {
      v1,
      v2,
      v3,
      i1,
      i2,
      i3,
      kwh,
      pf,
      hz,
      Kva,
      watt,
      vln1,
      vln2,
      vln3,
      cavg,
      vavg,
      vlnavg,
    } = this.state;
    const { DisplayData } = this;
    // console.log(this.state.options)
    const animatedComponents = makeAnimated();
    const {
      Reading,
      options,
      selecteditems,
      selectedMeters,
      tempMeter,
      selectedOptions,
      Readingitem,
    } = this.state;

    return (
      <div>
        <Container className="main-content-container m-0 h-200">
          <Row
            nogutters="true"
            className="page-header d-flex justify-content-between"
          >
            <PageTitle
              md={1}
              title="Diagnostics"
              subtitle="Graphs"
              className="text-sm-left"
            />
          </Row>
          <Row className="pt-3 w-100">
            <CardHeader style={{ backgroundColor: "#e0dff5" }}>
              <h5
                className="text-center"
                style={{
                  color: "#070357",
                  fontWeight: 200,
                  letterSpacing: 15,
                  fontSize: 40,
                }}
              >
                Comparison Graph
              </h5>
              <Card md="12" style={{ height: "105vh" }}>
                <Row>
                  <Col className="mx-3 pt-3 ">
                    <Row style={{ marginTop: 30 }}>
                      <Col>
                        <h5>Sensor ({Readingitem})</h5>
                      </Col>
                      <Col>
                        <h5>Start Date</h5>
                      </Col>
                      <Col>
                        <h5>End Date</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={4}
                        className="d-flex align-item-start flex-column col-md-auto"
                      >
                        <div style={{ width: "100%" }}>
                          <Select
                            placeholder={
                              Readingitem ? Readingitem : "Select value..."
                            }
                            options={Reading}
                            value={Readingitem}
                            onChange={this.ReadingChange}
                            labelledBy="Readings"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="d-flex justify-content-start pb-1">
                          {/* <span>Start:</span> */}
                          <DatePicker
                            placeholderText="Select Date..."
                            style={{
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                              borderLeft: "none",
                              borderColor: "lightgray",
                            }}
                            selected={this.state.tempStartDate}
                            onChange={(date) => this.setStartDate(date)}
                          />
                          <select
                            className="select-dropdown-hour"
                            defaultValue="none"
                            onChange={this.startHourSelect}
                          >
                            <option value="none">Hour</option>
                            <option value="00">00</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            {/* <option value="24">24</option> */}
                          </select>
                          <select
                            className="select-dropdown-minute"
                            defaultValue="none"
                            onChange={this.startMinuteSelect}
                          >
                            <option value="none">Min</option>
                            <option value="00">00</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex justify-content-start pb-1">
                          {/* <span>End :</span> */}
                          <DatePicker
                            placeholderText="Select Date..."
                            selected={this.state.tempEndDate}
                            onChange={(date) => this.setEndDate(date)}
                          />
                          <select
                            className="select-dropdown-hour"
                            defaultValue="none"
                            onChange={this.endHourSelect}
                          >
                            <option value="none">Hour</option>
                            <option value="00">00</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            {/* <option value="24">24</option> */}
                          </select>
                          <select
                            className="select-dropdown-minute"
                            defaultValue="none"
                            onChange={this.endMinuteSelect}
                          >
                            <option value="none">Min</option>
                            <option value="00">00</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                      <Col md={4}>
                        <h5>Meter Select</h5>
                        <ReactMulti
                          options={options}
                          selectedMeters={selectedMeters}
                          handleMeterChange={this.handleMeterChange}
                        />

                        {/* <ReactSelect 
                                // defaultValue = {this.state.alreadySelectedMeters}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                options={options} 
                                onChange={this.handleMeterChange}
                                value = {selectedMeters}
                                isMulti = {true}
                                isDisabled={selectedMeters.length>=8?true:false}
                                className = "basic-mmulti-select"
                                classNamePrefix = "select"
                            /> */}
                      </Col>
                      <Col md={3}>
                        <h5>Select Graph</h5>
                        <Dropdown
                          open={this.state.openChart}
                          toggle={this.toggleChart}
                          group
                        >
                          {/* <Button>{this.state.graphState}</Button> */}
                          <DropdownToggle split>
                            {this.state.graphState}
                          </DropdownToggle>
                          <DropdownItem
                            onClick={() => (this.state.graphState = "Area")}
                          >
                            Area
                          </DropdownItem>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => (this.state.graphState = "Line")}
                            >
                              Line
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => (this.state.graphState = "Bar")}
                            >
                              Bar
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                (this.state.graphState = "Scatter")
                              }
                            >
                              Scatter
                            </DropdownItem>
                            {/* <DropdownItem onClick={() => this.state.graphState = "Radial"}>Radial</DropdownItem> */}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                      <Col md={3}>
                        <h5>Select Interval</h5>
                        <Dropdown
                          open={this.state.openInterval}
                          toggle={this.toggleInterval}
                          group
                        >
                          {/* <Button>{this.state.graphState}</Button> */}
                          <DropdownToggle split>
                            {this.state.interval}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => this.thisInterval("1 Min")}
                            >
                              1 Min
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => this.thisInterval("5 Min")}
                            >
                              5 Min
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => this.thisInterval("10 Min")}
                            >
                              10 Min
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => this.thisInterval("30 Min")}
                            >
                              30 Min
                            </DropdownItem>
                            <DropdownItem
                              selected
                              onClick={() => this.thisInterval("1 Hour")}
                            >
                              1 Hour
                            </DropdownItem>
                            {/* <DropdownItem onClick={() => this.state.graphState = "Radial"}>Radial</DropdownItem> */}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                      <Col md={2}>
                        <h5>View Graph</h5>
                        <Button onClick={() => this.timeGetData()}>
                          Display Graph
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Graphs
                  key="graphs-component" // Add a unique key prop here
                  keysGraph={this.state.keysGraph}
                  data={this.state.dataGraph}
                  selected={this.state.selectedMeters}
                  reading={this.state.Readingitem}
                  color={this.state.colorDict}
                  graphState={this.state.graphState}
                />
              </Card>
            </CardHeader>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Diagnostic;
