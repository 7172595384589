import React, { useRef } from "react";
import ReactSelect, {components} from "react-select";
import makeAnimated from 'react-select/animated';

export const MultiSelect = props => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "All"
  };

  const MoreSelectedBadge = ({ items }) => {
    const style = {
    //   marginLeft: "auto",
      background: "#d4eefa",
      borderRadius: "4px",
      fontFamily: "Open Sans",
      fontSize: "11px",
      padding: "3px",
      order: 99,
      color: 'black'
    };
  
    const title = items.join(", \n");
    const length = items.length;
    const label = `${length} item${length !== 1 ? "s" : ""} selected`;
  
    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };
  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 0;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x) => x.label);
  
    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };
  
  const isSelectAllSelected = () => {
    const selectedValues = valueRef.current || [];
    const options = props.options || [];
    return selectedValues.length === options.length;
  };

  const isOptionSelected = (option) => {
    const selectedValues = valueRef.current || [];
    const options = props.options || [];
    return (
      selectedValues.some(({ value }) => value === option.value) ||
      isSelectAllSelected()
    );
  };

  const getOptions = () => {
    const options = props.options || [];
    return [selectAllOption, ...options];
  };

  const getValue = () => {
    const options = props.options || [];
    return isSelectAllSelected() ? [selectAllOption] : props.value;
  };

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;
    const options = props.options || [];

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.onChange(options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted blue',
    //   color: state.isSelected ? 'grey' : 'black',
        color:'black',
    //   padding: 20,
    })
  };
  const selectedVals = getValue().map((x) => x.value);
  const hiddenOptions =
    selectedVals.length > 3 ? selectedVals.slice(0, 3) : [];
  const options = getOptions().filter(
    (x) => !hiddenOptions.includes(x.value)
  );

  return (

    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      styles={customStyles}
      components={{MultiValue}}
    />
  );
};

export default MultiSelect;