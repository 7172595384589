import React from "react";
//import { Container, Row, Col } from "shards-react";
import { Row, Col,Container,  Card, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody, Button, CardSubtitle, Alert } from "shards-react";
import {url} from '../../../utilities/requests';
import PageTitle from "../../account/default-dashboard-layout/main-title-dashboard";
import '../all-dashboard-styles/dynamic-input.scss'
import {Oval} from 'react-loader-spinner';
import Table from 'react-bootstrap/Table'
import { usePromiseTracker } from "react-promise-tracker";
import Modal from "react-modal";
import ToggleButton from 'react-toggle-button'
import { trackPromise } from 'react-promise-tracker';
import Graphs from "../../account/dashboard-tools-component/graphsMeternew";
import Draggable from 'react-draggable';
import { propTypes } from "react-bootstrap/esm/Image";
import Box from '@material-ui/core/Box';
import { IoMdClose } from 'react-icons/io';
import {RiDragDropLine} from "react-icons/ri";
import { ConsoleView } from "react-device-detect";
import { DialogActions, rgbToHex } from "@material-ui/core";
import { ThreeSixtyTwoTone } from "@material-ui/icons";
import GraphsInternal from "../../account/dashboard-tools-component/Graph";
import HistoryTable from "../../account/dashboard-tools-component/HistoryTable";
import Modal2 from 'react-modal'
import { RiTShirtAirFill } from "react-icons/ri";
import zIndex from "@material-ui/core/styles/zIndex";
import { ThemeProvider } from "@emotion/react";
import Logo from '../../account/assets/Logo/shivoham-i.png'
import Select from 'react-select';
import ReactSelect from '../../account/dashboard-tools-component/common-multiselect';
import makeAnimated from 'react-select/animated';
import 'animate.css'
import { LoaderDots } from '@thumbtack/thumbprint-react';
import '../../account/all-dashboard-styles/scrollbar.css'
import { FaCircle } from 'react-icons/fa';
// import DateTimePicker from 'react-datetime-picker';
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import 'react-datetime-picker/dist/DateTimePicker.css';
import moment from 'moment';
import { useMediaQuery } from "react-responsive";
import '../all-dashboard-styles/history-input-field.scss'
import LoadingIndicator from "./promiseTracker";
const stateMappings = {v1: "v1",v2: "v2",v3: "v3",i1: "i1",i2: "i2",i3: "i3",kwh: "kwh",
  pf: "pf",hz: "hz",va: "va",watt: "watt",vln1: "vln1",vln2: "vln2",vln3: "vln3",cavg: "cavg",
  vavg: "vavg",vlnavg: "vlnavg",var: "var",
};
export default class Meters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.modalForm = React.createRef();
    this.state = {
      tempName: "",
      graphMeter: "",
      tempType: "",
      textColor: "",
      bgColor: "",
      Tutorial: false,
      tempMeter: [],
      tempSize: "",
      tempSubtitle: "",
      v1: false,
      v2: false,
      v3: false,
      i1: false,
      i2: false,
      i3: false,
      kwh: false,
      pf: false,
      hz: false,
      va: false,
      watt: false,
      vln1: false,
      vln2: false,
      vln3: false,
      cavg: false,
      vavg: false,
      vlnavg: false,
      var: false,
      startDate: new Date(),
      endDate: new Date(),
      result: [
        {
          uid: 1,
          name: "power1.Schneider_EM6436_dual",
          c1: 0,
          c2: 0,
          c3: 0,
          v1: 0,
          v2: 0,
          v3: 0,
          kwh: 0,
          hz: 0,
          pf: 0,
          display_name: "NK Proteins Meter",
          va: 0,
          watt: 0,
          vln1: 0,
          vln2: 0,
          vln3: 0,
          color: "",
          cavg: 0,
          vavg: 0,
          vlnavg: 0,
          startDate: Date(),
          endDate: Date(),
        },
      ],
      modal: false,
      modalVisible: false,
      graphData: {
        resultMonth: [
          { name: 1, Last: 0, Current: 0 },
          { name: 2, Last: 0, Current: 0 },
          { name: 3, Last: 0, Current: 0 },
          { name: 4, Last: 0, Current: 0 },
          { name: 5, Last: 0, Current: 0 },
          { name: 6, Last: 0, Current: 0 },
          { name: 7, Last: 0, Current: 0 },
          { name: 8, Last: 0, Current: 0 },
          { name: 9, Last: 0, Current: 0 },
          { name: 10, Last: 0, Current: 0 },
          { name: 11, Last: 0, Current: 0 },
          { name: 12, Last: 0, Current: 0 },
          { name: 13, Last: 0, Current: 0 },
          { name: 14, Last: 0, Current: 0 },
          { name: 15, Last: 0, Current: 0 },
          { name: 16, Last: 0, Current: 0 },
          { name: 17, Last: 0, Current: 0 },
          { name: 18, Last: 0, Current: 0 },
          { name: 19, Last: 0, Current: 0 },
          { name: 20, Last: 0, Current: 0 },
          { name: 21, Last: 0, Current: 0 },
          { name: 22, Last: 0, Current: 0 },
          { name: 23, Last: 0, Current: 0 },
          { name: 24, Last: 0, Current: 0 },
          { name: 25, Last: 0, Current: 0 },
          { name: 26, Last: 0, Current: 0 },
          { name: 27, Last: 0, Current: 0 },
          { name: 28, Last: 0, Current: 0 },
          { name: 29, Last: 0, Current: 0 },
          { name: 30, Last: 0, Current: 0 },
          { name: 31, Last: 0, Current: 0 },
        ],
        resultYear: [
          { name: 1, Last: 0, Current: 0 },
          { name: 2, Last: 0, Current: 0 },
          { name: 3, Last: 0, Current: 0 },
          { name: 4, Last: 0, Current: 0 },
          { name: 5, Last: 0, Current: 0 },
          { name: 6, Last: 0, Current: 0 },
          { name: 7, Last: 0, Current: 0 },
          { name: 8, Last: 0, Current: 0 },
          { name: 9, Last: 0, Current: 0 },
          { name: 10, Last: 0, Current: 0 },
          { name: 11, Last: 0, Current: 0 },
          { name: 12, Last: 0, Current: 0 },
        ],
        resultWeek: [
          { name: 1, Last: 0, Current: 0 },
          { name: 2, Last: 0, Current: 0 },
          { name: 3, Last: 0, Current: 0 },
          { name: 4, Last: 0, Current: 0 },
          { name: 5, Last: 0, Current: 0 },
          { name: 6, Last: 0, Current: 0 },
          { name: 7, Last: 0, Current: 0 },
        ],
      },
      display: [],
      selectMeterOptions: [
        { label: "N/A", value: "N/A" },
        // { label: 'Meter 2', value: 'meter2'},
        // { label: 'Meter 3', value: 'meter3'},
        // { label: 'Meter 4', value: 'meter4'},
        // { label: 'Meter 5', value: 'meter5'},
        // { label: 'Meter 6', value: 'meter6'},
        // { label: 'Meter 7', value: 'meter7'},
        // { label: 'Meter 8', value: 'meter8'},
        // { label: 'Meter 9', value: 'meter9'},
        // { label: 'Meter 10', value: 'meter10'}
      ],
      selectedMeters: [],
      selectedSingleMeter: "",
      deltaPosition: {
        x: 0,
        y: 0,
      },
      showLoader: "true",
    };
    this.handleChange = this.handleChange.bind(this);
    this.changeLoader = this.changeLoader.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cardName = this.cardName.bind(this);
    this.cardDisplayData = this.cardDisplayData.bind(this);
    this.cardSubtitle = this.cardSubtitle.bind(this);
    this.cardType = this.cardType.bind(this);
    this.size = this.size.bind(this);
    this.DisplayData = this.DisplayData.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.getData = this.getData.bind(this);
    this.graph_data_set = this.graph_data_set.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
    this.renderSelectMeter = this.renderSelectMeter.bind(this);
    this.renderSizeOptions = this.renderSizeOptions.bind(this);
    this.renderValueOptions = this.renderValueOptions.bind(this);
    this.singleDisplayData = this.singleDisplayData.bind(this);
    this.handleMeterChange = this.handleMeterChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleSingleMeterChange = this.handleSingleMeterChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.renderTimeSelection = this.renderTimeSelection.bind(this);
  }

  closeModal() {
    this.setState({ modal: false });
  }

  handleChange(data) {
    this.setState({
      result: data["result"],
      display: data["dash_data"],
      selectMeterOptions: data["valMeter"],
    });
  }

  changeLoader() {
    this.setState({ showLoader: false });
  }

  componentDidMount() {
    this.loadData();
    setInterval(this.loadData, 300000);
  }

  loadData() {
    try {
      trackPromise(
        fetch(url + "history_table", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            idToken: JSON.parse(sessionStorage["default-session-data"])[
              "userData"
            ]["idToken"],
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data["valMeter"].length == 0) {
              {
                console.log("sleeping");
                this.sleep(30000);
              }
            } else {
              this.setState({
                display: data["history_data"],
                selectMeterOptions: data["valMeter"],
                showLoader: false,
              });
              this.sleep(30000);
            }
          })
      );
    } catch (e) {
      console.log(e);
    }
  }

  cardName(event) {
    this.setState({ tempName: event.target.value });
  }

  handleMeterChange(selectedMeters) {
    this.setState({
      tempMeter: selectedMeters,
      selectedMeters: selectedMeters,
    });
  }

  handleSingleMeterChange(selectedSingleMeter) {
    this.setState({ tempMeter: selectedSingleMeter });
  }

  size(event) {
    this.setState({ tempSize: event.target.value });
  }

  cardSubtitle(event) {
    this.setState({ tempSubtitle: event.target.value });
  }

  async cardType(event) {
    await this.setState({ tempType: event.target.value });
  }

  async handleAdd() {
    const form = this.modalForm.current;
    const uniID =
      this.state.display.reduce((maxID, item) => Math.max(maxID, item.uid), 0) +
      1;
    const newItem = {
      uid: uniID,
      name: form["title"].value,
      meter: this.state.tempMeter,
      subtitle: form["subtitle"].value,
      type: this.state.tempType,
      size: this.state.tempSize,
      v1: this.state.v1,
      v2: this.state.v2,
      v3: this.state.v3,
      i1: this.state.i1,
      i2: this.state.i2,
      i3: this.state.i3,
      kwh: this.state.kwh,
      pf: this.state.pf,
      hz: this.state.hz,
      va: this.state.va,
      watt: this.state.watt,
      vln1: this.state.vln1,
      vln2: this.state.vln2,
      vln3: this.state.vln3,
      cavg: this.state.cavg,
      vavg: this.state.vavg,
      vlnavg: this.state.vlnavg,
      var: this.state.var,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    try {
      this.setState((prevState) => ({
        display: [...prevState.display, newItem],
        graphMeter: prevState.tempMeter,
        modal: false,
        startDate: new Date(),
        endDate: new Date(),
        tempName: null,
        tempType: null,
        tempMeter: [],
        selectedMeters: [],
        tempSize: null,
        tempSubtitle: null,
        v1: false,
        v2: false,
        v3: false,
        i1: false,
        i2: false,
        i3: false,
        kwh: false,
        pf: false,
        hz: false,
        va: false,
        watt: false,
        vln1: false,
        vln2: false,
        vln3: false,
        cavg: false,
        vavg: false,
        vlnavg: false,
        var: false,
      }));
      await fetch(url + "alter_history_data", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          idToken: JSON.parse(sessionStorage["default-session-data"])[
            "userData"
          ]["idToken"],
          history_data: [...this.state.display, newItem],
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  closeVisibleModal() {
    this.setState({ modalVisible: false });
  }


  deleteCard(uid) {
    var dataDisplay = this.state.display;
    var tempDataDisplay = [];
    for (let i = 0; i < dataDisplay.length; i++) {
      if (dataDisplay[i].uid != uid) {
        tempDataDisplay = [...tempDataDisplay, dataDisplay[i]];
      }
    }
    this.setState({ display: tempDataDisplay });

    try {
      fetch(url + "alter_history_data", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          idToken: JSON.parse(sessionStorage["default-session-data"])[
            "userData"
          ]["idToken"],
          history_data: tempDataDisplay,
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  DisplayData(event) {
    const tar = event.target.value;
    const stateVariable = stateMappings[tar];
    if (stateVariable) {
      this.setState((prevState) => ({
        [stateVariable]: !prevState[stateVariable],
      }));
    }
  }

  singleDisplayData(event) {
    const tar = event.target.value;
    const stateVariable = stateMappings[tar];
    if (stateVariable) {
      this.setState((prevState) => ({
        [stateVariable]: !prevState[stateVariable],
        var: false,
        vlnavg: false,
        vavg: false,
        cavg: false,
        vln1: false,
        vln2: false,
        vln3: false,
        v1: false,
        v2: false,
        v3: false,
        i1: false,
        i2: false,
        i3: false,
        kwh: false,
        pf: false,
        hz: false,
        va: false,
        watt: false,
      }));
    }
  }

  cardDisplayData = (props) => {
    if (props.props.type == "History") {
      // console.log(props.props.meter.value)
      var sensor = "";
      if (props.props.v1) {
        sensor = "voltage1";
      } else if (props.props.v2) {
        sensor = "voltage2";
      } else if (props.props.v3) {
        sensor = "voltage3";
      } else if (props.props.i1) {
        sensor = "current1";
      } else if (props.props.i2) {
        sensor = "current2";
      } else if (props.props.i3) {
        sensor = "current3";
      } else if (props.props.kwh) {
        sensor = "kWh";
      } else if (props.props.pf) {
        sensor = "pf";
      } else if (props.props.hz) {
        sensor = "hz";
      } else if (props.props.watt) {
        sensor = "watt";
      } else if (props.props.va) {
        sensor = "va";
      } else if (props.props.vln1) {
        sensor = "induction12";
      } else if (props.props.vln2) {
        sensor = "induction23";
      } else if (props.props.vln3) {
        sensor = "induction31";
      } else if (props.props.cavg) {
        sensor = "currentAvg";
      } else if (props.props.vavg) {
        sensor = "voltageAvg";
      } else if (props.props.vlnavg) {
        sensor = "voltageAvgLL";
      } else if (props.props.var) {
        sensor = "var";
      }
      return (
        <HistoryTable
          sensor={sensor}
          uid={props.props.meter.value}
          startDate={props.props.startDate}
          endDate={props.props.endDate}
        />
      );
    } else {
      return <CardBody></CardBody>;
    }
  };

  graph_data_set = (data, uid) => {
    var result = this.state.display;
    for (let i = 0; i < result.length; i++) {
      if (result[i].uid === uid) {
        result[i].graphData = data;
      }
    }
    this.setState({ display: result });
  };

  getData = async (sensor, uid, cardID) => {
    var dataResult = {};
    try {
      await trackPromise(
        fetch(url + "meters_graphs", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            idToken: JSON.parse(sessionStorage["default-session-data"])[
              "userData"
            ]["idToken"],
            uid: uid,
            sensor: sensor,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            this.graph_data_set(data, cardID);
          })
      );
    } catch (e) {
      console.log(e);
    }

    return dataResult;
  };

  renderSelectMeter() {
    const animatedComponents = makeAnimated();
    const { selectedMeters, selectedSingleMeter } = this.state;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted blue",
        //color: state.isSelected ? 'grey' : 'black',
        color: "black",
        //   padding: 20,
      }),
    };

    if (
      this.state.tempType == "Data" ||
      this.state.tempType == "Graph" ||
      this.state.tempType == "History"
    ) {
      return (
        <div>
          <h5 style={{ color: "white" }}>{this.state.tempMeter["label"]}</h5>
          <Select
            styles={customStyles}
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={this.state.selectMeterOptions}
            onChange={this.handleSingleMeterChange}
            value={selectedSingleMeter}
          />
        </div>
      );
    } else {
      return (
        <ReactSelect
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={this.state.selectMeterOptions}
          onChange={this.handleMeterChange}
          value={selectedMeters}
        />
      );
    }
  }

  renderSizeOptions() {
    if (this.state.tempType == "History") {
      return (
        <select onChange={this.size} className="selection">
          <option value="none">Select One</option>
          <option value="Large">Large</option>
          <option value="Full Screen">Full Screen</option>
        </select>
      );
    } else {
      return (
        <select onChange={this.size} className="selection">
          <option value="none">Select One</option>
        </select>
      );
    }
  }

  renderValueOptions() {
    if (this.state.tempType == "History") {
      return (
        <Col>
          <Row>
            <Col className="sub-title">Voltage :</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltage1"
                name="v1"
                value="v1"
                onChange={this.singleDisplayData}
                checked={this.state.v1}
              />{" "}
              R-Y
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage2"
                name="v2"
                value="v2"
                onChange={this.singleDisplayData}
                checked={this.state.v2}
              />{" "}
              Y-B
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage3"
                name="v3"
                value="v3"
                onChange={this.singleDisplayData}
                checked={this.state.v3}
              />{" "}
              B-R
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltageln1"
                name="vln1"
                value="vln1"
                onChange={this.singleDisplayData}
                checked={this.state.vln1}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln2"
                name="vln2"
                value="vln2"
                onChange={this.singleDisplayData}
                checked={this.state.vln2}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln3"
                name="vln3"
                value="vln3"
                onChange={this.singleDisplayData}
                checked={this.state.vln3}
              />{" "}
              B-N
            </Col>
          </Row>

          <Row>
            <Col className="sub-title mt-3">Current :</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="current1"
                name="i1"
                value="i1"
                onChange={this.singleDisplayData}
                checked={this.state.i1}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current2"
                name="i2"
                value="i2"
                onChange={this.singleDisplayData}
                checked={this.state.i2}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current3"
                name="i3"
                value="i3"
                onChange={this.singleDisplayData}
                checked={this.state.i3}
              />{" "}
              B-N
            </Col>
          </Row>

          <Row>
            <Col className="main">Other Values</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="kwh"
                name="kwh"
                value="kwh"
                onChange={this.singleDisplayData}
                checked={this.state.kwh}
              />{" "}
              KWh
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="pf"
                name="pf"
                value="pf"
                onChange={this.singleDisplayData}
                checked={this.state.pf}
              />{" "}
              PF
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="hz"
                name="hz"
                value="hz"
                onChange={this.singleDisplayData}
                checked={this.state.hz}
              />{" "}
              Freq.
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="watt"
                name="watt"
                value="watt"
                onChange={this.singleDisplayData}
                checked={this.state.watt}
              />{" "}
              KW
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="va"
                name="va"
                value="va"
                onChange={this.singleDisplayData}
                checked={this.state.va}
              />{" "}
              KVA{" "}
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="var"
                name="var"
                value="var"
                onChange={this.singleDisplayData}
                checked={this.state.var}
              />{" "}
              KVAR{" "}
            </Col>
          </Row>
        </Col>
      );
    } else {
      return (
        <Col>
          <Row>
            <Col className="sub-title">Voltage :</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltage1"
                name="v1"
                value="v1"
                onChange={this.DisplayData}
              />{" "}
              R-Y
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage2"
                name="v2"
                value="v2"
                onChange={this.DisplayData}
              />{" "}
              Y-B
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage3"
                name="v3"
                value="v3"
                onChange={this.DisplayData}
              />{" "}
              B-R
            </Col>
          </Row>
          <Row>
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltageln1"
                name="vln1"
                value="vln1"
                onChange={this.DisplayData}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln2"
                name="vln2"
                value="vln2"
                onChange={this.DisplayData}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln3"
                name="vln3"
                value="vln3"
                onChange={this.DisplayData}
              />{" "}
              B-N
            </Col>
          </Row>

          <Row>
            <Col className="sub-title mt-3">Current :</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="current1"
                name="i1"
                value="i1"
                onChange={this.DisplayData}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current2"
                name="i2"
                value="i2"
                onChange={this.DisplayData}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current3"
                name="i3"
                value="i3"
                onChange={this.DisplayData}
              />{" "}
              B-N
            </Col>
          </Row>
          <Row>
            <Col className="main">Other Values</Col>
          </Row>
          <Row>
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="kwh"
                name="kwh"
                value="kwh"
                onChange={this.DisplayData}
              />{" "}
              KWH
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="pf"
                name="pf"
                value="pf"
                onChange={this.DisplayData}
              />{" "}
              PF
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="hz"
                name="hz"
                value="hz"
                onChange={this.DisplayData}
              />{" "}
              Freq.
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="watt"
                name="watt"
                value="watt"
                onChange={this.DisplayData}
              />{" "}
              KW
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="va"
                name="va"
                value="va"
                onChange={this.DisplayData}
              />{" "}
              KVA{" "}
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="var"
                name="var"
                value="var"
                onChange={this.DisplayData}
              />{" "}
              KVAR{" "}
            </Col>
          </Row>
        </Col>
      );
    }
  }

  setStartDate(value) {
    this.setState({ startDate: value });
  }
  setEndDate(value) {
    let date = moment(value);
    this.setState({ endDate: value });
  }

  renderTimeSelection() {
    if (this.state.tempType == "History") {
      return (
        <div>
          <Col>
            <Row className="date-text">
              <Col>Start Date</Col>
            </Row>
            <Row>
              <Col>
                <DateTimePicker
                  className="picker-one"
                  value={this.state.startDate}
                  onChange={(value) => this.setStartDate(value)}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className="date-text">End Date</Col>
            </Row>
            <Row>
              <Col>
                <DateTimePicker
                  className="picker-two"
                  value={this.state.endDate}
                  onChange={(value) => this.setEndDate(value)}
                />
              </Col>
            </Row>
          </Col>
        </div>
      );
    } else {
      <div>
        <Col>
          <DateTimePicker
            disabled="true"
            value={this.state.startDate}
            onChange={(value) => this.setStartDate(value)}
          />
        </Col>
        <Col>
          <DateTimePicker
            disabled="true"
            value={this.state.endDate}
            onChange={(value) => this.setEndDate(value)}
          />
        </Col>
      </div>;
    }
  }

  closeMenuOption() {
    if (this.state.tempType == "Data" || this.state.tempType == "Graph")
      return true;
    else if (this.state.tempType == "Table") return false;
  }

  render() {
    return (
      <div>
        <Container fluid className="main-content-container px-4 h-100">
          <Row
            nogutters="true"
            className="page-header d-flex justify-content-between"
          >
            <PageTitle
              md={1}
              title="Meters"
              subtitle="Live Data"
              className="text-sm-left"
            />
          </Row>
          <Row>
            <Col className="mt-2">
              <Button
                onClick={() => this.setState({ modal: true })}
                className="pl-2"
              >
                Add Card
              </Button>
            </Col>
          </Row>
          <LoadingIndicator />
          <Modal
            isOpen={this.state.Tutorial}
            onRequestClose={this.closeVisibleTutorial}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                zIndex: 4,
              },
              content: {
                position: "absolute",
                top: "10%",
                left: "10%",
                right: "10%",
                bottom: "15%",
                border: "1px solid #ccc",
                background: "rgba(0, 0, 0, 0.2)",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                outline: "none",
                padding: "4px",
              },
            }}
          >
            <div>
              <Col className="text-center pt-3">
                <Button onClick={() => this.setState({ Tutorial: false })}>
                  Close
                </Button>
              </Col>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.modal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              content: {
                position: "absolute",
                top: "9%",
                left: "74%",
                right: "2%",
                bottom: "9%",
                border: "1px solid #ccc",
                background: "rgb(37,41,88)",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                outline: "none",
                padding: "4px",
              },
            }}
          >
            <Container>
              <form ref={this.modalForm}>
                <Row>
                  <Col className="main">Add Card Title</Col>
                </Row>
                <Row>
                  <Col className="sub-title">Title:</Col>
                  <Col>
                    <input type="text" name={"title"} />
                  </Col>
                </Row>
                <Row>
                  <Col className="sub-title">Subtitle:</Col>
                  <Col>
                    <input type="text" name={"subtitle"} />
                  </Col>
                </Row>
                <Row>
                  <Col className="main">Select Your Data To Display</Col>
                </Row>
                <Row>
                  <Col className="sub-title">Value</Col>
                  <Col>
                    <select
                      defaultValue="none"
                      onChange={this.cardType}
                      className="selection"
                    >
                      <option value="none">Select One</option>
                      <option value="History">History</option>
                      {/* <option value="mango">Mango</option> */}
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col className="main">Select Meter</Col>
                </Row>
                <Row>
                  <Col className="sub-title">Location </Col>
                  <Col>
                    <this.renderSelectMeter />
                  </Col>
                </Row>
                <Row>
                  <Col className="main">Select Card Size</Col>
                </Row>
                <Row>
                  <Col className="sub-title">Custom</Col>
                  <Col>
                    <this.renderSizeOptions />
                  </Col>
                </Row>
                <Row onClick={this.size}></Row>
                <Row>
                  <Col className="main">Select Readings</Col>
                </Row>
                <Row>
                  <this.renderValueOptions />
                </Row>
                <Row>
                  <Col className="main">Select Timing</Col>
                </Row>
                <Row>
                  <this.renderTimeSelection />
                </Row>
                <Row className="mt-3 mx-2">
                  <Col>
                    {" "}
                    <Button onClick={() => this.handleAdd()}>Add Card</Button>
                  </Col>
                  <Col>
                    <Button onClick={() => this.setState({ modal: false })}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </form>
            </Container>
          </Modal>
          <Row>
            {this.state.display.map((data, idx) => {
              if (data.size == "Large") {
                return (
                  <div key={data.uid}>
                    <Col md={6} className={"mb-5 mt-3"}>
                      <Card
                        style={{
                          minHeight: "40vh",
                          width: "100%",
                          overflow: "auto",
                          maxHeight: "90vh",
                        }}
                      >
                        <CardHeader>
                          <Row>
                            <Col md={3}>
                              <strong>
                                <RiDragDropLine style={{ color: "blue" }} />
                                <div style={{ fontSize: 7, color: "blue" }}>
                                  Drag
                                </div>
                              </strong>
                            </Col>
                            <Col md={6}>
                              <h6 className="text-center">{data.name}</h6>
                            </Col>
                            <Col className="align-items-right" md={2}>
                              <IoMdClose
                                size={25}
                                color="#800000"
                                id={"delete" + data.uid}
                                name={"delete" + data.uid}
                                value={"delete" + data.uid}
                                onClick={() => this.deleteCard(data.uid)}
                              />
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardSubtitle>
                          <Col className="ml-3">
                            <h6>
                              {" "}
                              {data.type === "History"
                                ? "History of " + data.meter.label
                                : data.subtitle}
                            </h6>
                          </Col>
                        </CardSubtitle>
                        <this.cardDisplayData
                          props={data}
                          state={this.state.result}
                        />
                      </Card>
                    </Col>
                  </div>
                );
              } else if (data.size == "Full Screen") {
                return (
                  <div key={data.uid}>
                    <Col md={12} className={"mb-5 mt-3"}>
                      <Card
                        style={{
                          minHeight: "40vh",
                          width: "100%",
                          overflow: "auto",
                          maxHeight: "90vh",
                        }}
                      >
                        <CardHeader>
                          <Row>
                            <Col md={3}>
                              <strong>
                                <RiDragDropLine style={{ color: "blue" }} />
                                <div style={{ fontSize: 7, color: "blue" }}>
                                  Drag
                                </div>
                              </strong>
                            </Col>
                            <Col md={6}>
                              <h6 className="text-center">{data.name}</h6>
                            </Col>
                            <Col className="align-items-right" md={2}>
                              <IoMdClose
                                size={25}
                                color="#800000"
                                id={"delete" + data.uid}
                                name={"delete" + data.uid}
                                value={"delete" + data.uid}
                                onClick={() => this.deleteCard(data.uid)}
                              />
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardSubtitle>
                          <Col className="ml-3">
                            <h6>
                              {" "}
                              {data.type === "History"
                                ? "History of " + data.meter.label
                                : data.subtitle}
                            </h6>
                          </Col>
                        </CardSubtitle>
                        <this.cardDisplayData
                          props={data}
                          state={this.state.result}
                        />
                      </Card>
                    </Col>
                  </div>
                );
              } else {
                return (
                  <div key={data.uid}>
                    <Col className={"mb-5 mt-3"}>
                      <Card>
                        <CardHeader>
                          <Col>{data.name}</Col>
                          <Col className="align-items-right" md={2}>
                            <IoMdClose
                              size={25}
                              color="#800000"
                              id={"delete" + data.uid}
                              name={"delete" + data.uid}
                              value={"delete" + data.uid}
                              onClick={() => this.deleteCard(data.uid)}
                            />
                          </Col>
                        </CardHeader>
                        <CardSubtitle>{data.subtitle}</CardSubtitle>
                        <this.cardDisplayData
                          props={data}
                          state={this.state.result}
                        />
                      </Card>
                    </Col>
                  </div>
                );
              }
            })}
          </Row>
        </Container>
      </div>
    );
  }
}
Modal.setAppElement(document.getElementById('root'));
