import React, { useEffect } from 'react';
import { selectApp, setAppSliceState ,logout} from '../state/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import Account from '../components/account/Account';
import Login from '../components/login/Login';
import Director from './director/Account';
import PropTypes from 'prop-types';
import { checkLoginStatus } from '../utilities/requests';
import persistentStorage from '../utilities/persistentStorage';
import Logout from './account/menu-header-footer-layout/Header-Components/HeaderLayout/user-logout-component';
const App = () => {
  const { currentPage } = useSelector(selectApp);
  const dispatch = useDispatch();
  useEffect(() => {
    const userData = persistentStorage.getSessionValue('userData');
    if (userData && userData.idToken) {
      checkLoginStatus(
        userData.idToken,
        (response) => loginSuccessCallback(response, dispatch, userData),
        loginErrorCallback(dispatch)
      );
    }

    else dispatch(setAppSliceState(['currentPage', 'login']));
  }, []);


  return (
    <Page currentPage={ currentPage }/>
  );
};

function Page({ currentPage }) {
  switch (currentPage) {
    case 'login':
      return <Login />;

    case 'director':
      return <Director />;

    case 'account':
      return <Account />;

    default:
      return null;
  }
}

function loginSuccessCallback(response, dispatch, userData) {
  if (response.data.users[0].role == "Admin") {
    dispatch(setAppSliceState([
      ['currentPage', 'account'],
      ['userData', userData]
    ]));
  }
  else if(response.data.users[0].role == "Director"){
    dispatch(setAppSliceState([
      ['currentPage', 'director'],
      ['userData', userData]
    ]));
  }
}

function loginErrorCallback(dispatch) {
  return () => {
    persistentStorage.deleteSessionItem('userData');
    dispatch(logout())
    dispatch(setAppSliceState(['currentPage', 'login']));
  };
}

Page.propTypes = {
  currentPage: PropTypes.string
};

Page.defaultProps = {
  currentPage: null
};

export default App;
