import React from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import routes from "./routes";
import withTracker from "./withTracker";
import Background from "../../components/account/assets/background/2.jpg";
import "bootstrap/dist/css/bootstrap.min.css";


export default class Account extends React.Component{
  constructor(props) {
    super(props);
  }

  componentDidCatch(){
    return <h1> Error 404</h1>
  }


  render(){
    const browserhistory = createBrowserHistory();

    return(
      <Router basename={process.env.REACT_APP_BASENAME || ""} history={browserhistory}>
      {/* <Button onClick={ userLogOut }>Log out</Button> */}
      <div style={{fontFamily:"Times New Roman", backgroundImage: `url(${Background})`, minHeight:'100vh', height:'100%', backgroundPosition: 'center',backgroundSize:'auto',backgroundRepeat: 'repeat'}}>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}
      </div>
    </Router>
    );
  }
}