import React from "react";
//import { Container, Row, Col } from "shards-react";
import { Row, Col,Container,  Card, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody, Button, CardSubtitle, Alert } from "shards-react";
import {url} from '../../../utilities/requests'
import PageTitle from "../../account/default-dashboard-layout/main-title-dashboard";
// import UsersByDevice from "./../components/blog/UsersByDevice";
import Loader from 'react-loader-spinner';
import Table from 'react-bootstrap/Table'
import { usePromiseTracker } from "react-promise-tracker";
import Modal from "react-modal";
import { trackPromise } from 'react-promise-tracker';
import { BiText } from 'react-icons/bi';
import {GrSubscript , GrCheckbox} from 'react-icons/gr';
import Graphs from "./graphsMeternew";
import Draggable from 'react-draggable';
import { propTypes } from "react-bootstrap/esm/Image";
import { IoMdClose } from 'react-icons/io';
import {RiDragDropLine} from "react-icons/ri";
import { ConsoleView } from "react-device-detect";
import { DialogActions } from "@material-ui/core";
import { ThreeSixtyTwoTone } from "@material-ui/icons";
import Modal2 from 'react-modal'
import { RiTShirtAirFill } from "react-icons/ri";
import zIndex from "@material-ui/core/styles/zIndex";
import { ThemeProvider } from "@emotion/react";

import Select from 'react-select';
import ReactSelect from './reportingpage-multiselect';
import makeAnimated from 'react-select/animated';


export default class GraphsInternal extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {uid: props.uid, sensor:props.sensor, 
            monthData:[{"name":1, "Last": 0,"Current": 0},{"name":2, "Last": 0,"Current": 0},{"name":3, "Last": 0,"Current": 0},{"name":4, "Last": 0,"Current": 0},{"name":5, "Last": 0,"Current": 0},{"name":6, "Last": 0,"Current": 0},{"name":7, "Last": 0,"Current": 0},{"name":8, "Last": 0,"Current": 0},{"name":9, "Last": 0,"Current": 0},{"name":10, "Last": 0,"Current": 0},{"name":11, "Last": 0,"Current": 0},{"name":12, "Last": 0,"Current": 0},{"name":13, "Last": 0,"Current": 0},{"name":14, "Last": 0,"Current": 0},{"name":15, "Last": 0,"Current": 0},{"name":16, "Last": 0,"Current": 0},{"name":17, "Last": 0,"Current": 0},{"name":18, "Last": 0,"Current": 0},{"name":19, "Last": 0,"Current": 0},{"name":20, "Last": 0,"Current": 0},{"name":21, "Last": 0,"Current": 0},{"name":22, "Last": 0,"Current": 0},{"name":23, "Last": 0,"Current": 0},{"name":24, "Last": 0,"Current": 0},{"name":25, "Last": 0,"Current": 0},{"name":26, "Last": 0,"Current": 0},{"name":27, "Last": 0,"Current": 0},{"name":28, "Last": 0,"Current": 0},{"name":29, "Last": 0,"Current": 0},{"name":30, "Last": 0,"Current": 0},{"name":31, "Last": 0,"Current": 0}], 
            yearData:[{"name":1, "Last": 0, "Current": 0},{"name":2, "Last": 0, "Current": 0},{"name":3, "Last": 0, "Current": 0},{"name":4, "Last": 0, "Current": 0},{"name":5, "Last": 0, "Current": 0},{"name":6, "Last": 0, "Current": 0},{"name":7, "Last": 0, "Current": 0},{"name":8, "Last": 0, "Current": 0},{"name":9, "Last": 0, "Current": 0},{"name":10, "Last": 0, "Current": 0},{"name":11, "Last": 0, "Current": 0},{"name":12, "Last": 0, "Current": 0}],
            weekData:[{"name":1, "Last":0, "Current":0},{"name":2, "Last":0, "Current":0},{"name":3, "Last":0, "Current":0},{"name":4, "Last":0, "Current":0},{"name":5, "Last":0, "Current":0},{"name":6, "Last":0, "Current":0},{"name":7, "Last":0, "Current":0}], 
        }
        this.graph_data_set = this.graph_data_set.bind(this);
    }

    graph_data_set(data){
        // console.log(data)
        this.setState({monthData: data.resultMonth, weekData: data.resultWeek, yearData: data.resultYear})
    }

    componentDidMount(){
        // console.log(this.state.uid)
        try{
            trackPromise(
                fetch(url+'meters_graphs', {headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 },
                method: "POST",
                body: JSON.stringify({
                    "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"],
                    "uid": this.state.uid,
                    "sensor": this.state.sensor
                })       
                }).then(response => response.json()).then(data =>  {this.graph_data_set(data)} )
                );
        } catch (e){
            console.log(e)
        }
    }

    render(){
        return(
            // <h1>Hello</h1>
            <Graphs graphState={"line"} title="" visibleDrop2={"hidden"} monthData={this.state.monthData} yearData={this.state.yearData} weekData={this.state.weekData} />
        )
    }
}