import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";
import {Button } from 'shards-react'
import NavbarNav from "./header-right-layout";
import NavbarToggle from "../../Left-Slider-Menu-Components/navbar-toggle-component";
import {useHistory} from 'react-router-dom'
import { IoCaretBack} from "react-icons/io5";
import SmallSidebar from "../../Left-Slider-Menu-Components/Small";
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import {RiLogoutBoxRFill} from 'react-icons/ri';


const MainNavbar = ({ layout, stickyTop }) => {
    const classes = classNames(
      "main-navbar",
      "bg-white",
      stickyTop && "sticky-top"
    );
    let history = useHistory();
    const goToPreviousPath = () => {
          history.goBack()
      }
    
    if(isMobile){
      return (
        <div className="main-navbar fixed-top bg-light d-sm-flex align-items-center">
          <Navbar type="light" className="d-flex flex-nowrap" style={{marginBottom: "0px"}}>
          <SmallSidebar/>
          {/* <div className="d-flex flex-column">
            <div className="p-0"><IoMdArrowRoundBack onClick={goToPreviousPath} color={'Teal'} size={25}/></div>
          <div className="p-0" style={{fontSize:12, color:"Teal"}}>Back</div>
          </div> */}
            {/* <NavbarTitle /> */}
            <NavbarNav />
            {/* <NavbarToggle /> */}
          </Navbar>
        {/* </Container> */}
      </div>
      )
    }
    
    return (
      <div className={classes}>
        {/* <Container className="p-0"> */}
          <Navbar type="light" className="d-flex flex-nowrap" style={{marginBottom: "0px",marginLeft:'5%'}}>
          <div className="d-flex flex-column">
          <div ><IoCaretBack onClick={goToPreviousPath} color={'Teal'} size={25}/></div>
          <div className="p-0" style={{fontSize:12, color:"Teal"}}>Back</div>
          </div > 
          <div ><h4 className="text-center pl-4">Third Eye Software</h4></div>
            {/* <NavbarTitle /> */}
            <NavbarNav />
            {/* <NavbarToggle /> */}
 
           
          </Navbar>
        {/* </Container> */}
        
      </div>
    );
  };
  
  MainNavbar.propTypes = {
    /**
     * The layout type where the MainNavbar is used.
     */
    layout: PropTypes.string,
    /**
     * Whether the main navbar is sticky to the top, or not.
     */
    stickyTop: PropTypes.bool
  };
  
  MainNavbar.defaultProps = {
    stickyTop: true
  };
  
  export default MainNavbar;