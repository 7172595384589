import React from "react";
import { usePromiseTracker } from "react-promise-tracker";

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          zIndex: "4",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {/* Your loading indicator JSX */}
      </div>
    )
  );
};

export default LoadingIndicator;
