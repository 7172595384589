
export const Rweek=[
          { name: 1, Last: 0, Current: 0 },
          { name: 2, Last: 0, Current: 0 },
          { name: 3, Last: 0, Current: 0 },
          { name: 4, Last: 0, Current: 0 },
          { name: 5, Last: 0, Current: 0 },
          { name: 6, Last: 0, Current: 0 },
          { name: 7, Last: 0, Current: 0 },
        ]
export const Ryear=[
          { name: 1, Last: 0, Current: 0 },
          { name: 2, Last: 0, Current: 0 },
          { name: 3, Last: 0, Current: 0 },
          { name: 4, Last: 0, Current: 0 },
          { name: 5, Last: 0, Current: 0 },
          { name: 6, Last: 0, Current: 0 },
          { name: 7, Last: 0, Current: 0 },
          { name: 8, Last: 0, Current: 0 },
          { name: 9, Last: 0, Current: 0 },
          { name: 10, Last: 0, Current: 0 },
          { name: 11, Last: 0, Current: 0 },
          { name: 12, Last: 0, Current: 0 },
        ]
export const Rmonth=[
          { name: 1, Last: 0, Current: 0 },
          { name: 2, Last: 0, Current: 0 },
          { name: 3, Last: 0, Current: 0 },
          { name: 4, Last: 0, Current: 0 },
          { name: 5, Last: 0, Current: 0 },
          { name: 6, Last: 0, Current: 0 },
          { name: 7, Last: 0, Current: 0 },
          { name: 8, Last: 0, Current: 0 },
          { name: 9, Last: 0, Current: 0 },
          { name: 10, Last: 0, Current: 0 },
          { name: 11, Last: 0, Current: 0 },
          { name: 12, Last: 0, Current: 0 },
          { name: 13, Last: 0, Current: 0 },
          { name: 14, Last: 0, Current: 0 },
          { name: 15, Last: 0, Current: 0 },
          { name: 16, Last: 0, Current: 0 },
          { name: 17, Last: 0, Current: 0 },
          { name: 18, Last: 0, Current: 0 },
          { name: 19, Last: 0, Current: 0 },
          { name: 20, Last: 0, Current: 0 },
          { name: 21, Last: 0, Current: 0 },
          { name: 22, Last: 0, Current: 0 },
          { name: 23, Last: 0, Current: 0 },
          { name: 24, Last: 0, Current: 0 },
          { name: 25, Last: 0, Current: 0 },
          { name: 26, Last: 0, Current: 0 },
          { name: 27, Last: 0, Current: 0 },
          { name: 28, Last: 0, Current: 0 },
          { name: 29, Last: 0, Current: 0 },
          { name: 30, Last: 0, Current: 0 },
          { name: 31, Last: 0, Current: 0 },
        ]
export const Result=[
        {
          uid: 1,
          name: "power1.Schneider_EM6436_dual",
          c1: 0,
          c2: 0,
          c3: 0,
          v1: 0,
          v2: 0,
          v3: 0,
          kwh: 0,
          hz: 0,
          pf: 0,
          display_name: "NK Proteins Meter",
          va: 0,
          watt: 0,
          vln1: 0,
          vln2: 0,
          vln3: 0,
          color: "",
          cavg: 0,
          vavg: 0,
          vlnavg: 0,
          startDate: Date(),
          endDate: Date(),
        },
      ]
export const functionsToBind = [
  "handleChange",
  "changeLoader",
  "closeModal",
  "cardName",
  "cardDisplayData",
  "cardSubtitle",
  "cardType",
  "size",
  "DisplayData",
  "handleAdd",
  "getData",
  "graph_data_set",
  "deleteCard",
  "checkSize",
  "renderSelectMeter",
  "renderSizeOptions",
  "renderValueOptions",
  "singleDisplayData",
  "handleMeterChange",
  "loadData",
  "changeColor",
  "handleSingleMeterChange",
  "setStartDate",
  "setEndDate",
  "renderTimeSelection"
];