import React, { PureComponent } from "react";
//import { Container, Row, Col } from "shards-react";
import {
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Button,
  CardSubtitle,
  Alert,
} from "shards-react";
import { url } from "../../../utilities/requests";
import { trackPromise } from "react-promise-tracker";
import PageTitle from "../../account/default-dashboard-layout/main-title-dashboard";
import ReactSelect from "../../account/dashboard-tools-component/common-multiselect";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";
import "react-datetime-picker/dist/DateTimePicker.css";
import axios from "axios";
import { render } from "react-dom";
import Table from "react-bootstrap/Table";
import { FaCircle } from "react-icons/fa";
import Diagnostic from "./Diagnostc/Diagnostic";
import Diagm from "./Diagm/Diagm";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Date: Date().toLocaleString(),
      result: [
        {
          uid: 1,
          name: "power1.Schneider_EM6436_dual",
          c1: 0,
          c2: 0,
          c3: 0,
          v1: 0,
          v2: 0,
          v3: 0,
          kwh: 0,
          hz: 0,
          pf: 0,
          display_name: "NK Proteins Meter",
          va: 0,
          watt: 0,
          vln1: 0,
          vln2: 0,
          vln3: 0,
          color: "",
          cavg: 0,
          vavg: 0,
          vlnavg: 0,
          startDate: Date(),
          endDate: Date(),
        },
      ],
      searchMeters: "",
      selectMeterOptions: [],
      headers: [
        { label: "date", key: "date" },
        { label: "Time", key: "time" },
        { label: "Uid", key: "uid2" },
        { label: "location", key: "display_name" },
        { label: "kwh", key: "kwh" },
        { label: "Watt", key: "watt" },
        { label: "Kva", key: "va" },
        { label: "A1", key: "c1" },
        { label: "A2", key: "c2" },
        { label: "A3", key: "c3" },
        { label: "V1", key: "v1" },
        { label: "V2", key: "v2" },
        { label: "V3", key: "v3" },
        { label: "V L-N 1", key: "vln1" },
        { label: "V L-N 2", key: "vln2" },
        { label: "V L-N 3", key: "vln3" },
        { label: "Pf", key: "pf" },
        { label: "Hz", key: "hz" },
        { label: "C Avg", key: "cavg" },
        { label: "V Avg", key: "vavg" },
        { label: "V L-N Avg", key: "vlnavg" },
      ],
    };
    this.loadData = this.loadData.bind(this);
    this.handleMeterChange = this.handleMeterChange.bind(this);
  }
  componentDidMount() {
    this.loadData();
    console.log("called again");
    setInterval(this.loadData, 300000);
  }
  loadData() {
    // console.log("in loaddata")
    try {
      trackPromise(
        fetch(url+"meters_table", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            idToken: JSON.parse(sessionStorage["default-session-data"])[
              "userData"
            ]["idToken"],
          }),
        })
          .then((response) => response.json())
          .then((data) => {if(data['error']){
            console.log("Error")
          }
          else if (data["result"].length == 0) {
              {
                console.log("sleeping");
                this.sleep(30000);
                // , this.forceUpdate()
              }
            } else {
              this.setState({
                result: data["result"],
                display: data["dash_data"],
                selectMeterOptions: data["valMeter"],
                showLoader: false,
              });
              //console.log(data['valMeter'])
              this.sleep(30000);
            }

        })
      );
    } catch (e) {
      console.log(e);
    }

    // console.log(new Date().getTime())
  }

  handleMeterChange(event) {
    this.setState({ searchMeters: event.target.value });
  }

  render() {
    const FilterMeters = this.state.result
      .filter((res) =>
        res.display_name
          .toLowerCase()
          .includes(this.state.searchMeters.toLowerCase())
      )
      .sort(function (a, b) {
        return a.uid2 - b.uid2;
      });
    return (
      <div style={{ marginRight: "10%" }}>
        <Container>
          <Row>
            <PageTitle
              md={1}
              title="Meters"
              subtitle="Live Data"
              className="text-center"
            />
          </Row>
          <Row style={{ marginBottom: "3%" }}>
            <Col>
              <h5>Search Meter</h5>
              <input type="search" onChange={this.handleMeterChange} />{" "}
            </Col>
            <Col md={2} stlye={{ float: "right" }}>
              <Row>
                <h5>Download Live Data</h5>
              </Row>
              <Row>
                <Col>
                  <Button>
                    <CSVLink
                      data={FilterMeters}
                      headers={this.state.headers}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Download
                    </CSVLink>{" "}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ width: "112%", overflow: "auto" }}>
                <div className="d-flex jusitfy-content-between">
                  <span className="px-2">
                    <FaCircle style={{ color: "#F01C1C" }} /> Offline
                  </span>{" "}
                  <span className="px-2">
                    <FaCircle style={{ color: "#3cb371" }} /> Online
                  </span>{" "}
                  <span className="px-2">
                    <FaCircle style={{ color: "black" }} /> Not Connected
                  </span>
                </div>
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      {/* <th>S.No.</th> */}
                      <th>UID</th>
                      <th></th>
                      <th>LOCATION</th>
                      {<th>KWh [counter]</th>}
                      {<th>KW</th>}
                      {<th>KVA</th>}
                      {<th>A 1</th>}
                      {<th>A 2</th>}
                      {<th>A 3</th>}
                      {<th>V R-Y</th>}
                      {<th>V Y-B</th>}
                      {<th>V B-R</th>}
                      {<th>V R-N</th>}
                      {<th>V Y-N</th>}
                      {<th>V B-N</th>}
                      {<th>PF</th>}
                      {<th>Freq</th>}
                      {<th>KVAR</th>}
                      {<th>I [Avg]</th>}
                      {<th>V-LL[Avg]</th>}
                      {<th>V-LN[Avg]</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(Date.now())} */}
                    {FilterMeters.map((Fil, index) => {
                      return (
                        <tr key={`${Fil.uid2}-${index}`}>
                          {/* <tr ><div>&nbsp;&nbsp;{index+1}</div></tr> */}
                          <td>{Fil.uid2}</td>
                          <td>
                            <FaCircle style={{ color: Fil.color }} />
                          </td>
                          {/* <td><FaCircle className="mr-1" style={{ color:  props.state.find(x=>x.uid2===i.value).color}}/></td> */}
                          <td>{Fil.display_name}</td>
                          {<td>{Fil.kwh.toFixed(2)}</td>}
                          {<td>{Fil.watt.toFixed(2)}</td>}
                          {<td>{(Fil.va / 1000).toFixed(2)}</td>}
                          {<td>{Fil.c1.toFixed(2)}</td>}
                          {<td>{Fil.c2.toFixed(2)}</td>}
                          {<td>{Fil.c3.toFixed(2)}</td>}
                          {<td>{Fil.v1.toFixed(2)}</td>}
                          {<td>{Fil.v2.toFixed(2)}</td>}
                          {<td>{Fil.v3.toFixed(2)}</td>}
                          {<td>{Fil.vln1.toFixed(2)}</td>}
                          {<td>{Fil.vln2.toFixed(2)}</td>}
                          {<td>{Fil.vln3.toFixed(2)}</td>}
                          {<td>{Fil.pf.toFixed(2)}</td>}
                          {<td>{Fil.hz.toFixed(2)}</td>}
                          {<td>{(Fil.var / 1000).toFixed(2)}</td>}
                          {<td>{Fil.cavg.toFixed(2)}</td>}
                          {<td>{Fil.vavg.toFixed(2)}</td>}
                          {<td>{Fil.vlnavg.toFixed(2)}</td>}
                        </tr>
                      );
                    })}
                    {/* {console.log(Date.now())} */}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          <Row>
           <Col style={{ marginTop: "5%" }}>
              <Diagnostic />
            </Col> 
          </Row>
          <Row>
            <Col className="text-center mt-3" stlye={{ marginTop: "5%" }}>
              <h2>Add More Cards</h2>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginBottom: "8%" }}>
              <Diagm />
            </Col> 
          </Row>
        </Container>
      </div>
    );
  }
}
export default Dynamic;
