import * as serviceWorker from './serviceWorker';

import App from './components/App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './state/store';

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(
  <React.Fragment>
    <Provider store={ store }>
      <App />
    </Provider>
  </React.Fragment>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();