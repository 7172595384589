import React from "react";
import { Redirect } from "react-router-dom";
// Layout Types
import  DefaultLayout from "./default-dashboard-layout/Default";
import  Unknown  from "./default-dashboard-layout/unknown";
import UserProfileLite from "./UsersProfile/UserProfileLite";
// import UserAccountDetails from "./components/user-profile-lite/UserAccountDetails";
// import UserDetails from "./components/user-profile-lite/UserDetails";
import PasswordReset from "./addUser/resetPassword";
import Dynamic from "./views/Dynamic";
import Alarms from './views/Alarms'
import AddUser from './addUser/addUser'
import History from "./views/History";
import Diagnostic from "./views/Diagnostic";
import Temprature from "./views/Temprature";
export default [
  // {
  //   path: "/",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: () => <Redirect to="/Metering" />
  // },
  {
    path:"/",
    exact:true,
    layout: DefaultLayout,
    component: () => <Redirect to="/Dynamic" />
  },
  {
    path:"/Dynamic",
    exact:true,
    layout: DefaultLayout,
    component: Dynamic
  },
  // {
  //   path: "/Live",
  //   exact: true, 
  //   layout: DefaultLayout,
  //   component: LiveChart
  // },
  // {
  //   path: "/plot",
  //   exact: true, 
  //   layout: DefaultLayout,
  //   component: PlotTest
  // },
  {
    path: "/History",
    exact: true,
    layout: DefaultLayout,
    component: History
  },
  {
    path: "/Diagnostic",
    exact: true,
    layout: DefaultLayout,
    component: Diagnostic
  },
  {
    path: "/user-profile",
    exact: true,
    layout: DefaultLayout,
    component: UserProfileLite
  },

  {
    path: "/Alarms",
    exact: true,
    layout: DefaultLayout,
    component: Alarms
  },
  {
    path: "/add-user",
    layout: DefaultLayout,
    component: AddUser
  },
  {
    path: "/Temprature",
    layout: DefaultLayout,
    component: Temprature
  },
  // {
  //   path:"/MasterBlower",
  //   layout: DefaultLayout,
  //   component: BlowerMaster
  // },
  // {
  //   path:"/UserAcc",
  //   layout:DefaultLayout,
  //   component: 
  // },
  // {
  //   path:"/Series",
  //   layout: DefaultLayout,
  //   component: Series
  // },

  {
    path: "/reset-password",
    exact: true,
    layout: DefaultLayout,
    component: PasswordReset
  },
  // {
  //   path: "/Report",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: Report
  // },
  // {
  //   path:"/MasterBlower",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: Blowers
  // },
  // {
  //   path:'/Blowers',
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: BlowerMaster
  // },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // },
  // {
  //   path: "/Metering",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: MeteringOverview
  // },
  // {
  //   path: '/Meters',
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: Meters
  // },
  // {
  //   path: "/ElectricalStats",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: ElectricalStats
  // },
  // {
  //   path: "/FloorMetering",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: PanelMetering
  // },
  // {
  //   path:"/DetailsMeters",
  //   layout: DefaultLayout,
  //   component: DetailsMeters
  // },
  // {
  //   path: "/PanelsInters",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: PanelsInters
  // },
  // {
  //   path:"/Panels",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: FloorMetering
  // },
  // {
  //   path:"/CommonMetering",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: CommonMetering
  // },
  // {
  //   path:"/HVAC",
  //   layout: DefaultLayout,
  //   component: HVAC
  // },
  // {
  //   path:"/Commoncontrol",
  //   layout: DefaultLayout,
  //   component:Commoncontrol
  // },
  // {
  //   path:"/Roomcontrol",
  //   layout: DefaultLayout,
  //   component:Roomcontrol
  // },
  // {
  //   path:"/Roomoverview",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component:Roomoverview
  // },
  // {
  //   path:"/Commonoverview",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: Commonoverview
  // },

  // {
  //   path: "/Maps",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/Details",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: Details
  // },

  //   path: "*",
  //   layout: Unknown,
  //   component: Errors
  // }
  // {
  //   path: "/Reporting",
  //   layout: DefaultLayout,
  //   component: Reporting
  // }
];
