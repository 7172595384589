import React from "react";
import { Nav ,Container,Col,Row} from "shards-react";

import Notifications from "./notification-component";
import UserActions from "./user-profile-component";
import Logout from "./user-logout-component";
export default () => (
  <Nav navbar>
    <Container >
      <Row>
        <Col>
        <Row className='row d-flex justify-content-between mb-0 pb-0'>
          <Col><Notifications/></Col>
          <Col><UserActions /></Col>
          <Col><Logout /></Col>
        {/* <Col><RiLogoutBoxRFill className="pt-2 mb-2" style={{color:'#800000'}} size={30} onClick={userLogOut}/><div>Exit</div></Col> */}
        </Row>
        {/* <Row className='mt-0 pt-0'><Col size='sm'>Notification</Col><Col >Profile</Col><Col className='mx-2' >Exit</Col></Row> */}
        </Col>
      </Row>

    </Container>
  </Nav>
);
