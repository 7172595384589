import {url} from '../../../utilities/requests'
export const setEDate=(date)=>{
    // console.log(date);
    let sDate = date;
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    sDate = (new Date(sDate - tzoffset)).toISOString().slice(0, -1).split('T')[0];
    return sDate
}
export const setSDate=(date)=>{
    let sDate = date;
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    sDate = (new Date(sDate - tzoffset)).toISOString().slice(0, -1).split('T')[0];
    return sDate    
}
export const handleGC=async (data)=>{
      let keys = await Object.keys(data["data"][0]);
      return keys;
}


export const graphComp = async (
  meters,
  sensor,
  time,
  interval,
  startDate,
  endDate,
  startHour,
  endHour,
  startMinute,
  endMinute,
  timezone
) => {
  const response = await fetch(url + "comparisonGraph", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      idToken: JSON.parse(sessionStorage["default-session-data"])["userData"][
        "idToken"
      ],
      meters: meters,
      sensor: sensor,
      time: time,
      interval: interval,
      startDate: startDate,
      endDate: endDate,
      timezone: timezone,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  }
};


export const switchInt=(interval)=>{
     switch (interval) {
       case "1 Hour":
         return 60;
       case "30 Min":
         return 30;
       case "10 Min":
         return 10;
       case "5 Min":
         return 5;
       case "1 Min":
         return 1;
       default:
         return 1;
     }
}