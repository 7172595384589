import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from "shards-react";
import { CardBody } from "reactstrap";
import ProfilePic from "../../account/assets/avatars/1.png"

const UserDetails = ({ userDetails }) => (
    <Card small className="mb-4 pt-3">
      <CardBody className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          <img
            // className="rounded-circle"
            style={{borderRadius: "50%"}}
            // src={userDetails.avatar}
            src={ProfilePic}
            alt={userDetails.name}
            width="180"
            height="180"
          />
        </div>
        <h4 className="mb-0">{JSON.parse(sessionStorage["default-session-data"])["userData"] ? JSON.parse(sessionStorage["default-session-data"])["userData"]["displayName"] : "Not Available"}</h4>
        <span className="text-muted d-block mb-2">{JSON.parse(sessionStorage["default-session-data"]) ? JSON.parse(sessionStorage["default-session-data"])["userData"]["role"] : "Not Available"}</span>
        {/* <Button pill outline size="sm" className="mb-2">
          <i className="material-icons mr-1">person_add</i> Follow
        </Button> */}
      </CardBody>
      {/* <ListGroup flush> */}
        {/* <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">
              {userDetails.performanceReportTitle}
            </strong>
            <Progress
              className="progress-sm"
              value={userDetails.performanceReportValue}
            >
              <span className="progress-value">
                {userDetails.performanceReportValue}%
              </span>
            </Progress>
          </div>
        </ListGroupItem> */}
        {/* <ListGroupItem className="p-4">
          <strong className="text-muted d-block mb-2">
            {userDetails.metaTitle}
          </strong>
          <span>{userDetails.metaValue}</span>
        </ListGroupItem> */}
      {/* </ListGroup> */}
    </Card>
  );
  
  UserDetails.propTypes = {
    /**
     * The user details object.
     */
    userDetails: PropTypes.object
  };
  
  UserDetails.defaultProps = {
    userDetails: {
      name: "Nimer Singh",
      avatar: require("../../account/assets/avatars/0.jpg"),
      jobTitle: "Not Available",
      performanceReportTitle: "Workload",
      performanceReportValue: 74,
      metaTitle: "Description",
      metaValue:""
    }
  };
  
  export default UserDetails;
  