import { Form, Button, FormGroup, FormControl, Card, ControlLabel, InputGroup, Navbar, NavDropdown } from "react-bootstrap";
import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { selectApp, setAppSliceState } from './state/appSlice';
import { selectLogin, setLoginSliceState } from './state/signUpSlice';
import { useDispatch, useSelector } from 'react-redux';

import Icon from './assets/icons/Icon';
import pageConfig from './Utilities/pageConfig';
import persistentStorage from './Utilities/persistentStorage';
import styles from './assets/styles/Login.module.scss';
import submitForm from '../addUser/Utilities/submitForm';
import { sendPushNotification } from './Utilities/requests';

import Grid from '@material-ui/core/Grid';
import {Oval} from 'react-loader-spinner';
import Modal from 'react-modal';

const PasswordReset = () => {

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [role, setRole] = useState("Admin");

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const {
    email,
    isRememberMe,
    // pageType,
    password,
    username
  } = useSelector(selectLogin);
  const { currentPage } = useSelector(selectApp);

  const pageType = "passwordReset"

  const submitInput = useRef(null);
  const loginSignUpButton = useRef(null);

  const [showNotice, setShowNotice] = useState(false);
  const [notice, setNotice] = useState({ header: '', message: '' });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {

    if (notice.header) {
      setShowNotice(true);
      setShowLoader(false);
    }

    else setShowNotice(false);
  }, [notice]);

  
  const updateEmail = (event) => {
    const { target: { value } } = event;
    dispatch(setLoginSliceState(['email', value]));
  };


  const { currentPageText, newPageText } = pageConfig(pageType);

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoader(true);

    submitForm(
      pageType,
      email,
      password,
      username,
      role,
      setNotice
    );

  };


  return (

    <div>
    <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  style={{ minHeight: '80vh'}}
 >

  <Grid item xs={4}>
      <Card className="bg-light" style={{ minWidth: '50vh' }}>
      <Oval color="#00BFFF" height={80} width={80} visible={ showLoader || false } style={{zIndex:"4" , position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}/>
        <Card.Body >
        <form onSubmit={ handleSubmit }>
        <Container >
          <Icon pageType={ pageType }  className="d-flex justify-content-center w-50"/>
          <h4  >{currentPageText}</h4>
          <div >
            {
              pageType === 'signUp' || pageType === 'passwordReset' ? (
                <div>
                    <label>Email ID</label>
                <InputGroup className="mb-3">
                  
                  <FormControl
                    onChange= {updateEmail}
                    placeholder="Email ID"
                    required
                    type="email"
                    value={email || ''}
                    
                  />
                </InputGroup>
                </div>
              ) : null
            }
          </div>

          
            <div className="d-flex justify-content-center" >
          <Button
            className="btn-primary "
            onClick={ () => submitInput.current.click() }
            variant="large"
          >
            <input
              className={ styles['submit-input'] }
              ref={ submitInput }
              type="submit"
            />
            { currentPageText }
          </Button>
            </div>
        </Container>
      </form>

      <div>
      </div>
      </Card.Body>
      </Card>
      </Grid>
      </Grid>

      <Modal isOpen={showNotice || false} className="Modal" style={{minHeight:"50%", width:"50%", alignItems:"center", overlay: {zIndex:1000} }} ariaHideApp={false}>
      <p style={{marginTop: "18%", textAlign:"center"}}>{notice.message}</p>
      <Button onClick={() => setShowNotice(false) } style={{marginLeft: "44%"}}>Okay</Button>
      </Modal>
      </div>

  );
};

export default PasswordReset;

