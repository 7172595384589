import GraphsInternal from "../../dashboard-tools-component/Graph";
import { CardBody } from 'shards-react'
import Table from "react-bootstrap/Table";
import { FaCircle } from "react-icons/fa";
export const eventStop=(dataDisplay,offsetX,offsetY,tempDataDisplay,event,uid)=>{
      for (let i = 0; i < dataDisplay.length; i++) {
        if (dataDisplay[i].uid == uid) {
          tempDataDisplay = dataDisplay[i];
        }
      }
      tempDataDisplay["x"] = event.layerX - offsetX;
      tempDataDisplay["y"] = event.layerY - offsetY;
      var updatedData = [];
      for (let i = 0; i < dataDisplay.length; i++) {
        if (dataDisplay[i].uid != uid) {
          updatedData = [...updatedData, dataDisplay[i]];
          return updatedData
        }
        if (dataDisplay[i].uid == uid) {
          updatedData = [...updatedData, tempDataDisplay];
          return updatedData
        }
      }
}

export function singleDisplayDat(event, v1, v2, v3, i1, i2, i3, kwh, pf, hz, va, watt, vln1, vln2, vln3, cavg, vavg, vlnavg){
    var tar = event.target.value;
    switch (tar) {
      case "v1":
        return ({
          v1: !v1,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v3: false,v2: false,i1: false,i2: false,i3: false,kwh: false,pf: false,
          hz: false,va: false,watt: false});
      case "v2":
        return ({
          v2: !v2,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v3: false,i1: false,i2: false,i3: false,kwh: false,pf: false,
          hz: false,va: false,watt: false});
      case "v3":
        return ({
          v3: !v3,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,i1: false,i2: false,i3: false,kwh: false,pf: false,
          hz: false,va: false,watt: false});
      case "i1":
        return ({
          i1: !i1,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i2: false,i3: false,kwh: false,pf: false,
          hz: false,va: false,watt: false});
      case "i2":
        return ({
          i2: !i2,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i1: false,i3: false,kwh: false,pf: false,
          hz: false,va: false,watt: false});
      case "i3":
        return ({
          i3: !i3,
          var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i1: false,i2: false,kwh: false,
          pf: false,hz: false,va: false,watt: false});
      case "kwh":
        return ({
          kwh: !kwh,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,
          vln2: false,vln3: false,v1: false,v2: false,v3: false,i1: false,i2: false,
          i3: false,pf: false,hz: false,va: false,watt: false});
      case "pf":
        return ({
          pf: !pf,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          hz: false,va: false,watt: false});
      case "hz":
        return ({
          hz: !hz,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,va: false,watt: false});
      case "va":
        return({
          va: !va,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,hz: false,watt: false});
      case "watt":
        return ({
          watt: !watt,va: false,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,hz: false,va:false});
      case "vln1":
        return ({
          vln1: !vln1,watt: false,va: false,var: false,vlnavg: false,vavg: false,cavg: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,hz: false,watt: false});
      case "vln2":
        return ({
          vln2: !vln2 ,watt: false,va: false,var: false,vlnavg: false,vavg: false,cavg: false,vln1: false,vln2: false,
          vln3: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,hz: false,watt: false});
      case "vln3":
        return ({
          vln3: !vln3,vln2: false ,watt: false,va: false,var: false,vlnavg: false,vavg: false,cavg: false,
          vln1: false,vln2: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,hz: false,watt: false});
      case "cavg":
        return ({
          cavg: !cavg,vln3: false,vln2: false ,watt: false,va: false,var: false,vlnavg: false,vavg: false,
          vln1: false,vln2: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,hz: false,watt: false});
      case "vavg":
        return ({
          vlnavg: !vlnavg,vln3: false,vln2: false ,watt: false,va: false,var: false,vavg: false,cavg: false,
          vln1: false,vln2: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,hz: false,watt: false});
      case "vlnavg":
        return this.setStatev
      case "var":
        return ({
          var: !this.state.var,vln3: false,vln2: false ,watt: false,va: false,vavg:false,vlnavg: false,cavg: false,
          vln1: false,vln2: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
          pf: false,hz: false,watt: false});
    }
}

export function crdDisplayData(props,meter,type,v1,v2,v3,i1,i2,i3,kwh,pf,hz,va,watt,vln1,vln2,vln3,cavg,vavg,vlnavg,result){
  if (type == "Data") {
    result.filter((obj) => {
      return String(obj.uid) === meter.value;
    });

    if (v1 == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].v1.toFixed(4)} <b>V</b></h5></CardBody>
      );
    } else if (v2 == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].v2.toFixed(4)} <b>V</b></h5></CardBody>
      );
    } else if (v3 == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].v3.toFixed(4)} <b>V</b></h5></CardBody>
      );
    } else if (i1 == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].c1.toFixed(4)} <b>A</b></h5></CardBody>
      );
    } else if (i2 == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].c2.toFixed(4)} <b>A</b></h5></CardBody>
      );
    } else if (i3 == true) {
      return (
        <CardBody> <h5 className="p-0 m-0">{result[0].c3.toFixed(4)} <b>A</b></h5></CardBody>
      );
    } else if (kwh == true) {
      return (
        <CardBody> <h5 className="p-0 m-0">{result[0].kwh.toFixed(4)} <b>kWh</b></h5></CardBody>
      );
    } else if (pf == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].pf.toFixed(4)}</h5></CardBody>
      );
    } else if (hz == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].hz.toFixed(4)} <b>Hz</b></h5></CardBody>
      );
    } else if (va == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].va.toFixed(4)} <b>VA</b></h5></CardBody>
      );
    } else if (watt == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].watt.toFixed(4)} <b>W</b></h5></CardBody>
      );
    } else if (vln1 == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].vln1.toFixed(4)} <b>V</b></h5></CardBody>
      );
    } else if (vln2 == true) {
      return (
        <CardBody><h5 className="p-0 m-0">{result[0].vln2.toFixed(4)} <b>V</b></h5></CardBody>
      );
    } else if (vln3 == true) {
      return (
        <CardBody>
          <h5 className="p-0 m-0">
            {result[0].vln3.toFixed(4)} <b>V</b>
          </h5>
        </CardBody>
      );
    } else if (cavg == true) {
      return (
        <CardBody>
          <h5 className="p-0 m-0">
            {result[0].cavg.toFixed(4)} <b>A</b>
          </h5>
        </CardBody>
      );
    } else if (vavg == true) {
      return (
        <CardBody>
          <h5 className="p-0 m-0">
            {result[0].vavg.toFixed(4)} <b>V</b>
          </h5>
        </CardBody>
      );
    } else if (vlnavg == true) {
      return (
        <CardBody>
          <h5 className="p-0 m-0">
            {result[0].vlnavg.toFixed(4)} <b>V</b>
          </h5>
        </CardBody>
      );
    } else if (props.props.var == true) {
      return (
        <CardBody>
          <h5 className="p-0 m-0">
            {result[0].var.toFixed(4)} <b>KVAR</b>
          </h5>
        </CardBody>
      );
    } else {
      return <CardBody></CardBody>;
    }
  } else if (type == "Table") {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>UID</th>
            <th>SENSOR</th>
            <th>LOCATION</th>
            {kwh ? <th>KWh [counter]</th> : null}
            {watt ? <th>KW</th> : null}
            {va ? <th>KVA</th> : null}
            {i1 ? <th>Current Phase 1</th> : null}
            {i2 ? <th>Current Phase 2</th> : null}
            {i3 ? <th>Current Phase 3</th> : null}
            {v1 ? <th>Voltage P1-P2</th> : null}
            {v2 ? <th>Voltage P2-P3</th> : null}
            {v3 ? <th>Voltage P3-P1</th> : null}
            {vln1 ? <th>Voltage P1</th> : null}
            {vln2 ? <th>Voltage P2</th> : null}
            {vln3 ? <th>Voltage P3</th> : null}
            {pf ? <th>Power Factor</th> : null}
            {hz ? <th>Frequency</th> : null}
            {props.props.var ? <th>KVAR</th> : null}
            {cavg ? <th>Current (Av)</th> : null}
            {vavg ? <th>Voltage LL (Av)</th> : null}
            {vlnavg ? <th>Voltage LN (Av)</th> : null}
          </tr>
        </thead>
        <tbody>
          {meter.map((i) => {
            // console.log(i)
            return (
              <tr key={i.value}>
                <td>{props.state.find((x) => x.uid2 === i.value).uid2}</td>
                <td>
                  <FaCircle
                    className="mr-1"
                    style={{
                      color: props.state.find((x) => x.uid2 === i.value)
                        .color,
                    }}
                  />
                </td>
                <td>
                  {props.state.find((x) => x.uid2 === i.value).display_name}
                </td>
                {kwh ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .kwh.toFixed(2)}{" "}
                  </td>
                ) : null}
                {watt ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .watt.toFixed(2)}{" "}
                  </td>
                ) : null}
                {va ? (
                  <td>
                    {(
                      props.state.find((x) => x.uid2 === i.value).va / 1000
                    ).toFixed(2)}{" "}
                  </td>
                ) : null}
                {i1 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .c1.toFixed(2)}{" "}
                  </td>
                ) : null}
                {i2 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .c2.toFixed(2)}{" "}
                  </td>
                ) : null}
                {i3 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .c3.toFixed(2)}{" "}
                  </td>
                ) : null}
                {v1 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .v1.toFixed(2)}{" "}
                  </td>
                ) : null}
                {v2 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .v2.toFixed(2)}{" "}
                  </td>
                ) : null}
                {v3 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .v3.toFixed(2)}{" "}
                  </td>
                ) : null}
                {vln1 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .vln1.toFixed(2)}{" "}
                  </td>
                ) : null}
                {vln2 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .vln2.toFixed(2)}{" "}
                  </td>
                ) : null}
                {vln3 ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .vln3.toFixed(2)}{" "}
                  </td>
                ) : null}
                {pf ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .pf.toFixed(2)}
                  </td>
                ) : null}
                {hz ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .hz.toFixed(2)}{" "}
                  </td>
                ) : null}
                {props.props.var ? (
                  <td>
                    {(
                      props.state.find((x) => x.uid2 === i.value).var / 1000
                    ).toFixed(2)}
                  </td>
                ) : null}
                {cavg ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .cavg.toFixed(2)}{" "}
                  </td>
                ) : null}
                {vavg ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .vavg.toFixed(2)}{" "}
                  </td>
                ) : null}
                {vlnavg ? (
                  <td>
                    {props.state
                      .find((x) => x.uid2 === i.value)
                      .vlnavg.toFixed(2)}{" "}
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  } else if (type == "Graph") {
    var sensor = "";
    if (v1) {
      sensor = "voltage1";
    } else if (v2) {
      sensor = "voltage2";
    } else if (v3) {
      sensor = "voltage3";
    } else if (i1) {
      sensor = "current1";
    } else if (i2) {
      sensor = "current2";
    } else if (i3) {
      sensor = "current3";
    } else if (kwh) {
      sensor = "kWh";
    } else if (pf) {
      sensor = "pf";
    } else if (hz) {
      sensor = "hz";
    } else if (watt) {
      sensor = "watt";
    } else if (va) {
      sensor = "va";
    } else if (vln1) {
      sensor = "induction12";
    } else if (vln2) {
      sensor = "induction23";
    } else if (vln3) {
      sensor = "induction31";
    } else if (cavg) {
      sensor = "currentAvg";
    } else if (vavg) {
      sensor = "voltageAvg";
    } else if (vlnavg) {
      sensor = "voltageAvgLL";
    } else if (props.props.var) {
      sensor = "var";
    }
    return {
      sensor:sensor,
      meterV:meter.value,
      meterL:meter.label}
  }
  else {
    return <CardBody></CardBody>;
  }
}