import React from "react";
import { NavItem, NavLink, Badge, Collapse,Dropdown, DropdownItem } from "shards-react";
import {url} from '../../../../../utilities/requests'
import {IoMdNotifications} from 'react-icons/io'
export default class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      totalData : [],
      alarmData:[]
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(data){
    var initialValues = [];
    var actualAlarms = data;


    data.map((result,idx)=>{
      // console.log(data.uid)
      if(result.read== false){
        // console.log("Falsinator")
        initialValues.push(result);
      }
      if(initialValues.length > 4){
        initialValues = initialValues.slice(-4);
      }
    })

    this.setState({alarmData: initialValues, totalData: actualAlarms});
  }
  

  toggleNotifications() {
    
    if(this.state.visible==true){
      this.setState({alarmData:[]});

      var totalData = this.state.totalData;

      totalData.map((data,idx)=>{
        if(data.read==false){
          data.read=true;
        }
      })

      try{
        fetch(url+'alter_alarms', {headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             },
            method: "POST",
            body: JSON.stringify({
                "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"],
                "alarms_data": totalData
            })       
            })
          } catch (e){
        console.log(e)
    }

    }
    this.setState({
      visible: !this.state.visible
    });    
  }

  render() {

    var alarmVals = this.state.alarmData;
    return (
      <NavItem className=" dropdown notifications">
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper" >
            <IoMdNotifications size={25} color='#000075'/>
            {alarmVals.length > 0 ? <Badge pill theme="danger">
              {alarmVals.length}
            </Badge>: null}
            Notification
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
        >
        <Dropdown open={this.state.visible} toggle={this.toggleNotifications}>
          {alarmVals.map((data,idx)=>{
              return <DropdownItem key={idx}>
              <div className="notification__icon-wrapper" >
                <div className="notification__icon" >
                  <i className="material-icons">&#xE8D1;</i>
                </div>
              </div>
              <div className="notification__content">
                <span className="notification__category">{data.alarm} - {data.name}</span>
                <p>
                  {
                    data.alarmDesc
                  } 
                </p>
                <p>
                  {data.dateTime}
                </p>
              </div>
            </DropdownItem>
              }
            )
          
          }
          <DropdownItem className="notification__all text-center" onClick={()=> window.location.href='/Alarms'}>
            View all Notifications
          </DropdownItem>
        </Dropdown>
        </Collapse>
      </NavItem>
    );
  }
}
