import React from "react";
import { Nav ,Container,Col,Row} from "shards-react";

import Notifications from "./notification-component";
import UserActions from "./user-profile-component";
import Logout from "./user-logout-component";
import {RiLogoutBoxRFill} from 'react-icons/ri'
export default () => (
  <Nav navbar className=" flex-row flex-nowrap">
    <Container className='my-2 '>
      <Row>
        <Col><Notifications /></Col><Col><UserActions /></Col><Col><Logout/></Col>
      </Row>
      <Row >
      </Row>
    </Container>
  </Nav>
);
