import React from "react";
//import { Container, Row, Col } from "shards-react";
import {Row,Col,Container,Card,CardHeader,CardBody,Button,CardSubtitle} from "shards-react";
import { url } from "../../../../utilities/requests";
import "../../all-dashboard-styles/dynamic-input.scss";
import Table from "react-bootstrap/Table";
import Modal from "react-modal";
import { trackPromise } from "react-promise-tracker";
import Draggable from "react-draggable";
import { IoMdClose } from "react-icons/io";
import { RiDragDropLine } from "react-icons/ri";
import GraphsInternal from "../../dashboard-tools-component/Graph";
import Select from "react-select";
import ReactSelect from "../../dashboard-tools-component/common-multiselect";
import makeAnimated from "react-select/animated";
import "animate.css";
import "../../all-dashboard-styles/scrollbar.css";
import { FaCircle } from "react-icons/fa";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";
import "react-datetime-picker/dist/DateTimePicker.css";
import moment from "moment";
import LoadingIndicator from "../promiseTracker";
import { Rmonth, Rweek, Ryear ,Result} from "./Constant";
import { eventStop, singleDisplayDat } from "./diagmActions";
import { HeadersH, Method ,Token} from "../GlobalConst/GlobalConst";
export default class Meters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.modalForm = React.createRef();
    this.state = {
      offsetX: 0,offsetY: 0,tempName: "",graphMeter: "",tempType: "",textColor: "",tempType:'none',
      bgColor: "",Tutorial: false,tempMeter: [],options: [],tempSize: "",tempSubtitle: "",v1: false,
      v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,pf: false,hz: false,va: false,watt: false,
      vln1: false,vln2: false,vln3: false,cavg: false,vavg: false,vlnavg: false,var: false,startDate: new Date(),
      endDate: new Date(),result: Result,modal: false,modalVisible: false,graphData: {resultMonth: Rmonth,resultYear: Ryear,resultWeek: Rweek},
      display: [],selectMeterOptions: [{ label: "N/A", value: "N/A" }],selectedMeters: [],selectedSingleMeter: "",deltaPosition: {x: 0,y: 0},
      showLoader: "true",
    };
    const functionsToBind = [
      "handleChange","changeLoader","closeModal","cardName","cardDisplayData",
      "cardSubtitle","cardType","size","DisplayData","handleAdd","getData",
      "graph_data_set","deleteCard","checkSize","renderSelectMeter","renderSizeOptions",
      "renderValueOptions","singleDisplayData","handleMeterChange","loadData",
      "changeColor","handleSingleMeterChange","visibeTurotial","closeVisibleTutorial",
      "setStartDate","setEndDate","renderTimeSelection",
    ];
    functionsToBind.forEach((func) => {
      this[func] = this[func].bind(this);
    });
  }

  handleDrag = (e, ui) => {
    const { x, y } = this.state.deltaPosition;
    this.setState({ deltaPosition: { x: x + ui.deltaX, y: y + ui.deltaY } });
  };

  async dragStop(event, uid) {
    var tempDataDisplay = [];
    var dataDisplay = this.state.display;
    var offsetX = this.state.offsetX;
    var offsetY = this.state.offsetY;
    const updatedData = await eventStop(
      dataDisplay,
      offsetX,
      offsetY,
      tempDataDisplay,
      event,
      uid
    );
    this.setState({ display: updatedData, offsetX: 0, offsetY: 0 });
    try {
      trackPromise(
        fetch(url + "alter_user_dash", {
          headers: HeadersH,
          method: Method,
          body: JSON.stringify({
            idToken: Token,
            dash_data: updatedData,
          }),
        })
      );
    } catch (e) {
      console.log(e);
    }
  }

  closeModal() {
    this.setState({ modal: false });
  }

  handleChange(data) {
    this.setState({
      result: data["result"],
      display: data["dash_data"],
      selectMeterOptions: data["valMeter"],
    });
  }

  defaultData(data) {
    console.log(data);
  }

  changeLoader() {
    this.setState({ showLoader: false });
  }

  componentDidMount() {
    this.loadData();
    console.log("called again");
    setInterval(this.loadData, 300000);
  }
 

  async loadData() {
    try {
      const response = await fetch(url + "meters_table", {
        headers: HeadersH,
        method: Method,
        body: JSON.stringify({
          idToken: Token,
        }),
      });
      const data = await response.json();
      const { dash_data, result, valMeter } = data;
      if(result){
        this.setState({
          result:result,
          display:dash_data,
          selectMeterOptions:valMeter
        })
      }
    } catch (e) {
      console.log(e);
    }
  }

  cardName(event) {
    this.setState({ tempName: event.target.value });
  }

  handleMeterChange(selectedMeters) {
    this.setState({
      tempMeter: selectedMeters,
      selectedMeters: selectedMeters,
    });
  }

  handleSingleMeterChange(selectedSingleMeter) {
    this.setState({ tempMeter: selectedSingleMeter });
  }

  size(event) {
    this.setState({ tempSize: event.target.value });
  }

  cardSubtitle(event) {
    this.setState({ tempSubtitle: event.target.value });
  }

  async cardType(event) {
      this.setState({
        var: false,vlnavg: false,vavg: false,cavg: false,vln3: false,vln2: false,vln1: false,
        watt: false,v1: false,v2: false,v3: false,i1: false,i2: false,i3: false,kwh: false,
        pf: false,hz: false,va: false,
      });
      await this.setState({ tempType: event.target.value });
  }

  async handleAdd() {
    const {display,tempMeter,tempType,tempSize,v1,v2,v3,i1,i2,i3,kwh,pf,hz,va,watt,vln1,vln2,
      vln3,cavg,vavg,vlnavg,startDate,endDate} = this.state;
    const form = this.modalForm.current;
    var uniID = 0;
    for (let i = 0; i < display.length; i++) {
      uniID = display[i].uid + 1;
    }
    this.setState({
      display: [
        ...display,
        {
          uid: uniID,name: form["title"].value,meter: tempMeter,subtitle: form["subtitle"].value,
          type: tempType,size: tempSize,v1: v1,v2: v2,v3: v3,i1: i1,i2: i2,i3: i3,kwh: kwh,pf: pf,
          hz: hz,va: va,watt: watt,vln1: vln1,vln2: vln2,vln3: vln3,cavg: cavg,vavg: vavg,
          vlnavg: vlnavg,var: this.state.var,startDate: startDate,endDate: endDate,
        },
      ],

      graphMeter: tempMeter,
      modal: false,
      startDate: new Date(),
      endDate: new Date(),
      tempName: null,
      tempType: null,
      tempMeter: [],
      selectedMeters: [],
      tempSize: null,
      tempSubtitle: null,
      v1: false,
      v2: false,
      v3: false,
      i1: false,
      i2: false,
      i3: false,
      kwh: false,
      pf: false,
      hz: false,
      va: false,
      watt: false,
      vln1: false,
      vln2: false,
      vln3: false,
      cavg: false,
      vavg: false,
      vlnavg: false,
      var: false,
    });
    try {
      await fetch(url + "alter_user_dash", {
        headers: HeadersH,
        method: Method,
        body: JSON.stringify({
          idToken: Token,
          dash_data: [
            ...this.state.display,
            {
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              uid: uniID,
              name: form["title"].value,
              meter: this.state.tempMeter,
              subtitle: form["subtitle"].value,
              type: this.state.tempType,
              size: this.state.tempSize,
              v1: this.state.v1,
              v2: this.state.v2,
              v3: this.state.v3,
              i1: this.state.i1,
              i2: this.state.i2,
              i3: this.state.i3,
              kwh: this.state.kwh,
              pf: this.state.pf,
              hz: this.state.hz,
              va: this.state.va,
              watt: this.state.watt,
              vln1: this.state.vln1,
              vln2: this.state.vln2,
              vln3: this.state.vln3,
              cavg: this.state.cavg,
              vavg: this.state.vavg,
              vlnavg: this.state.vlnavg,
              var: this.state.var,
            },
          ],
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  closeVisibleModal() {
    this.setState({ modalVisible: false });
  }

  checkSize() {
    const { tempSize, tempMeter, tempType } = this.state;
    if (tempSize == "Small" && tempMeter.length >= 5 && tempType == "Table") {
      this.setState({ modalVisible: true });
    } else if (tempSize == "Small" && tempType == "Graph") {
      this.setState({ modalVisible: true });
    }
  }

  deleteCard(uid) {
    var dataDisplay = this.state.display;
    var tempDataDisplay = [];
    for (let i = 0; i < dataDisplay.length; i++) {
      if (dataDisplay[i].uid != uid) {
        tempDataDisplay = [...tempDataDisplay, dataDisplay[i]];
      }
    }
    this.setState({ display: tempDataDisplay });

    try {
      fetch(url + "alter_user_dash", {
        headers: HeadersH,
        method:Method,
        body: JSON.stringify({
          idToken: Token,
          dash_data: tempDataDisplay,
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  DisplayData(event) {
    const {v1,v2,v3,i1,i2,i3,kwh,pf,hz,va,watt,vln1,vln2,vln3,cavg,vavg,vlnavg,} = this.state;
    var tar = event.target.value;
    switch (tar) {
      case "v1": return this.setState({ v1: !v1 });
      case "v2": return this.setState({ v2: !v2 });
      case "v3": return this.setState({ v3: !v3 });
      case "i1": return this.setState({ i1: !i1 });
      case "i2": return this.setState({ i2: !i2 });
      case "i3": return this.setState({ i3: !i3 });
      case "kwh": return this.setState({ kwh: !kwh });
      case "pf": return this.setState({ pf: !pf });
      case "hz": return this.setState({ hz: !hz });
      case "va": return this.setState({ va: !va });
      case "watt": return this.setState({ watt: !watt });
      case "vln1": return this.setState({ vln1: !vln1 });
      case "vln2": return this.setState({ vln2: !vln2 });
      case "vln3": return this.setState({ vln3: !vln3 });
      case "cavg": return this.setState({ cavg: !cavg });
      case "vavg": return this.setState({ vavg: !vavg });
      case "vlnavg": return this.setState({ vlnavg: !vlnavg });
      case "var": return this.setState({ var: !this.state.var });
    }
  }

 async singleDisplayData(event) {
   const { v1, v2, v3, i1, i2, i3, kwh, pf, hz, va, watt, vln1, vln2, vln3, cavg, vavg, vlnavg, } = this.state;
  const data=await singleDisplayDat(event,v1,v2,v3,i1,i2,i3,kwh,pf,hz,va,watt,vln1,vln2,vln3,cavg,vavg,vlnavg)
   this.setState(data);
  }

  cardDisplayData = (props) => {
    const {meter,type,v1,v2,v3,i1,i2,i3,kwh,pf,hz,va,watt,vln1,vln2,vln3,cavg,vavg,vlnavg,} = props.props;
    if (type == "Data") {
      var result = this.state.result.filter((obj) => {
        return String(obj.uid) === meter.value;
      });

      if (v1 == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].v1.toFixed(4)} <b>V</b></h5></CardBody>
        );
      } else if (v2 == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].v2.toFixed(4)} <b>V</b></h5></CardBody>
        );
      } else if (v3 == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].v3.toFixed(4)} <b>V</b></h5></CardBody>
        );
      } else if (i1 == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].c1.toFixed(4)} <b>A</b></h5></CardBody>
        );
      } else if (i2 == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].c2.toFixed(4)} <b>A</b></h5></CardBody>
        );
      } else if (i3 == true) {
        return (
          <CardBody> <h5 className="p-0 m-0">{result[0].c3.toFixed(4)} <b>A</b></h5></CardBody>
        );
      } else if (kwh == true) {
        return (
          <CardBody> <h5 className="p-0 m-0">{result[0].kwh.toFixed(4)} <b>kWh</b></h5></CardBody>
        );
      } else if (pf == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].pf.toFixed(4)}</h5></CardBody>
        );
      } else if (hz == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].hz.toFixed(4)} <b>Hz</b></h5></CardBody>
        );
      } else if (va == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].va.toFixed(4)} <b>VA</b></h5></CardBody>
        );
      } else if (watt == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].watt.toFixed(4)} <b>W</b></h5></CardBody>
        );
      } else if (vln1 == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].vln1.toFixed(4)} <b>V</b></h5></CardBody>
        );
      } else if (vln2 == true) {
        return (
          <CardBody><h5 className="p-0 m-0">{result[0].vln2.toFixed(4)} <b>V</b></h5></CardBody>
        );
      } else if (vln3 == true) {
        return (
          <CardBody>
            <h5 className="p-0 m-0">
              {result[0].vln3.toFixed(4)} <b>V</b>
            </h5>
          </CardBody>
        );
      } else if (cavg == true) {
        return (
          <CardBody>
            <h5 className="p-0 m-0">
              {result[0].cavg.toFixed(4)} <b>A</b>
            </h5>
          </CardBody>
        );
      } else if (vavg == true) {
        return (
          <CardBody>
            <h5 className="p-0 m-0">
              {result[0].vavg.toFixed(4)} <b>V</b>
            </h5>
          </CardBody>
        );
      } else if (vlnavg == true) {
        return (
          <CardBody>
            <h5 className="p-0 m-0">
              {result[0].vlnavg.toFixed(4)} <b>V</b>
            </h5>
          </CardBody>
        );
      } else if (props.props.var == true) {
        return (
          <CardBody>
            <h5 className="p-0 m-0">
              {result[0].var.toFixed(4)} <b>KVAR</b>
            </h5>
          </CardBody>
        );
      } else {
        return <CardBody></CardBody>;
      }
    } else if (type == "Table") {
      return (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>UID</th>
              <th>SENSOR</th>
              <th>LOCATION</th>
              {kwh ? <th>KWh [counter]</th> : null}
              {watt ? <th>KW</th> : null}
              {va ? <th>KVA</th> : null}
              {i1 ? <th>Current Phase 1</th> : null}
              {i2 ? <th>Current Phase 2</th> : null}
              {i3 ? <th>Current Phase 3</th> : null}
              {v1 ? <th>Voltage P1-P2</th> : null}
              {v2 ? <th>Voltage P2-P3</th> : null}
              {v3 ? <th>Voltage P3-P1</th> : null}
              {vln1 ? <th>Voltage P1</th> : null}
              {vln2 ? <th>Voltage P2</th> : null}
              {vln3 ? <th>Voltage P3</th> : null}
              {pf ? <th>Power Factor</th> : null}
              {hz ? <th>Frequency</th> : null}
              {props.props.var ? <th>KVAR</th> : null}
              {cavg ? <th>Current (Av)</th> : null}
              {vavg ? <th>Voltage LL (Av)</th> : null}
              {vlnavg ? <th>Voltage LN (Av)</th> : null}
            </tr>
          </thead>
          <tbody>
            {meter.map((i) => {
              // console.log(i)
              return (
                <tr key={i.value}>
                  <td>{props.state.find((x) => x.uid2 === i.value).uid2}</td>
                  <td>
                    <FaCircle
                      className="mr-1"
                      style={{
                        color: props.state.find((x) => x.uid2 === i.value)
                          .color,
                      }}
                    />
                  </td>
                  <td>
                    {props.state.find((x) => x.uid2 === i.value).display_name}
                  </td>
                  {kwh ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .kwh.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {watt ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .watt.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {va ? (
                    <td>
                      {(
                        props.state.find((x) => x.uid2 === i.value).va / 1000
                      ).toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {i1 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .c1.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {i2 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .c2.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {i3 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .c3.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {v1 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .v1.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {v2 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .v2.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {v3 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .v3.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {vln1 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .vln1.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {vln2 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .vln2.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {vln3 ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .vln3.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {pf ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .pf.toFixed(2)}
                    </td>
                  ) : null}
                  {hz ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .hz.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {props.props.var ? (
                    <td>
                      {(
                        props.state.find((x) => x.uid2 === i.value).var / 1000
                      ).toFixed(2)}
                    </td>
                  ) : null}
                  {cavg ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .cavg.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {vavg ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .vavg.toFixed(2)}{" "}
                    </td>
                  ) : null}
                  {vlnavg ? (
                    <td>
                      {props.state
                        .find((x) => x.uid2 === i.value)
                        .vlnavg.toFixed(2)}{" "}
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    } else if (type == "Graph") {
      var sensor = "";
      if (v1) {
        sensor = "voltage1";
      } else if (v2) {
        sensor = "voltage2";
      } else if (v3) {
        sensor = "voltage3";
      } else if (i1) {
        sensor = "current1";
      } else if (i2) {
        sensor = "current2";
      } else if (i3) {
        sensor = "current3";
      } else if (kwh) {
        sensor = "kWh";
      } else if (pf) {
        sensor = "pf";
      } else if (hz) {
        sensor = "hz";
      } else if (watt) {
        sensor = "watt";
      } else if (va) {
        sensor = "va";
      } else if (vln1) {
        sensor = "induction12";
      } else if (vln2) {
        sensor = "induction23";
      } else if (vln3) {
        sensor = "induction31";
      } else if (cavg) {
        sensor = "currentAvg";
      } else if (vavg) {
        sensor = "voltageAvg";
      } else if (vlnavg) {
        sensor = "voltageAvgLL";
      } else if (props.props.var) {
        sensor = "var";
      }
      return (
        <GraphsInternal sensor={sensor} uid={meter.value} name={meter.label} />
      );
    }
    else {
      return <CardBody></CardBody>;
    }
  };

  graph_data_set = (data, uid) => {
    var result = this.state.display;
    for (let i = 0; i < result.length; i++) {
      if (result[i].uid === uid) {
        // console.log("Found")
        result[i].graphData = data;
        //console.log(data)
      }
    }
    // return result
    this.setState({ display: result });
  };

  getData = async (sensor, uid, cardID) => {
    // console.log("inside getdata")

    var dataResult = {};
    // console.log(result)
    try {
      await trackPromise(
        fetch(url + "meters_graphs", {
          headers: HeadersH,
          method: Method,
          body: JSON.stringify({
            idToken: Token,
            uid: uid,
            sensor: sensor,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            this.graph_data_set(data, cardID);
          })
      );
    } catch (e) {
      console.log(e);
    }

    return dataResult;
  };

  renderSelectMeter() {
    const {
      tempType,
      tempMeter,
      selectMeterOptions,
      selectedMeters,
      selectedSingleMeter,
    } = this.state;
    const animatedComponents = makeAnimated();
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted blue",
        //color: state.isSelected ? 'grey' : 'black',
        color: "black",
        //   padding: 20,
      }),
    };

    if (tempType == "Data" || tempType == "Graph") {
      return (
        <div>
          <h5 style={{ color: "white" }}>{tempMeter["label"]}</h5>
          <Select
            styles={customStyles}
            closeMenuOnSelect={true}
            components={animatedComponents}
            // isMulti
            options={selectMeterOptions}
            // onChange={this.handleSeriesChange:this.handleSingleMeterChange}
            onChange={this.handleSingleMeterChange}
            value={selectedSingleMeter}
          />
        </div>
      );
    } else {
      return (
        <ReactSelect
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={selectMeterOptions}
          onChange={this.handleMeterChange}
          value={selectedMeters}
        />
      );
    }
  }

  renderSizeOptions() {
    if (this.state.tempType == "Data") {
      return (
        <select onChange={this.size} className="selection">
          <option value="none">Select One</option>
          <option value="Small">Small</option>
        </select>
      );
    } else if (this.state.tempType == "Table") {
      return (
        <select onChange={this.size} className="selection">
          <option value="none">Select One</option>
          <option value="Medium">Medium</option>
          <option value="Large">Large</option>
          <option value="Full Screen">Full Screen</option>
        </select>
      );
    } else if (this.state.tempType == "Graph") {
      return (
        <select onChange={this.size} className="selection">
          <option value="none">Select One</option>
          <option value="Medium">Medium</option>
          <option value="Large">Large</option>
          <option value="Full Screen">Full Screen</option>
        </select>
      );
    }
    else {
      return (
        <select onChange={this.size} className="selection">
          <option value="none">Select One</option>
        </select>
      );
    }
  }

  renderValueOptions() {
    const {tempType,v1,v2,v3,i1,i2,i3,kwh,pf,hz,va,watt,vln1,vln2,vln3,cavg,vavg,vlnavg} = this.state;
    const { singleDisplayData, DisplayData } = this;
    if (tempType == "Graph") {
      return (
        <Col>
          <Row>
            <Col className="sub-title">Voltage :</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltage1"
                name="v1"
                value="v1"
                onChange={singleDisplayData}
                checked={v1}
              />{" "}
              R-Y
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage2"
                name="v2"
                value="v2"
                onChange={singleDisplayData}
                checked={v2}
              />{" "}
              Y-B
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage3"
                name="v3"
                value="v3"
                onChange={singleDisplayData}
                checked={v3}
              />{" "}
              B-R
            </Col>
          </Row>
          {/* <Row className='mt-1'>
                <Col  className='ckeck-text mx-3'><input type="checkbox" id="voltagelnavg" name="vlnavg" value="vlnavg" onChange={this.singleDisplayData} checked={this.state.vlnavg}/> Voltage Avg</Col>
                <Col  className='ckeck-text mx-3'><input type="checkbox" id="voltageavg" name="vavg" value="vavg" onChange={this.singleDisplayData} checked={this.state.vavg}/> VoltageLN Avg</Col>
                </Row> */}
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltageln1"
                name="vln1"
                value="vln1"
                onChange={singleDisplayData}
                checked={vln1}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln2"
                name="vln2"
                value="vln2"
                onChange={singleDisplayData}
                checked={vln2}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln3"
                name="vln3"
                value="vln3"
                onChange={singleDisplayData}
                checked={vln3}
              />{" "}
              B-N
            </Col>
          </Row>

          <Row>
            <Col className="sub-title mt-3">Current :</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="current1"
                name="i1"
                value="i1"
                onChange={singleDisplayData}
                checked={i1}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current2"
                name="i2"
                value="i2"
                onChange={singleDisplayData}
                checked={i2}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current3"
                name="i3"
                value="i3"
                onChange={singleDisplayData}
                checked={i3}
              />{" "}
              B-N
            </Col>
          </Row>

          <Row>
            <Col className="main">Other Values</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="kwh"
                name="kwh"
                value="kwh"
                onChange={singleDisplayData}
                checked={kwh}
              />{" "}
              KWh
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="pf"
                name="pf"
                value="pf"
                onChange={singleDisplayData}
                checked={pf}
              />{" "}
              PF
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="hz"
                name="hz"
                value="hz"
                onChange={singleDisplayData}
                checked={hz}
              />{" "}
              Freq.
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="watt"
                name="watt"
                value="watt"
                onChange={singleDisplayData}
                checked={watt}
              />{" "}
              KW
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="va"
                name="va"
                value="va"
                onChange={singleDisplayData}
                checked={va}
              />{" "}
              KVA{" "}
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="var"
                name="var"
                value="var"
                onChange={singleDisplayData}
                checked={this.state.var}
              />{" "}
              KVAR{" "}
            </Col>
          </Row>
        </Col>
      );
    } else if (tempType == "Data") {
      return (
        <Col>
          <Row>
            <Col className="sub-title">Voltage :</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltage1"
                name="v1"
                value="v1"
                onChange={singleDisplayData}
                checked={v1}
              />{" "}
              R-Y
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage2"
                name="v2"
                value="v2"
                onChange={singleDisplayData}
                checked={v2}
              />{" "}
              Y-B
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage3"
                name="v3"
                value="v3"
                onChange={singleDisplayData}
                checked={v3}
              />{" "}
              B-R
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltageln1"
                name="vln1"
                value="vln1"
                onChange={singleDisplayData}
                checked={vln1}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln2"
                name="vln2"
                value="vln2"
                onChange={singleDisplayData}
                checked={vln2}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln3"
                name="vln3"
                value="vln3"
                onChange={singleDisplayData}
                checked={vln3}
              />{" "}
              B-N
            </Col>
          </Row>

          <Row>
            <Col className="sub-title mt-3">Current :</Col>
          </Row>
          {/* <Row className='mt-1'>
            <Col  className='ckeck-text mx-3'><input type="checkbox" id="currentavg" name="cavg" value="cavg" onChange={this.singleDisplayData} checked={this.state.cavg}/> Average</Col>    
            </Row> */}
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="current1"
                name="i1"
                value="i1"
                onChange={singleDisplayData}
                checked={i1}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current2"
                name="i2"
                value="i2"
                onChange={singleDisplayData}
                checked={i2}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current3"
                name="i3"
                value="i3"
                onChange={singleDisplayData}
                checked={i3}
              />{" "}
              B-N
            </Col>
          </Row>

          <Row>
            <Col className="main">Other Values</Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="kwh"
                name="kwh"
                value="kwh"
                onChange={singleDisplayData}
                checked={kwh}
              />{" "}
              KWh
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="pf"
                name="pf"
                value="pf"
                onChange={singleDisplayData}
                checked={pf}
              />{" "}
              PF
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="hz"
                name="hz"
                value="hz"
                onChange={singleDisplayData}
                checked={hz}
              />{" "}
              Freq.
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="watt"
                name="watt"
                value="watt"
                onChange={singleDisplayData}
                checked={watt}
              />{" "}
              KW
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="va"
                name="va"
                value="va"
                onChange={singleDisplayData}
                checked={va}
              />{" "}
              KVA{" "}
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="var"
                name="var"
                value="var"
                onChange={singleDisplayData}
                checked={this.state.var}
              />{" "}
              KVAR{" "}
            </Col>
          </Row>
        </Col>
      );
    } else {
      return (
        <Col>
          <Row>
            <Col className="sub-title">Voltage :</Col>
          </Row>
          {/* <Row className='mt-1'>
            <Col  className='ckeck-text mx-3'><input type="checkbox" id="voltagelnavg" name="vlnavg" value="vlnavg" onChange={this.DisplayData}/> Voltage Avg</Col>
            <Col  className='ckeck-text mx-3'><input type="checkbox" id="voltageavg" name="vavg" value="vavg" onChange={this.DisplayData} /> VoltageLN Avg</Col>
            </Row> */}
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltage1"
                name="v1"
                value="v1"
                onChange={DisplayData}
              />{" "}
              R-Y
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage2"
                name="v2"
                value="v2"
                onChange={DisplayData}
              />{" "}
              Y-B
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltage3"
                name="v3"
                value="v3"
                onChange={DisplayData}
              />{" "}
              B-R
            </Col>
          </Row>
          <Row>
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="voltageln1"
                name="vln1"
                value="vln1"
                onChange={DisplayData}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln2"
                name="vln2"
                value="vln2"
                onChange={DisplayData}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="voltageln3"
                name="vln3"
                value="vln3"
                onChange={DisplayData}
              />{" "}
              B-N
            </Col>
          </Row>

          <Row>
            <Col className="sub-title mt-3">Current :</Col>
          </Row>
          {/* <Row  className='mt-1'>
            <Col  className='ckeck-text mx-3'><input type="checkbox" id="currentavg" name="cavg" value="cavg" onChange={this.DisplayData}/> Average</Col>    
            </Row> */}
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="current1"
                name="i1"
                value="i1"
                onChange={DisplayData}
              />{" "}
              R-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current2"
                name="i2"
                value="i2"
                onChange={DisplayData}
              />{" "}
              Y-N
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="current3"
                name="i3"
                value="i3"
                onChange={DisplayData}
              />{" "}
              B-N
            </Col>
          </Row>
          <Row>
            <Col className="main">Other Values</Col>
          </Row>
          <Row>
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="kwh"
                name="kwh"
                value="kwh"
                onChange={DisplayData}
              />{" "}
              KWH
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="pf"
                name="pf"
                value="pf"
                onChange={DisplayData}
              />{" "}
              PF
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="hz"
                name="hz"
                value="hz"
                onChange={DisplayData}
              />{" "}
              Freq.
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="ckeck-text mx-3">
              <input
                type="checkbox"
                id="watt"
                name="watt"
                value="watt"
                onChange={DisplayData}
              />{" "}
              KW
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="va"
                name="va"
                value="va"
                onChange={DisplayData}
              />{" "}
              KVA{" "}
            </Col>
            <Col className="ckeck-text">
              <input
                type="checkbox"
                id="var"
                name="var"
                value="var"
                onChange={DisplayData}
              />{" "}
              KVAR{" "}
            </Col>
          </Row>
        </Col>
      );
    }
  }

  setStartDate(value) {
    // let date = moment(value);
    // console.log(date._d);
    // this.setState({startDate:date._d});
    this.setState({ startDate: value });
    console.log(value);
  }
  setEndDate(value) {
    let date = moment(value);
    console.log(value);
    // this.setState({endDate:date._d});
    this.setState({ endDate: value });
    // console.log(value);
  }

  renderTimeSelection() {
    const { startDate, endDate } = this.state;
    const { setStartDate, setEndDate } = this;
    if (this.state.tempType == "History") {
      return (
        <div>
          <Col>
            <DateTimePicker
              style={{ backgroundColor: "white" }}
              value={startDate}
              onChange={(value) => setStartDate(value)}
            />
          </Col>
          <Col>
            <DateTimePicker
              className="react-datetime-picker__clock"
              value={endDate}
              onChange={(value) => setEndDate(value)}
            />
          </Col>
        </div>
      );
    } else {
      <div>
        <Col>
          <DateTimePicker
            disabled
            value={startDate}
            onChange={(value) => setStartDate(value)}
          />
        </Col>
        <Col>
          <DateTimePicker
            disabled
            value={endDate}
            onChange={(value) => setEndDate(value)}
          />
        </Col>
      </div>;
    }
  }

  closeMenuOption() {
    const { tempType } = this.state;
    if (tempType == "Data" || tempType == "Graph") return true;
    else if (tempType == "Table") return false;
  }

  launchTutorial(e) {
    console.log(e.target.value);
  }

  changeColor = (value, val) => {
    this.setState({ bgColor: value });
    this.setState({ textColor: val });
  };

  closeVisibleTutorial() {
    this.setState({ Tutorial: false });
  }

  visibeTurotial(e) {
    if (e.target.value == "false") {
      this.setState({ Tutorial: true });
    }
  }

  render() {
    const { toggleTutorial, modal, result } = this.state;
    const {
      visibeTurotial,
      closeVisibleTutorial,
      closeModal,
      cardType,
      size,
      handleAdd,
      dragStop,
      deleteCard,
    } = this;
    // console.log(this.state.display.length)
    // console.log(this.state.result)
    return (
      <div>
        <Container fluid className="main-content-container px-4 h-100">
          <Row
            nogutters="true"
            className="page-header d-flex justify-content-between"
          ></Row>
          <Row>
            <Col className="mt-2">
              <Button
                onClick={() => this.setState({ modal: true })}
                className="pl-2"
              >
                Add Card
              </Button>
            </Col>
          </Row>
          <LoadingIndicator />
          <Modal
            isOpen={this.state.Tutorial}
            onRequestClose={closeVisibleTutorial}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                zIndex: 4,
              },
              content: {
                position: "absolute",
                top: "10%",
                left: "10%",
                right: "10%",
                bottom: "15%",
                border: "1px solid #ccc",
                background: "rgba(0, 0, 0, 0.2)",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                outline: "none",
                padding: "4px",
              },
            }}
          >
            <div>
              <Col className="text-center pt-3">
                <Button onClick={() => this.setState({ Tutorial: false })}>
                  Close
                </Button>
              </Col>
            </div>
          </Modal>

          <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            ariaHideApp={false}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              content: {
                position: "absolute",
                top: "9%",
                left: "74%",
                right: "2%",
                bottom: "9%",
                border: "1px solid #ccc",
                background: "rgb(37,41,88)",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                outline: "none",
                padding: "4px",
              },
            }}
          >
            <Container>
              <form ref={this.modalForm}>
                <Row>
                  <Col className="main">Add Card Title</Col>
                </Row>
                <Row>
                  <Col className="sub-title">Title:</Col>
                  <Col>
                    <input type="text" name={"title"} />
                  </Col>
                </Row>
                <Row>
                  <Col className="sub-title">Subtitle:</Col>
                  <Col>
                    <input type="text" name={"subtitle"} />
                  </Col>
                </Row>
                <Row>
                  <Col className="main">Select Your Data To Display</Col>
                </Row>
                <Row>
                  <Col className="sub-title">Value</Col>
                  <Col>
                    <select
                      value={this.state.tempType || 'none'}
                      onChange={cardType}
                      className="selection"
                    >
                      <option value="none">Select One</option>
                      <option value="Data">Data</option>
                      <option value="Table">Table</option>
                      <option value="Graph">Graph</option>
                      {/* <option value="mango">Mango</option> */}
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col className="main">Select Meter</Col>
                </Row>
                <Row>
                  <Col className="sub-title">Location </Col>
                  <Col>
                    <this.renderSelectMeter />
                  </Col>
                </Row>
                <Row>
                  <Col className="main">Select Card Size</Col>
                </Row>
                <Row>
                  <Col className="sub-title">Custom</Col>
                  <Col>
                    <this.renderSizeOptions />
                  </Col>
                </Row>
                <Row onClick={this.size}></Row>
                <Row>
                  <Col className="main">Select Readings</Col>
                </Row>
                <Row>
                  <this.renderValueOptions />
                </Row>
                <Row>
                  <Col className="main">Select Timing</Col>
                </Row>
                <Row>
                  <this.renderTimeSelection />
                </Row>
                <Row className="mt-3 mx-2">
                  <Col>
                    {" "}
                    <Button onClick={() => handleAdd()}>Add Card</Button>
                  </Col>
                  <Col>
                    <Button onClick={() => this.setState({ modal: false })}>
                      Close
                    </Button>
                  </Col>
                </Row>
              </form>
            </Container>
          </Modal>

          {/* <Col><ToggleButton value={this.state.value || false} onToggle={(value)=>{this.setState({value:!value})}} onChange={this.launchTutorial}/></Col> */}
          <Row>
            {/* {console.log(this.state.display)}  */}
            {this.state.display.map((data, idx) => {
              if (data.size == "Small") {
                return (
                  <Draggable
                    onMouseDown={(event) =>
                      this.setState({
                        offsetX: event.nativeEvent["layerX"] - data.x,
                        offsetY: event.nativeEvent["layerY"] - data.y,
                      })
                    }
                    onStop={(event) => this.dragStop(event, data.uid)}
                    key={data.uid}
                    position={{ x: data.x, y: data.y }}
                  >
                    <Col md={2} className={"mb-5 mt-3"} handle={"strong"}>
                      {/* <Card style={{minHeight:'15vh',overflow:'hidden', maxHeight:'45vh'}} dragstop={(event)=> dragStop(event, data.uid)} > */}
                      <Card
                        style={{
                          minHeight: "15vh",
                          overflow: "hidden",
                          maxHeight: "45vh",
                        }}
                      >
                        <CardHeader>
                          <Row>
                            <Col md={3}>
                              <strong>
                                <RiDragDropLine style={{ color: "blue" }} />
                                <div style={{ fontSize: 7, color: "blue" }}>
                                  Drag
                                </div>
                              </strong>
                            </Col>
                            <Col md={6}>
                              <h6>{data.name}</h6>
                            </Col>
                            <Col className="" md={2}>
                              <IoMdClose
                                size={25}
                                color="#800000"
                                id={"delete" + data.uid}
                                name={"delete" + data.uid}
                                value={"delete" + data.uid}
                                onClick={() => deleteCard(data.uid)}
                              />
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardSubtitle>
                          <Col className="ml-3 px-5">
                            <h6>
                              {data.type === "History"
                                ? "History of " + data.meter.label
                                : data.subtitle}
                            </h6>
                          </Col>
                        </CardSubtitle>
                        <this.cardDisplayData props={data} state={result} />
                      </Card>
                    </Col>
                  </Draggable>
                );
              } else if (data.size == "Medium") {
                return (
                  <Draggable
                    onMouseDown={(event) =>
                      this.setState({
                        offsetX: event.nativeEvent["layerX"] - data.x,
                        offsetY: event.nativeEvent["layerY"] - data.y,
                      })
                    }
                    onStop={(event) => this.dragStop(event, data.uid)}
                    key={data.uid}
                    position={{ x: data.x, y: data.y }}
                  >
                    <Col md={4} className={"mb-5 mt-3"} handle={"strong"}>
                      <Card
                        style={{
                          minHeight: "30vh",
                          overflow: "auto",
                          maxHeight: "90vh",
                        }}
                      >
                        <CardHeader>
                          <Row>
                            <Col md={3}>
                              <strong>
                                <RiDragDropLine style={{ color: "blue" }} />
                                <div style={{ fontSize: 7, color: "blue" }}>
                                  Drag
                                </div>
                              </strong>
                            </Col>
                            <Col md={6}>
                              <h6 className="text-center">{data.name}</h6>
                            </Col>
                            <Col className="align-items-right" md={2}>
                              <IoMdClose
                                size={25}
                                color="#800000"
                                id={"delete" + data.uid}
                                name={"delete" + data.uid}
                                value={"delete" + data.uid}
                                onClick={() => deleteCard(data.uid)}
                              />
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardSubtitle>
                          <Col className="ml-3">
                            <h6>
                              {data.type === "History"
                                ? "History of " + data.meter.label
                                : data.subtitle}
                            </h6>
                          </Col>
                        </CardSubtitle>
                        <this.cardDisplayData props={data} state={result} />
                      </Card>
                    </Col>
                  </Draggable>
                );
              } else if (data.size == "Large") {
                return (
                  <Draggable
                    onMouseDown={(event) =>
                      this.setState({
                        offsetX: event.nativeEvent["layerX"] - data.x,
                        offsetY: event.nativeEvent["layerY"] - data.y,
                      })
                    }
                    onStop={(event) => this.dragStop(event, data.uid)}
                    key={data.uid}
                    position={{ x: data.x, y: data.y }}
                  >
                    <Col md={6} className={"mb-5 mt-3"} handle={"strong"}>
                      <Card
                        style={{
                          minHeight: "40vh",
                          width: "100%",
                          overflow: "auto",
                          maxHeight: "90vh",
                        }}
                      >
                        <CardHeader>
                          <Row>
                            <Col md={3}>
                              <strong>
                                <RiDragDropLine style={{ color: "blue" }} />
                                <div style={{ fontSize: 7, color: "blue" }}>
                                  Drag
                                </div>
                              </strong>
                            </Col>
                            <Col md={6}>
                              <h6 className="text-center">{data.name}</h6>
                            </Col>
                            <Col className="align-items-right" md={2}>
                              <IoMdClose
                                size={25}
                                color="#800000"
                                id={"delete" + data.uid}
                                name={"delete" + data.uid}
                                value={"delete" + data.uid}
                                onClick={() => deleteCard(data.uid)}
                              />
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardSubtitle>
                          <Col className="ml-3">
                            <h6>
                              {" "}
                              {data.type === "History"
                                ? "History of " + data.meter.label
                                : data.subtitle}
                            </h6>
                          </Col>
                        </CardSubtitle>
                        <this.cardDisplayData props={data} state={result} />
                      </Card>
                    </Col>
                  </Draggable>
                );
              } else if (data.size == "Full Screen") {
                return (
                  <Draggable
                    onMouseDown={(event) =>
                      this.setState({
                        offsetX: event.nativeEvent["layerX"] - data.x,
                        offsetY: event.nativeEvent["layerY"] - data.y,
                      })
                    }
                    onStop={(event) => this.dragStop(event, data.uid)}
                    key={data.uid}
                    position={{ x: data.x, y: data.y }}
                  >
                    <Col md={12} className={"mb-5 mt-3"} handle={"strong"}>
                      <Card
                        style={{
                          minHeight: "40vh",
                          width: "100%",
                          overflow: "auto",
                          maxHeight: "90vh",
                        }}
                      >
                        <CardHeader>
                          <Row>
                            <Col md={3}>
                              <strong>
                                <RiDragDropLine style={{ color: "blue" }} />
                                <div style={{ fontSize: 7, color: "blue" }}>
                                  Drag
                                </div>
                              </strong>
                            </Col>
                            <Col md={6}>
                              <h6 className="text-center">{data.name}</h6>
                            </Col>
                            <Col className="align-items-right" md={2}>
                              <IoMdClose
                                size={25}
                                color="#800000"
                                id={"delete" + data.uid}
                                name={"delete" + data.uid}
                                value={"delete" + data.uid}
                                onClick={() => deleteCard(data.uid)}
                              />
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardSubtitle>
                          <Col className="ml-3">
                            <h6>
                              {" "}
                              {data.type === "History"
                                ? "History of " + data.meter.label
                                : data.subtitle}
                            </h6>
                          </Col>
                        </CardSubtitle>
                        <this.cardDisplayData props={data} state={result} />
                      </Card>
                    </Col>
                  </Draggable>
                );
              } else {
                return (
                  <Draggable
                    onMouseDown={(event) =>
                      this.setState({
                        offsetX: event.nativeEvent["layerX"] - data.x,
                        offsetY: event.nativeEvent["layerY"] - data.y,
                      })
                    }
                    onStop={(event) => this.dragStop(event, data.uid)}
                    key={data.uid}
                    position={{ x: data.x, y: data.y }}
                  >
                    <Col className={"mb-5 mt-3"} handle={"strong"}>
                      <Card>
                        <CardHeader>
                          <Col>{data.name}</Col>
                          <Col className="align-items-right" md={2}>
                            <IoMdClose
                              size={25}
                              color="#800000"
                              id={"delete" + data.uid}
                              name={"delete" + data.uid}
                              value={"delete" + data.uid}
                              onClick={() => deleteCard(data.uid)}
                            />
                          </Col>
                        </CardHeader>
                        <CardSubtitle>{data.subtitle}</CardSubtitle>
                        <this.cardDisplayData props={data} state={result} />
                      </Card>
                    </Col>
                  </Draggable>
                );
              }
            })}
          </Row>
        </Container>
      </div>
    );
  }
}
Modal.setAppElement(document.getElementById("root"));
