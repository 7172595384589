import {FaUserAlt} from 'react-icons/fa'
import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { selectApp, setAppSliceState } from '../../../../../state/appSlice';
import { selectLogin, setLoginSliceState } from '../../../../../state/loginSlice';
import { useDispatch, useSelector } from 'react-redux';

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }


  render() {
    return (
      <NavItem className=" dropdown notifications">
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleUserActions}
          >
          <div className="nav-link-icon__wrapper" onBlur={this.toggleUserActions} tabIndex="0">
            <FaUserAlt onClick={()=> window.location.href='/user-profile'} size={20} color='#000075'/>
            <div className='mt-1'>Profile</div>
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small">
        </Collapse>
      </NavItem>
    );
  }
}
