import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import ReactSelect from './common-multiselect';
import makeAnimated from 'react-select/animated';


const Multiselect = (props) => {
    const [selectedMeters, setSelectedMeters] = useState(props.selectedMeters);
    // const selectedMeters = this.props.selectedMeters;
    const options = props.options;
    const animatedComponents = makeAnimated();

    const handleChange =(value) =>{
        setSelectedMeters(value)
        // console.log(selectedMeters.length)
        // console.log(selectedMeters)
    }
    const displayMeters =(meters)=>{
        console.log(meters)
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => props.handleMeterChange(selectedMeters), 3000);
        return () => clearTimeout(timeoutId);
      }, [selectedMeters]);


    return(<ReactSelect 
                                // defaultValue = {this.state.alreadySelectedMeters}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                options={options} 
                                onChange={handleChange}
                                value = {selectedMeters}
                                isMulti = {true}
                                isDisabled={selectedMeters.length>=7?true:false}
                                className = "basic-mmulti-select"
                                classNamePrefix = "select"
                            />)
};

export default Multiselect;