export const dummyData = [
  {
    "DUMMY_1": 25.641807556152344,
    "DUMMY_2": 63.846458435058594,
    "DUMMY_3": 28.822032928466797,
    date: "11-09-23 08:10:30", // Corrected date format
  },
  {
    "DUMMY_1": 27.641807556152344,
    "DUMMY_2": 23.846458435058594,
    "DUMMY_3": 18.822032928466797,
    date: "11-09-23 09:10:30", // Corrected date format
  },
  {
    "DUMMY_1": 30.641807556152344,
    "DUMMY_2": 53.846458435058594,
    "DUMMY_3": 18.822032928466797,
    date: "11-09-23 10:20:30", // Corrected date format
  },
  {
    "DUMMY_1": 24.641807556152344,
    "DUMMY_2": 43.846458435058594,
    "DUMMY_3": 18.822032928466797,
    date: "11-09-23 11:30:30", // Corrected date format
  },
  
];
