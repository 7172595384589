import React, { Component } from "react";
import Plot from "react-plotly.js";
import "./Graph.css";

class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingStartTime: null,
    };
  }
  componentDidMount() {
    this.updateAnnotations(); // Update annotations when the component mounts
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ loading: true, loadingStartTime: new Date() }, () => {
        setTimeout(() => {
          this.updateAnnotations();
          this.setState({ loading: false });
          const loadingEndTime = new Date();
          const loadingTime = loadingEndTime - this.state.loadingStartTime;
          console.log(`Graph loaded in ${loadingTime} milliseconds.`);
        }, 500); // Adjust the duration of the transition (in milliseconds)
      });
    }
  }

  updateAnnotations() {
    const { data } = this.props;
    const keys = Object.keys(data[0]).filter((key) => key !== "date");
    const x = data.map((item) => this.parseDate(item.date));
    const dateRange = x.slice(-1)[0] - x[0];
    const numAnnotations = keys.length;
    const interval = dateRange / (numAnnotations + 1);

    const annotations = keys.map((key, index) => {
      const xCoordinate = x[0].getTime() + interval * (index + 1) * 1;
      return {
        x: xCoordinate,
        y: data[0][key],
        xref: "x",
        yref: "y",
        text: `<b>${key}: ${data[0][key]}</b>`,
        showarrow: true,
        arrowhead: 1,
        ax: 10,
        ay: -30,
      };
    });

    this.setState({ annotations }); // Update annotations in state
  }

  parseDate(dateString) {
    const parts = dateString.split(" ");
    const datePart = parts[0].split("-").map((part) => parseInt(part));
    const timePart = parts[1].split(":").map((part) => parseInt(part));
    return new Date(
      2000 + datePart[0],
      datePart[1] - 1,
      datePart[2],
      timePart[0],
      timePart[1],
      timePart[2]
    );
  }

  render() {
    const { loading } = this.state;
    const mtrData = this.props.data;
    //console.log(mtrData)
    const graphSt = this.props.graphState;
    const chartType = graphSt || "Area";
    const keys = Object.keys(mtrData[0]).filter((key) => key !== "date");
    const dummyData = mtrData.map((item) =>
      Object.keys(item).filter((key) => key.startsWith("DUMMY_"))
    );
    const dataToDisplay = loading ? dummyData : this.props.data;
    const colors = [
      "rgba(31, 119, 180, 0.8)", // blue
      "rgba(255, 127, 14, 0.8)", // orange
      "rgba(44, 160, 44, 0.8)", // green
      "rgba(214, 39, 40, 0.8)", // red
      "rgba(148, 103, 189, 0.8)", // purple
      "rgba(140, 86, 75, 0.8)", // brown
      "rgba(227, 119, 194, 0.8)", // pink
      "rgba(127, 127, 127, 0.8)", // gray
      "rgba(188, 189, 34, 0.8)", // olive
      "rgba(23, 190, 207, 0.8)", // teal
    ];

    const traces = keys.map((key, index) => {
      const x = mtrData.map((item) => this.parseDate(item.date)); // Parse date
      const y = mtrData.map((item) => item[key]);
      const name = key;
      const lineColor = colors[index];
      const fillOpacity = 0.1; // Set the desired opacity value (0.1 for very light)
      const rgbaValues = lineColor.slice(4, -1).split(","); // Extract RGB values from lineColor
      const rgb = rgbaValues.slice(0, 3).join(","); // Join the first 3 values (RGB)
      const fillColor = `rgba(${rgb}, ${fillOpacity})`;
      let trace;
      if (chartType === "Line") {
        // Line chart
        trace = {
          x: x,
          y: y,
          type: "scatter",
          mode: "lines",
          name: name,
          line: {
            color: lineColor,
            width: 2,
            opacity: 0.8,
          },
          // ... (rest of your trace properties)
        };
      } else if (chartType === "Bar") {
        // Bar chart
        trace = {
          x: x,
          y: y,
          type: "bar",
          name: name,
          marker: {
            color: lineColor,
            opacity: 0.8,
          },
          // ... (rest of your trace properties)
        };
      } else if (chartType === "Area") {
        // Area chart
        trace = {
          x: x,
          y: y,
          type: "scatter",
          mode: "lines",
          fill: "tozeroy",
          name: name,
          fillcolor: fillColor,
          line: {
            color: lineColor,
            width: 2,
            opacity: 0.8,
          },
          // ... (rest of your trace properties)
        };
      } else if (chartType === "Scatter") {
        // Scatter chart
        trace = {
          x: x,
          y: y,
          mode: "markers",
          type: "scatter",
          name: name,
          marker: {
            color: lineColor,
            size: 8,
            line: {
              width: 1,
              color: "black",
            },
          },
          // ... (rest of your trace properties)
        };
      }
      return trace;
    });

    return (
      <div className="graph-container">
        {loading && <div>Loading...</div>}
        <Plot
          data={traces}
          layout={{
            title: "Area Chart",
            xaxis: {
              title: "date",
              type: "date",
            },
            yaxis: {
              title: "value",
            },
            annotations: this.state.annotations, // Use annotations from state
            margin: {
              t: 80,
            },
            legend: {
              orientation: "h",
              x: 0,
              y: -0.2,
              itemclick: "toggleothers", 
              traceorder: "normal",
            },
          }}
          config={{ responsive: true }} // Enable responsive behavior
          style={{ width: "72vw", maxWidth: "100%"}} // Set maximum width
        />
      </div>
    );
  }
}

export default Graph;
