import { url } from "../../../../utilities/requests";

export const setEDate = (date) => {
  // console.log(date);
  let sDate = date;
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  sDate = new Date(sDate - tzoffset).toISOString().slice(0, -1).split("T")[0];
  return sDate;
};
export const setSDate = (date) => {
  let sDate = date;
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  sDate = new Date(sDate - tzoffset).toISOString().slice(0, -1).split("T")[0];
  return sDate;
};

export const switchInt = (interval) => {
  switch (interval) {
    case "1 Hour":
      return 60;
    case "30 Min":
      return 30;
    case "10 Min":
      return 10;
    case "5 Min":
      return 5;
    case "1 Min":
      return 1;
    default:
      return 1;
  }
};