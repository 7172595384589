import React from "react";
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar , ComposedChart, Scatter, RadialBarChart, RadialBar, ResponsiveContainer } from 'recharts';
import { AreaChart, Area} from 'recharts';
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody, Button } from "shards-react";

const data = [
  {
    name: '18-24', uv: 31.47, pv: 2400, fill: '#8884d8',
  },
  {
    name: '25-29', uv: 26.69, pv: 4567, fill: '#83a6ed',
  },
  {
    name: '30-34', uv: 15.69, pv: 1398, fill: '#8dd1e1',
  },
  {
    name: '35-39', uv: 8.22, pv: 9800, fill: '#82ca9d',
  },
  {
    name: '40-49', uv: 8.63, pv: 3908, fill: '#a4de6c',
  },
  {
    name: '50+', uv: 2.63, pv: 4800, fill: '#d0ed57',
  },
  {
    name: 'unknow', uv: 6.67, pv: 4800, fill: '#ffc658',
  },
];

const style = {top: 0,
  left: 350,
  lineHeight: '24px',}

class Graphs extends React.PureComponent {
    constructor(props) {
        super(props);
  
        this.canvasRef = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.toggleChart = this.toggleChart.bind(this);
        this.switchData = this.switchData.bind(this);
        this.state = { open: false, visibleDrop1:"inline", visibleDrop2:"inline", graphState: this.props.graphState , selectedOption: "Week", monthData:[], yearData:[], weekData:[], openChart:false };
    }

    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/9km41z5z/';

    toggle() {
        this.setState(prevState => {
          return { open: !prevState.open };
        });
      }

    toggleChart() {
        this.setState(prevState => {
          return { openChart: !prevState.openChart };
        });
      }
    
    
    // chartData = this.props.chartData;
      monthData = this.props.monthData;
      yearData = this.props.yearData;
      weekData = this.props.weekData;

    switchData(selectedOption){
        if(selectedOption === "Month"){
            return (this.state.monthData);
        }
        else{
            return (this.state.yearData);
        }
    }

    switchChart(props){
      var selectedOption = props.selectedOption;
      var graphOption = props.graphOption;
      var weekData = props.weekData;
      var monthData = props.monthData;
      var yearData = props.yearData;
      var graphState = props.graphState;
      var visibleDrop1 = props.visibleDrop1;
      var visibleDrop2 = props.visibleDrop2;

      const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
      const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
      }) => {
         const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
          
      };

      const RADIAN = Math.PI / 180;

      if(graphOption === "Area"){
        return <ResponsiveContainer widht={'100%'} height={250}> 
        <AreaChart 
        // width={400} height={250}  
        margin={{
          top: 5, right: 30, left: 10, bottom: 5,
        }} data={ selectedOption==="Week" ? weekData : (selectedOption === "Month"? monthData : yearData)}>
        <defs>

            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#8884d8" stopOpacity={0.1} />
                <stop offset="40%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.1} />
                <stop offset="40%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
        </defs>
        <XAxis dataKey="name" interval={selectedOption === "Week" ? 0: (selectedOption==="Month" ? 1 : 0)}/>
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="Last" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" strokeWidth={1.5} dot={true}/>
        <Area type="monotone" dataKey="Current" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" strokeWidth={1.5} dot={true}/>
    </AreaChart>
    </ResponsiveContainer>
      }
      else  if(graphOption === "Bar"){
        return <BarChart
        width={550}
        height={250}
        data={ selectedOption==="Week" ? weekData : (selectedOption === "Month"? monthData : yearData)}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Last" fill="#8884d8" />
        <Bar dataKey="Current" fill="#82ca9d" />
      </BarChart>
      }
      else if(graphOption === "Bar1"){
        return <BarChart 
        width={550}
        height={250}
        data={[3, 2, 4]}
        margin={{
          top: 5, right: 30, left: 20, bottom:5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Last" fill="#8884d8" />
        <Bar dataKey="Current" fill="#82ca9d" />
      </BarChart>
      }
      else if(graphOption === "Scatter"){
        return <ComposedChart
            width={450}
            height={250}
            data={ selectedOption==="Week" ? weekData : (selectedOption === "Month"? monthData : yearData)}
            margin={{
              top: 20, right: 80, bottom: 20, left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <Tooltip />
            <Legend />
    
            <XAxis dataKey="name" type="number" label={{ value: 'Index', position: 'insideBottomRight', offset: 0 }} />
            <YAxis unit="ms" type="number" label={{ value: 'Time', angle: -90, position: 'insideLeft' }} />
            <Scatter name="Last" dataKey="Last" fill="#8884d8" />
            <Scatter name="Current" dataKey="Current" fill="#82ca9d" />
            <Line dataKey="Last" stroke="#8884d8" dot={false} activeDot={false} legendType="none" />
            <Line dataKey="Current" stroke="#82ca9d" dot={false} activeDot={false} legendType="none" />
          </ComposedChart>
          
      }
      else if(graphOption === "Radial"){
        return <RadialBarChart width={450} height={250} cx={150} cy={150} innerRadius={20} outerRadius={140} barSize={10} data={data}>
        <RadialBar minAngle={15} label={{ position: 'insideStart', fill: '#fff' }} background clockWise dataKey="uv" />
        <Legend iconSize={10} width={120} height={140} layout="vertical" verticalAlign="middle" wrapperStyle={style} />
      </RadialBarChart>
        
      }
      else{
        
        return <ComposedChart
        width={450}
        height={250}
        data={ selectedOption==="Week" ? weekData : (selectedOption === "Month"? monthData : yearData)}
        margin={{
          top: 5, right: 30, left: 10, bottom: 5,
        }}
        
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0058FF" stopOpacity={0.1}/>
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
          </linearGradient>
        </defs>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" interval={selectedOption === "Week" ? 0: (selectedOption==="Month" ? 1 : 0)}/>
        <YAxis />
        <Tooltip />
        <Legend />
        {/* <Line type="monotone" dataKey="Last" stroke="#8884d8" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Current" stroke="#82ca9d" strokeDasharray="3 4 5 2" /> */}
        <Line type="monotone" stroke="#21D59B" dataKey="Last" />
        <Line type="monotone"  stroke="#0058FF" dataKey="Current" strokeWidth={2}  />
        {/* <Area type="monotone" dataKey="Current" stroke="#0058F" strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" /> */}
      </ComposedChart>
      }
      
}
    
    
    render() {
        
        const { title, monthData, yearData, weekData, visibleDrop1, visibleDrop2} = this.props;
        // console.log(monthData);
        this.state.monthData = this.monthData;
        this.state.yearData = this.yearData;
        this.state.weekData = this.weekData;
        this.state.visibleDrop1 = this.visibleDrop1;
        this.state.visibleDrop2 = this.visibleDrop2;
        // console.log(this.monthData)
        return (
            
            
            // <Card small style={{borderRadius:0}} className="mb-3">
            <div>
            {/* <CardHeader className="d-flex justify-content-between"> */}
              {/* <h6 className="m-0">{title}</h6> */}
              {/* <Row className="border-bottom py-2 bg-light justify-content-end"> */}
              <Col className="d-flex align-items-end flex-column col-md-auto">
                <div style={{visibility: visibleDrop2}}>
                 <Dropdown open={this.state.openChart} toggle={this.toggleChart} group>
                    <Button>{this.state.graphState}</Button>
                    <DropdownToggle split />
                    <DropdownMenu>
                    <DropdownItem onClick={() => this.state.graphState = "Area"}>Area</DropdownItem>
                    <DropdownItem onClick={() => this.state.graphState = "Bar"}>Bar</DropdownItem>
                    <DropdownItem onClick={() => this.state.graphState = "Scatter"}>Scatter</DropdownItem>
                    {/* <DropdownItem onClick={() => this.state.graphState = "Radial"}>Radial</DropdownItem> */}
                    <DropdownItem onClick={() => this.state.graphState = "Line"}>Line</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                </div>
            </Col>
            <Col className="d-flex align-items-end flex-column col-md-auto">
            <div style={{visibility: visibleDrop1}}>
           

                <Dropdown open={this.state.open} toggle={this.toggle} group>
                    <Button>{this.state.selectedOption}</Button>
                    <DropdownToggle split />
                    <DropdownMenu>
                    <DropdownItem onClick={() => this.state.selectedOption = "Week"}>Week</DropdownItem>
                    <DropdownItem onClick={() => this.state.selectedOption = "Month"}>Month</DropdownItem>
                    <DropdownItem onClick={() => this.state.selectedOption = "Year"}>Year</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            </Col>
            {/* </Row> */}
            {/* </CardHeader> */}
            {/* <CardBody className="pt-0"> */}
              
            <Row>
            <this.switchChart selectedOption={this.state.selectedOption} graphOption={this.state.graphState} weekData={this.props.weekData} monthData={this.props.monthData} yearData={this.props.yearData} />
            </Row>
            {/* </CardBody> */}
            </div>
            // </Card>
        
        )
    }
}

Graphs.propTypes = {
    /**
     * The component's title.
     */
    title: PropTypes.string,
    /**
     * The chart dataset.
     */
    weekData: PropTypes.array,
    monthData: PropTypes.array,
    yearData: PropTypes.array,
    visibleDrop1: PropTypes.string,
    visibleDrop2: PropTypes.string,
    /**
     * The Chart.js options.
     */
    chartOptions: PropTypes.object,
    fullReportLink: PropTypes.string
  };
  
  Graphs.defaultProps = {
    title: "Rooms Overview",
    visibleDrop1: "inline",
    visibleDrop2: "inline",

    weekData:[
    {'name': 1, 'Last': 0.0, 'Current': 0.0},
    {'name': 2, 'Last': 0.0, 'Current': 0.0},
    {'name': 3, 'Last': 0.0, 'Current': 0.0},
    {'name': 4, 'Last': 0.0, 'Current': 0.0},
    {'name': 5, 'Last': 0.0, 'Current': 0.0},
    {'name': 6, 'Last': 0.0, 'Current': 0.0},
    {'name': 7, 'Last': 0.0, 'Current': 0.0},
    ],
    
    monthData: [{'name': 1, 'Last': 0.0, 'Current': 0.0},
    {'name': 2, 'Last': 0.0, 'Current': 0.0},
    {'name': 3, 'Last': 0.0, 'Current': 0.0},
    {'name': 4, 'Last': 0.0, 'Current': 0.0},
    {'name': 5, 'Last': 0.0, 'Current': 0.0},
    {'name': 6, 'Last': 0.0, 'Current': 0.0},
    {'name': 7, 'Last': 0.0, 'Current': 0.0},
    {'name': 8, 'Last': 0.0, 'Current': 0.0},
    {'name': 9, 'Last': 0.0, 'Current': 0.0},
    {'name': 10, 'Last': 0.0, 'Current': 0.0},
    {'name': 11, 'Last': 0.0, 'Current': 0.0},
    {'name': 12, 'Last': 0.0, 'Current': 0.0}],

    yearData: [{'name': 1, 'Last': 0.0, 'Current': 0.0},
    {'name': 2, 'Last': 0.0, 'Current': 0.0},
    {'name': 3, 'Last': 0.0, 'Current': 0.0},
    {'name': 4, 'Last': 0.0, 'Current': 0.0},
    {'name': 5, 'Last': 0.0, 'Current': 0.0},
    {'name': 6, 'Last': 0.0, 'Current': 0.0},
    {'name': 7, 'Last': 0.0, 'Current': 0.0},
    {'name': 8, 'Last': 0.0, 'Current': 0.0},
    {'name': 9, 'Last': 0.0, 'Current': 0.0},
    {'name': 10, 'Last': 0.0, 'Current': 0.0},
    {'name': 11, 'Last': 0.0, 'Current': 0.0},
    {'name': 12, 'Last': 0.0, 'Current': 0.0}],

  };



export default Graphs;