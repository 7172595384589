import React from 'react';
import {url} from '../../../utilities/requests'
import Table from 'react-bootstrap/Table'

export default class HistoryTable extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {uid:props.uid, sensor:props.sensor, startDate:props.startDate, endDate:props.endDate, historyData:[[946684800, {'min': 0, 'max': 0, 'avg': 0, 'num': 0}]]}
        this.handleData= this.handleData.bind(this);
    }

    async handleData(data){
        // console.log(data)
        await this.setState({historyData: data});
    }

    async componentDidMount(){
        // console.log(this.state.uid);
        // console.log(this.state.sensor);
        // console.log(this.state.startDate);
        // console.log(this.state.endDate);
        try{
            await fetch(url+'history_data', {headers:{
                'Content-Type' : 'application/json',
                'Accept' : 'application/json'
            },
            method: "POST", 
            body: JSON.stringify({
                "idToken": JSON.parse(sessionStorage["default-session-data"])["userData"]["idToken"],
                "uid": this.state.uid,
                "sensor": this.state.sensor,
                "startDate": this.state.startDate,
                "endDate":this.state.endDate
            })
        }).then(response => response.json()).then(data => {this.handleData(data)})
        // .then(response => response.json()).then(data => {this.handleData(data)})
        }
        catch(e){
            console.log(e);
        }
    }

    renderData(){
        // if(this.state.historyData){
        
        this.state.historyData.map((data, idx)=>{
            return(<tr>
                <td>{data[0]}</td>
                <td>{data[1]['avg']}</td>
            </tr>)
        })
        // }
        // else{
        //     console.log('nothing in data');
        // }
    }

    sensorValue(){
        var sensor = this.state.sensor;
        if(sensor === 'voltage1' || sensor === 'voltage2' || sensor === 'voltage3' || sensor === 'induction12' || sensor === 'induction23' || sensor === 'induction31' || sensor === 'voltageAvg' || sensor === 'voltageAvgLL'){
            return "V"
        }
        else if(sensor === 'current1' || sensor === 'current2' || sensor === 'current3' || sensor === 'currentAvg'){
            return "A"
        }
        else if(sensor === 'kWh'){
            return "kWh"
        }
        else if(sensor === 'hz'){
            return "Hz"
        }
        else if(sensor === 'watt'){
            return "W"
        }
        else if(sensor === 'va'){
            return "VA"
        }
        else if(sensor === 'var'){
            return "VAR"
        }
        else{
            return ""
        }
    }

    render(){
        // return null;
        return(<div>
            <Table striped bordered hover style={{overflowY:"auto"}}>
                <thead>
                    <tr>
                        <th>Date/Time</th>
                        <th>Average Value {this.sensorValue()}</th>
                        <th>Minimum Value</th>
                        <th>Maximum Value</th>
                    </tr>
                </thead>
                
                <tbody>
                    {this.state.historyData.map((data,idx)=>{
                        return(<tr key={idx}>
                            <td>{data[0]}</td>
                            <th>{data[1]['avg'].toFixed(2)}</th>
                            <td>{data[1]['min'].toFixed(2)}</td>
                            <td>{data[1]['max'].toFixed(2)}</td>
                        </tr>)
                    })
                    }
                </tbody>
               
            </Table>
        </div>)
    }
}