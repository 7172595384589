import {FaUserAlt} from 'react-icons/fa'
import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { selectApp, setAppSliceState } from '../../../../../state/appSlice';
import { selectLogin, setLoginSliceState } from '../../../../../state/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { userLogOut } from '../../../../../utilities/requests';
import {RiLogoutBoxRFill} from 'react-icons/ri'



export default class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }


  render() {
    return (
      <NavItem className=" logout">
        <NavLink
          className="text-center"
          onClick={userLogOut}
          >
          <div tabIndex="0">
            <RiLogoutBoxRFill onClick={()=> userLogOut} size={20} color="#ce2029"/>
            <div className='mt-1'>Exit</div>
          </div>
        </NavLink>
        {/* <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small">
        </Collapse> */}
      </NavItem>
    );
  }
}
