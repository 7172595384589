import React, { useState, useEffect } from 'react';
import './Temprature.scss';
import { GiBattery100 } from 'react-icons/gi'
import { AiOutlineMenuUnfold } from 'react-icons/ai'
function Temprature() {
    const [values, setValues] = useState({
        value1: null,
        value2: null,
        value3: null,
        value4: null,
        value5: null,
        value6: null, 
        value7: null,
        value8: null,
        value9: null
    });
    const [visible,setVisible]=useState(false)
    const min = 24;
    const max = 30;

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newValue1 = (Math.random() * (max - min) + min).toFixed(2);
            const newValue2 = (Math.random() * (max - min) + min).toFixed(2);
            const newValue3 = (Math.random() * (max - min) + min).toFixed(2);
            const newValue4 = (Math.random() * (max - min) + min).toFixed(2);
            const newValue5 = (Math.random() * (max - min) + min).toFixed(2);
            const newValue6 = (Math.random() * (max - min) + min).toFixed(2);
            const newValue7 = (Math.random() * (max - min) + min).toFixed(2);
            const newValue8 = (Math.random() * (max - min) + min).toFixed(2);
            const newValue9 = (Math.random() * (max - min) + min).toFixed(2);
            setValues({
                value1: newValue1,
                value2: newValue2,
                value3: newValue3,
                value4: newValue4,
                value5: newValue5,
                value6: newValue6,
                value7:newValue7,
                value8 :newValue8,
                value9 :newValue9
            });
        }, 6000); // Generates new random values every second

        return () => {
            clearInterval(intervalId); // Clear the interval when the component is unmounted
        };
    }, [min, max]);

    const openModal =()=>{
        setVisible(!visible)
    }

    return (
        <div className='draw-card'>
            
            <div className='main-card'>
                <div className='mfed'><h4>Feeder 1</h4><div ><AiOutlineMenuUnfold  onClick={openModal}/></div></div>
                <div className='ray-card'>
                    <div className='ray1 text-center' style={{ position: 'relative' }}>R</div>
                    <div className='ray2 text-center' style={{ position: 'relative' }}>Y</div>
                    <div className='ray3 text-center' style={{ position: 'relative' }}>B</div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 1</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{color:'green'}} size={20}/> {values.value1}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value2}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value3}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 2</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value4}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value5}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value6}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 3</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value7}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value8}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value9}</div>
                    </div>
                </div>
            </div>
            <div className='main-card'>
                <div className='mfed'><h4>Feeder 2</h4><div><AiOutlineMenuUnfold /></div></div>
                <div className='ray-card'>
                    <div className='ray1 text-center' style={{ position: 'relative' }}>R</div>
                    <div className='ray2 text-center' style={{ position: 'relative' }}>Y</div>
                    <div className='ray3 text-center' style={{ position: 'relative' }}>B</div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 1</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value1}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value2}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value3}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 2</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value4}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value5}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value6}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 3</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value7}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value8}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value9}</div>
                    </div>
                </div>
            </div>
            <div className='main-card'>
                <div className='mfed'><h4>Feeder 3</h4><div><AiOutlineMenuUnfold /></div></div>
                <div className='ray-card'>
                    <div className='ray1 text-center' style={{ position: 'relative' }}>R</div>
                    <div className='ray2 text-center' style={{ position: 'relative' }}>Y</div>
                    <div className='ray3 text-center' style={{ position: 'relative' }}>B</div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 1</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value1}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value2}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value3}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 2</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value4}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value5}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value6}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 3</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value7}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value8}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value9}</div>
                    </div>
                </div>
            </div>
            <div className='main-card'>
                <div className='mfed'><h4>Feeder 4</h4><div><AiOutlineMenuUnfold /></div></div>
                <div className='ray-card'>
                    <div className='ray1  text-center' style={{ position: 'relative' }}>R</div>
                    <div className='ray2  text-center' style={{ position: 'relative' }}>Y</div>
                    <div className='ray3  text-center' style={{ position: 'relative' }}>B</div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 1</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value1}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value2}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value3}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 2</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value4}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value5}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value6}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 3</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value7}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value8}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value9}</div>
                    </div>
                </div>
            </div>
            <div className='main-card'>
                <div className='mfed'><h4>Feeder 5</h4><div><AiOutlineMenuUnfold style={{color:'red'}}/></div></div>
                <div className='ray-card'>
                    <div className='ray1 text-center' style={{position:'relative'}}>R</div>
                    <div className='ray2 text-center' style={{ position: 'relative' }}>Y</div>
                    <div className='ray3 text-center' style={{ position: 'relative' }}>B</div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 1</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value1}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value2}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value3}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 2</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value4}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value5}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value6}</div>
                    </div>
                </div>
                <div className='ray-card'>
                    <div>
                        <div>Ch 3</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value7}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value8}</div>
                    </div>
                    <div>
                        <div><GiBattery100 style={{ color: 'green' }} size={20} /> {values.value9}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Temprature;
